import { ObjectId } from 'bson';

export default class MongoUser {
  _id = null;

  docId = "";
  enactonId = "";

  stripeAccountId = "";
  stripeCustomerId = "";

  email = "";
  userName = "";

  firstName = "";
  lastName = "";

  password = "";

  logoUrl = "";
  videoUrl = "";

  intro = "";

  currencyCode = "GBP";

  enviromateCount = 0;

  followeeCount = 0;
  followerCount = 0;

  projectCount = 0;
  supportCount = 0;

  cashbackEarned = 0;             // cents
  cashbackPending = 0;            // cents
  heartEarned = 0;                // cents
  topUpAmount = 0;                // cents
  withdrawAmount = 0;             // cents
  supportSpent = 0;               // cents

  fcmToken = "";
  timestamp = null;

  constructor() {
    this._id = new ObjectId();
    this.docId = this._id.valueOf().toString();
    this.timestamp = new Date();
  }
}
