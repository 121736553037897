import { ObjectId } from 'bson';

export default class MongoProjectUpdateCommentLike {
  _id = null;

  updateId= "";
  commentId = "";
  likerIds = [];

  constructor() {
    this._id = new ObjectId();
    this.docId = this._id.valueOf().toString();
  }
}
