export default class MongoTeam {
  static TYPE_CREATOR = "creator";
  static TYPE_COCREATOR = "co-creator";
  static TYPE_MEMBER = "member";
  static TYPE_INVITED = "invited";

  type = MongoTeam.TYPE_MEMBER;

  memberId = "";
  memberName = "";
  memberLogoUrl = "";

  invitorId = "";
  reject = false;
}
