import MongoEngine from ".";
import MongoProjectDislike from "./projectdislike";

export default class MongoProjectDislikeEngine {
  static async add(like) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectDislike");
      var result = await collection.insertOne(like);
      like._id = result.insertedId;
    } catch(error) {
      console.log("[MongoProjectDislikeEngine] add() -> error: ", error);
    }
  }

  static async deleteByUserId(userId) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectDislike");
      var filter = {
        userId: userId,
      };
      var result = await collection.deleteMany(filter);
    } catch(error) {
      console.log("[MongoProjectDislikeEngine] deleteByUserId() -> error: ", error);
    }
  }

  static async dislike(user, project) {
    try {
      var dislike = await MongoProjectDislikeEngine.getByUserId(user.docId);
      if (dislike == null) {
        dislike = new MongoProjectDislike();
        dislike.userId = user.docId;
      }

      var projectIds = dislike.projectIds;
      var index = projectIds.find(projectId => projectId == project.docId);
      if (index >= 0) {
        return;
      }

      projectIds.push(project.docId);
      dislike.projectIds = projectIds;

      await MongoProjectDislikeEngine.update(dislike);

      return true;
    } catch(error) {
      console.error("[MongoProjectDislikeEngine] dislike() -> error: " + error.message);
    }

    return false;
  }

  static fromJson(json) {
    var dislike = new MongoProjectDislike();
    try {
      if (json._id != undefined) {
        dislike._id = json._id;
      }
      if (json.userId != undefined) {
        dislike.userId = json.userId;
      }
      if (json.projectIds != undefined) {
        dislike.projectIds = json.projectIds;
      }
    } catch(error) {
      console.log("[MongoProjectDislikeEngine] fromJson() -> error: ", error);
    }

    return dislike;
  }

  static async getByUserId(userId) {
    var dislike = null;
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectDislike");
      var filter = {
        userId: userId,
      };

      var mongoDislikes = await collection.find(filter);
      if (mongoDislikes.length == 0) {
        return null;
      }

      dislike = MongoProjectDislikeEngine.fromJson(mongoDislikes[0]);
    } catch(error) {
      console.log("[MongoProjectDislikeEngine] getByUserId() -> error: ", error);
    }

    return dislike;
  }

  static async getCountByProjectId(projectId) {
    var count = 0;
    try {
      var like = await MongoProjectDislikeEngine.getByProjectId(projectId);
      count = like.likerIds.length;
    } catch(error) {
      console.log("[MongoProjectDislikeEngine] getCountByProjectId() -> error: ", error);
    }

    return count;
  }

  static async isDislike(userId, projectId) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectDislike");
      var filter = {
        userId: userId,
        projectIds: projectId,
      };

      var count = await collection.count(filter);
      if (count > 0) {
        return true;
      }
    } catch(error) {
      console.log("[MongoProjectDislikeEngine] isDislike() -> error: " + error);
    }

    return false;
  }

  static async update(like) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectDislike");
      var result = await collection.updateOne(
        { _id: like._id },
        { $set: like },
        { upsert: true },
      );
    } catch(error) {
      console.log("[MongoProjectDislikeEngine] update() -> error: ", error);
    }
  }
}
