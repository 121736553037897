import MongoEngine from ".";
import MongoProjectUpdateEngine from "./projectupdates";
import MongoProjectUpdateLike from "./projectupdatelike";

export default class MongoProjectUpdateLikeEngine {
  static async add(like) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdateLike");
      var result = await collection.insertOne(like);
      like._id = result.insertedId;
    } catch(error) {
      console.log("[MongoProjectUpdateLikeEngine] add() -> error: ", error);
    }
  }

  static async deleteAll() {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdateLike");
      var result = await collection.deleteMany();
    } catch(error) {
      console.log("[MongoProjectUpdateLikeEngine] deleteAll() -> error: ", error);
    }
  }

  static async deleteByUpdateId(updateId) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdateLike");
      var filter = {
        updateId: updateId,
      };
      var result = await collection.deleteMany(filter);
    } catch(error) {
      console.log("[MongoProjectUpdateLikeEngine] deleteByUpdateId() -> error: ", error);
    }
  }

  static async dislike(updateId, userId) {
    try {
      var like = await MongoProjectUpdateLikeEngine.getByUpdateId(updateId);
      if (like == null) {
        return false;
      }

      var likerIds = like.likerIds;
      var index = likerIds.find(likerId => likerId == userId);
      if (index < 0) {
        return false;
      }

      likerIds.splice(index, 1);
      like.likerIds = likerIds;

      await MongoProjectUpdateLikeEngine.update(like);

      return true;
    } catch(error) {
      console.log("[MongoProjectUpdateLikeEngine] dislike() -> error: " + error.message);
    }

    return false;
  }

  static fromJson(json) {
    var like = new MongoProjectUpdateLike();
    try {
      if (json._id != undefined) {
        like._id = json._id;
      }
      if (json.projectId != undefined) {
        like.projectId = json.projectId;
      }
      if (json.updateId != undefined) {
        like.updateId = json.updateId;
      }
      if (json.likerIds != undefined) {
        like.likerIds = json.likerIds;
      }
    } catch(error) {
      console.log("[MongoProjectUpdateLikeEngine] fromJson() -> error: ", error);
    }

    return like;
  }

  static async getByUpdateId(updateId) {
    var like = null;
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdateLike");
      var filter = {
        updateId: updateId,
      };

      var mongoProjectUpdateLikes = await collection.find(filter);
      if (mongoProjectUpdateLikes.length == 0) {
        return null;
      }

      like = MongoProjectUpdateLikeEngine.fromJson(mongoProjectUpdateLikes[0]);
    } catch(error) {
      console.log("[MongoProjectUpdateLikeEngine] getByProjectId() -> error: ", error);
    }

    return like;
  }

  static async getCountByUpdateId(updateId) {
    var count = 0;
    try {
      var like = await MongoProjectUpdateLikeEngine.getByUpdateId(updateId);
      count = like.likerIds.length;
    } catch(error) {
      console.log("[MongoProjectUpdateLikeEngine] getCountByUpdateId() -> error: ", error);
    }

    return count;
  }

  static async isLiked(updateId, userId) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdateLike");
      var filter = {
        updateId: updateId,
        likerIds: userId,
      };

      var count = await collection.count(filter);
      if (count > 0) {
        return true;
      }
    } catch(error) {
      console.log("[MongoProjectUpdateLikeEngine] isLikedByUserId() -> error: " + error);
    }

    return false;
  }

  static async like(projectId, updateId, userId) {
    try {
      var like = await MongoProjectUpdateLikeEngine.getByUpdateId(updateId);
      if (like == null) {
        like = new MongoProjectUpdateLike();
        like.updateId = updateId;
        like.projectId = projectId;
      }

      var likerIds = like.likerIds;
      var index = likerIds.find(likerId => likerId == userId);
      if (index >= 0) {
        return false;
      }

      likerIds.push(userId);
      like.likerIds = likerIds;

      await MongoProjectUpdateLikeEngine.update(like);

      return true;
    } catch(error) {
      console.log("[MongoProjectUpdateLikeEngine] like() -> error: " + error.message);
    }

    return false;
  }

  static async update(like) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdateLike");
      var result = await collection.updateOne(
        { _id: like._id },
        { $set: like },
        { upsert: true },
      );
    } catch(error) {
      console.log("[MongoProjectUpdateLikeEngine] update() -> error: ", error);
    }
  }
}
