import MongoCallback from "./callback";
import MongoEngine from ".";
import MongoProjectUpdateEngine from "./projectupdates";
import MongoProjectUpdateRead from "./projectupdateread";

export default class MongoProjectUpdateReadEngine {
  static changeListeners = [];

  static async add(read) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdateRead");
      var result = await collection.insertOne(read);
      read._id = result.insertedId;
    } catch(error) {
      console.log("[MongoProjectUpdateReadEngine] add() -> error: ", error);
    }
  }

  static async deleteAll() {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdateRead");
      var result = await collection.deleteMany();
    } catch(error) {
      console.error("[MongoProjectUpdateReadEngine] deleteAll() -> error: ", error);
    }
  }

  static async deleteByUpdateId(updateId) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdateRead");
      var filter = {
        updateId: updateId,
      };
      var result = await collection.deleteMany(filter);
    } catch(error) {
      console.error("[MongoProjectUpdateReadEngine] deleteByUpdateId() -> error: ", error);
    }
  }

  static fromJson(json) {
    var read = new MongoProjectUpdateRead();
    try {
      if (json._id != undefined) {
        read._id = json._id;
      }
      if (json.updateId != undefined) {
        read.updateId = json.updateId;
      }
      if (json.projectId != undefined) {
        read.projectId = json.projectId;
      }
      if (json.readerIds != undefined) {
        read.readerIds = json.readerIds;
      }
    } catch(error) {
      console.log("[MongoProjectUpdateReadEngine] fromJson() -> error: ", error);
    }

    return read;
  }

  static async getByUpdateId(updateId) {
    var read = null;
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdateRead");
      var filter = {
        updateId: updateId,
      };

      var mongoProjectUpdateReads = await collection.find(filter);
      if (mongoProjectUpdateReads.length == 0) {
        return null;
      }

      read = MongoProjectUpdateReadEngine.fromJson(mongoProjectUpdateReads[0]);
    } catch(error) {
      console.log("[MongoProjectUpdateReadEngine] getByProjectId() -> error: ", error);
    }

    return read;
  }

  static isRead(read, userId) {
    var readerIds = read.readerIds;
    var index = readerIds.findIndex(readerId => readerId == userId);
    if (index < 0) {
      return false;
    }

    return true;
  }

  static async read(read, userId) {
    if (this.isRead(read, userId) == true) {
      return true;
    }

    var readerIds = [...read.readerIds, userId];
    read.readerIds = readerIds;

    await this.update(read);

    return true;
  }

  static registerChangeListener = (callback) => {
    try {
      var mongoCallback = new MongoCallback();
      mongoCallback.callback = callback;
      this.changeListeners.push(mongoCallback);
    } catch(error) {
      console.error("[MongoProjectUpdateReadEngine] registerChangeListener() -> error: ", error.message);
    }
  }

  static unregisterChangeListenerByListenerId = (callbackId) => {
    try {
      var index = this.changeListeners.find(callback => callback.id == callbackId);
      if (index < 0) {
        return;
      }

      this.changeListeners.splice(index, 1);
    } catch(error) {
      console.error("[MongoProjectUpdateReadEngine] unregisterChangeListenerByListenerId() -> error: ", error.message);
    }
  }

  static async update(read) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdateRead");
      var result = await collection.updateOne(
        { _id: read._id },
        { $set: read },
        { upsert: true },
      );
    } catch(error) {
      console.log("[MongoProjectUpdateReadEngine] update() -> error: ", error);
    }
  }

  static watchForChanges = async () => {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdateRead");
      var changeStream = collection.watch();
      for await (var change of changeStream) {
        this.changeListeners.forEach(listener => {
          try {
            listener.callback();
          } catch(error) {}
        });
      }
    } catch(error) {
      console.error("[MongoProjectUpdateReadEngine] watchForChanges() -> error: ", error.message);
    }
  }
}
