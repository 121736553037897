import { ObjectId } from 'bson';

import { HEART_VALUE } from '../constants/globalconstants';

//import EnactonSaleEngine from 'engine/enacton/sale';
//import EnactonStoreEngine from '../enacton/store';
//import EnactonUserEngine from '../enacton/user';
//import EnactonUserHeartEngine from 'engine/enacton/userheart';

import MongoEngine from ".";
import MongoEnviromateEngine from "./enviromates";
import MongoProjectEngine from "./projects";
import MongoUser from "./user";
import MongoUserInfoEngine from "./userinfos";
// import StripeCustomerEngine from "../stripe/customers";
// import StripeAccountEngine from '../stripe/accounts';

export default class MongoUserEngine {
  static async add(user) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("User");
      var result = await collection.insertOne(user);
      user._id = result.insertedId;
    } catch(error) {
      console.log("[MongoUserEngine] add() -> error: ", error);
    }
  }

  /* static async addNewUser(user, userInfo) {
    try {
      // add to enacton
      var enactonId = await EnactonUserEngine.addByUserInfo(user);
      user.enactonId = enactonId;

      // add to mongo
      await MongoUserEngine.add(user);

      if (userInfo != null) {
        userInfo.userEnactonId = enactonId;
        await MongoUserInfoEngine.add(userInfo);
      }

      // add store of user
      await EnactonStoreEngine.addByUser(user);  

      // add stripe customer, stripe account
      var stripeCustomer = await StripeCustomerEngine.addByMongoUser(user, userInfo);
      var stripeAccount = await StripeAccountEngine.addByUser(user);
      if (stripeCustomer != null && stripeAccount != null) {
        user.stripeCustomerId = stripeCustomer.id;
        user.stripeAccountId = stripeAccount.id;
        await MongoUserEngine.update(user);
      }
    } catch(error) {
      console.log("[FirebaseUserEngine] addNewUser() -> error: ", error.message);
    }
  } */

  static async deleteAll() {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("User");
      var result = await collection.deleteMany();
      console.log("[MongoUserEngine] deleteAll() -> result: ", result);
    } catch(error) {
      console.log("[MongoUserEngine] deleteAll() -> error: ", error);
    }
  }

  static fromJson(json) {
    var user = new MongoUser();
    try {
      if (json._id != undefined) {
        user._id = new ObjectId(json._id);
      }

      user.docId = MongoEngine.objectIdToString(json.docId);
      if (user.docId == "") {
        user.docId = user._id.toString();
      }
      if (json.enactonId != undefined) {
        user.enactonId = json.enactonId;
      }

      if (json.stripeAccountId != undefined) {
        user.stripeAccountId = json.stripeAccountId;
      }
      if (json.stripeCustomerId != undefined) {
        user.stripeCustomerId = json.stripeCustomerId;
      }

      if (json.email != undefined) {
        user.email = json.email;
      }
      if (json.userName != undefined) {
        user.userName = json.userName;
      }

      if (json.firstName != undefined) {
        user.firstName = json.firstName;
      }
      if (json.lastName != undefined) {
        user.lastName = json.lastName;
      }

      if (json.password != undefined) {
        user.password = json.password;
      }

      if (json.logoUrl != undefined) {
        user.logoUrl = json.logoUrl;
      }
      if (json.videoUrl != undefined) {
        user.videoUrl = json.videoUrl;
      }

      if (json.intro != undefined) {
        user.intro = json.intro;
      }

      if (json.currencyCode != undefined && json.currencyCode != null) {
        user.currencyCode = json.currencyCode;
      }

      if (json.enviromateCount != undefined) {
        user.enviromateCount = json.enviromateCount;
      }

      if (json.followeeCount != undefined) {
        user.followeeCount = json.followeeCount;
      }
      if (json.followerCount != undefined) {
        user.followerCount = json.followerCount;
      }

      if (json.projectCount != undefined) {
        user.projectCount = json.projectCount;
      }
      if (json.supportCount != undefined) {
        user.supportCount = json.supportCount;
      }

      if (json.cashbackEarned != undefined) {
        user.cashbackEarned = json.cashbackEarned;
      }
      if (json.cashbackPending != undefined) {
        user.cashbackPending = json.cashbackPending;
      }
      if (json.heartEarned != undefined) {
        user.heartEarned = json.heartEarned;
      }
      if (json.topUpAmount != undefined) {
        user.topUpAmount = json.topUpAmount;
      }
      if (json.withdrawAmount != undefined) {
        user.withdrawAmount = json.withdrawAmount;
      }
      if (json.supportSpent != undefined) {
        user.supportSpent = json.supportSpent;
      }

      if (json.fcmToken != undefined && json.fcmToken != null) {
        user.fcmToken = json.fcmToken;
      }

      if (json.timestamp != undefined) {
        user.timestamp = new Date(json.timestamp);
      }
    } catch(error) {
      console.log("[MongoUserEngine] fromJson() -> error: ", error);
    }
    return user;
  }

  static async get() {
    var users = [];
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("User");
      var filter = {};
      var mongoUsers = await collection.find(filter);
      mongoUsers.forEach(mongoUser => {
        var user = MongoUserEngine.fromJson(mongoUser);
        users.push(user);
      });
    } catch(error) {
      console.log("[MongoUserEngine] get() -> error: ", error);
    }

    return users;
  }

  static getAvailableFund = (user) => {
    var fund = 0;
    try {
      fund = user.cashbackEarned + user.heartEarned + user.topUpAmount - user.supportSpent - user.withdrawAmount;
      if (fund < 0) {
        fund = 0;
      }
    } catch(error) {
      console.log("[MongoUserEngine] getAvailableFund() -> error: ", error);
    }

    return fund;
  }

  static getAvailableHeartCount = (user) => {
    var count = 0;
    try {
      var fund = MongoUserEngine.getAvailableFund(user);
      count = Math.floor(fund / HEART_VALUE);
    } catch(error) {
      console.log("[MongoUserEngine] getAvailableFund() -> error: ", error);
    }

    return count;
  }

  static async getByDocId(docId) {
    var user = null;
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("User");
      var filter = {
        docId: docId,
      };
      var mongoUsers = await collection.find(filter);
      if (mongoUsers.length == 0) {
        return null;
      }

      user = MongoUserEngine.fromJson(mongoUsers[0]);
    } catch(error) {
      console.error("[MongoUserEngine] getByDocId() -> error: ", error.message);
    }

    return user;
  }

  static async getByUsername(username) {
    var user = null;
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("User");
      var filter = {
        userName: new RegExp(`^${username}$`, 'i'),
      };
      console.log(filter);
      var mongoUsers = await collection.find(filter);
      if (mongoUsers.length == 0) {
        return null;
      }

      user = MongoUserEngine.fromJson(mongoUsers[0]);
    } catch(error) {
      console.error("[MongoUserEngine] getByUsername() -> error: ", error.message);
    }

    return user;
  }

  static async getByDocIds(docIds) {
    var users = [];
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("User");
      var filter = {
        docId: {
          $in: docIds
        },
      };
      var mongoUsers = await collection.find(filter);
      mongoUsers.forEach(mongoUser => {
        var user = MongoUserEngine.fromJson(mongoUser);
        users.push(user);
      });
    } catch(error) {
      console.log("[MongoUserEngine] getByDocIds() -> error: ", error);
    }

    return users;
  }

  static async getByEnactonId(enactonId) {
    var user = null;
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("User");
      var filter = {
        enactonId: enactonId,
      };
      var mongoUsers = await collection.find(filter);
      if (mongoUsers.length == 0) {
        return null;
      }
      user = MongoUserEngine.fromJson(mongoUsers[0]);
    } catch(error) {
      console.log("[MongoUserEngine] getByEnactonId() -> error: ", error);
    }

    return user;
  }

  static async getForExcludesByDocIds(docIds) {
    var users = [];
    try {
      /*
      var realm = MongoEngine.realm;

      var collection = realm.objects("User");
      var mongoUsers = collection.filtered("docId == ANY $0", docIds);
      */

      var db = await MongoEngine.getDB();
      var collection = db.collection("User");
      var filter = {
        docId: {
          $nin: docIds
        },
      };
      var mongoUsers = await collection.find(filter);
      mongoUsers.forEach(mongoUser => {
        var user = MongoUserEngine.fromJson(mongoUser);
        users.push(user);
      });
    } catch(error) {
      console.log("[MongoUserEngine] getForExcludesByDocIds() -> error: ", error);
    }

    return users;
  }

  static getFriendlyName(user) {
    if (user == null) {
      return "";
    }

    var name = user.firstName;
    if (name != "") {
      name += " ";
    }
    name += user.lastName;
    if (name != "") {
      return name;
    }

    return MongoUserEngine.getFriendlyUserName(user);
  }

  static getFriendlyUserName(user) {
    if (user == null) {
      return "";
    }

    if (user.userName != "") {
      return user.userName;
    }

    return user.email;
  }

  static getName(user) {
    if (user == null) {
      return "";
    }

    var name = user.firstName;
    if (name != "") {
      name += " ";
    }
    name += user.lastName;

    return name;
  }

  static async getStrangersByUserId(userId, location) {
    //console.log("[MongoUserEngine] getStrangersByUserId()");
    //console.log("\tuserId: ", userId);
    //console.log("\tlocation: ", location);

    var users = [];
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("User");
      
      var aggregate = [
        {
          $lookup: {
            from: "UserInfo",
            localField: "docId",
            foreignField: "userDocId",
            as: "infos"
          }
        },
        { $addFields: { userInfo: { $first: "$infos" } } },
        {
          $addFields: {
            distance: {
              $cond: [
                {
                  $or: [
                    {$eq: [ { $size: "$infos" }, 0 ] },
                    {$eq: [ "$userInfo.location", null ] },
                    {$eq: [ "$userInfo.location.latitude", null ] },
                    {$eq: [ "$userInfo.location.longitude", null ] },
                  ],
                },
                100000000,
                {
                  $sqrt: {
                    $add: [
                      { $pow: [ { $subtract: [ "$userInfo.location.latitude", location.latitude ] }, 2 ] },
                      { $pow: [ { $subtract: [ "$userInfo.location.longitude", location.latitude ] }, 2 ] }
                    ]
                  }
                }
              ]
            }
          }
        },
        {
          $lookup: {
            from: "Follow",
            localField: "docId",
            foreignField: "userId",
            as: "follows"
          }
        },
        {
          $match: {
              docId: {
                $ne: userId
              },
              "follows.followers": {
                $ne: userId
              }
          }
        },
        {
          $sort: {
            distance: 1,
          }
        },
        { $limit : 20 }
      ];

      var mongoUsers = await collection.aggregate(aggregate);
      mongoUsers.forEach(mongoUser => {
        //console.log("\t\tdistance: ", mongoUser.distance);
        //console.log("\t\t\tuserInfo: ", mongoUser.infos);

        var user = MongoUserEngine.fromJson(mongoUser);
        //console.log("\t\t\tuser: ", user);
        users.push(user);
      });

      users.sort((a, b) => {
        return b.followerCount - a.followerCount;
      });
      
    } catch(error) {
      console.error("[MongoUserEngine] getStrangersByUserId() -> error: ", error);
    }

    return users;
  }

  static isMongoUser = (user) => {
    if (user.email) {
      return true;
    }

    return false
  }

  static isShowcaseReviewer = (user) => {
    if (user == undefined || user == null) {
      return false;
    }

    if (user.email == "simon@yamu.ai") {
      return true;
    }

    return false;
  }

  static onGotHeart = async (user, heartCount) => {
    try {
      var heartEarned = heartCount * HEART_VALUE;
      var mongoUser = await MongoUserEngine.getByDocId(user.docId);
      if (mongoUser != null) {
        heartEarned += Number(mongoUser.heartEarned);
      }

      user.heartEarned = heartEarned;
      await MongoUserEngine.update(user);
    } catch(error) {
      console.log("[MongoUserEngine] onGotHeart() -> error: ", error);
    }
  }

  static getUsers = async () => {
    var users = [];
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("User");
      var mongoUsers = await collection.find();

      users = mongoUsers.map(mongoUser => MongoUserEngine.fromJson(mongoUser));

    } catch(error) {
      console.log("[MongoUserEngine] getUsers -> error: ", error);
    }
    return users;
  }

  static refine = async () => {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("User");
      var mongoUsers = await collection.find();
      for (var mongoUser of mongoUsers) {
        var user = MongoUserEngine.fromJson(mongoUser);
        await MongoUserEngine.update(user);
      }
    } catch(error) {
      console.log("[MongoUserEngine] refine() -> error: ", error);
    }
  }

  static async update(user) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("User");
      var result = await collection.updateOne(
        { _id: user._id },
        { $set: user },
        { upsert: true },
      );
    } catch(error) {
      console.log("[MongoUserEngine] update() -> error: ", error);
    }
  }
}
