export default class MongoNotificationShowcaseInvitationReject {
  showcaseId = "";
  showcaseTitle = "";

  inviteeId = "";
  inviteeName = "";
  inviteeLogoUrl = "";

  invitorId = "";
  invitorName = "";
  invitorLogoUrl = "";
}
