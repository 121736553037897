import MongoLocation from "./location";

export default class MongoLocationEngine {
  static fromFirebase(json) {
    var location = new MongoLocation();
    try {
      if (json.address != undefined) {
        location.address = json.address;
      }
      if (json.city != undefined) {
        location.city = json.city;
      }
      if (json.countryCode != undefined) {
        location.countryCode = json.countryCode;
      }
      if (json.countryName != undefined) {
        location.countryName = json.countryName;
      }
      if (json.postalCode != undefined) {
        location.postalCode = json.postalCode;
      }
      if (json.geometry != undefined) {
        if (json.geometry.lat != undefined) {
          location.latitude = json.lat;
        }
        if (json.geometry.lng != undefined) {
          location.longitude = json.lng;
        }
      }
    } catch(error) {
      console.log("[MongoLocationEngine] fromFirebase() -> error: ", error);
    }

    return location;
  }

  static fromJson(json) {
    var location = new MongoLocation();
    try {
      if (json.address != undefined) {
        location.address = json.address;
      }
      if (json.city != undefined) {
        location.city = json.city;
      }
      if (json.countryCode != undefined) {
        location.countryCode = json.countryCode;
      }
      if (json.countryName != undefined) {
        location.countryName = json.countryName;
      }
      if (json.postalCode != undefined) {
        location.postalCode = json.postalCode;
      }
      if (json.latitude != undefined) {
        location.latitude = json.latitude;
      }
      if (json.longitude != undefined) {
        location.longitude = json.longitude;
      }
    } catch(error) {
      console.log("[MongoLocationEngine] fromJson() -> error: ", error);
    }

    return location;
  }

  static getAsString = (location) => {
    if (location == null) {
      return "";
    }

    var locationAsString = location.address;
    if (location.city != "") {
      if (locationAsString != "") {
        locationAsString += ", ";
      }
      locationAsString += location.city;
    }

    if (location.countryName != "") {
      if (locationAsString != "") {
        locationAsString += ", ";
      }
      locationAsString += location.countryName;
    }

    if (location.postalCode != "") {
      if (locationAsString != "") {
        locationAsString += ", ";
      }
      locationAsString += location.postalCode;
    }

    return locationAsString;
  }

  static isEdited = (location) => {
    if (location == null) {
      return false;
    }
    if (location.address != "") {
      return true;
    }
    if (location.city != "") {
      return true;
    }
    if (location.countryCode != "") {
      return true;
    }
    if (location.countryName != "") {
      return true;
    }
    if (location.postalCode != "") {
      return true;
    }

    return false;
  }
}
