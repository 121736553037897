export default class MongoBankCard {
  paymentMethodId = "";

  countryCode = "";
  brand = "Visa";

  last4 = [];
  expiryYear = 0;
  expiryMonth = 0;

  default = false;
}
