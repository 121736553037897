import { ObjectId } from 'bson';

export default class MongoProject {
  static MENTOR_AVAILABLE = "Available";
  static MENTOR_LATER = "Maybe later";

  static STATUS_DRAFT = "draft";
  static STATUS_ONLINE = "online";

  _id = null;

  showcaseId = "";

  creatorId = "";
  creatorName = "";
  creatorLogoUrl = "";

  docId = "";

  title = "";
  titleAsDone = false;

  medias = [];                                            // MongoMedia
  mediasAsDone = false;

  summary = "";
  description = "";
  category = "";
  tags = [];
  descriptionAsDone = false;

  location = null;                                        // MongoLocation
  locationAsDone = false;

  funding = null;                                         // MongoFunding
  fundingAsDone = false;

  members = [];                                           // MongoTeam
  membersAsDone = false;

  mentorAvailablility = MongoProject.MENTOR_AVAILABLE;
  coFundingAsDone = false;

  commentCount = 0;
  postCount = 0;

  pinCount = 0;
  followCount = 0;
  likeCount = 0;
  supporterCount = 0;
  readCount = 0;

  status = MongoProject.STATUS_DRAFT;

  timestamp = null;
  updatedTimestamp = null;
  launchedTimestamp = null;

  constructor() {
    this._id = new ObjectId();
    this.docId = this._id.valueOf().toString();
    this.timestamp = new Date();
    this.updatedTimestamp = new Date();
  }
}
