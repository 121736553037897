import { ObjectId } from 'bson';

export default class MongoProjectUpdatePledge {
  static TYPE_HEARTS = "hearts";
  static TYPE_CENTS = "cents";

  static FIREDBY_MANALLY = "manually";
  static FIREDBY_AUTO = "monthly";

  static MONTHLY_AMOUNT = "Amount";
  static MONTHLY_PERCENT = "Percent";

  _id = null;
  docId = "";

  updateId = "";
  updateMedia = null;                                    // MondoMedia
  updateComment = "";

  creatorId = "";
  creatorName = "";
  creatorLogoUrl = "";

  pledgerId = "";
  pledgerName = "";
  pledgerLogoUrl = "";

  type = MongoProjectUpdatePledge.TYPE_HEARTS;
  amount = 0;
  currencyCode = "GBP";

  firedBy = MongoProjectUpdatePledge.FIREDBY_MANALLY;

  timestamp = 0;

  constructor() {
    this._id = new ObjectId();
    this.docId = this._id.valueOf().toString();
    this.timestamp = new Date();
  }
}
