import { ObjectId } from 'bson';

export default class MongoProjectDislike {
  _id = null;

  userId = "";
  projectIds = [];

  constructor() {
    this._id = new ObjectId();
  }
}
