import MongoEngine from ".";
import MongoProjectCommentEngine from "./projectcomments";
import MongoProjectCommentLike from "./projectcommentlike";

export default class MongoProjectCommentLikeEngine {
  static async add(like) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectCommentLike");
      var result = await collection.insertOne(like);
      like._id = result.insertedId;
    } catch(error) {
      console.log("[MongoProjectCommentLikeEngine] add() -> error: ", error);
    }
  }

  static async deleteAll() {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectCommentLike");
      var result = await collection.deleteMany();
    } catch(error) {
      console.log("[MongoProjectCommentLikeEngine] deleteAll() -> error: ", error);
    }
  }

  static async deleteByCommentId(commentId) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectCommentLike");
      var filter = {
        commentId: commentId,
      };
      var result = await collection.deleteMany(filter);
    } catch(error) {
      console.log("[MongoProjectCommentEngine] deleteByCommentId() -> error: ", error);
    }
  }

  static async deleteByCommentIds(commentIds) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectCommentLike");
      var filter = {
        commentId: { $in: commentIds },
      };
      var result = await collection.deleteMany(filter);
    } catch(error) {
      console.log("[MongoProjectCommentLikeEngine] deleteByCommentIds() -> error: ", error);
    }
  }

  static async dislike(comment, userId) {
    try {
      var like = await MongoProjectCommentLikeEngine.getByCommentId(comment.docId);
      if (like == null) {
        return false;
      }

      var likerIds = like.likerIds;
      var index = likerIds.find(likerId => likerId == userId);
      if (index < 0) {
        return false;
      }

      likerIds.splice(index, 1);
      like.likerIds = likerIds;

      await MongoProjectCommentLikeEngine.update(like);

      // decrease like count of comment
      comment.likeCount = likerIds.length;
      await MongoProjectCommentEngine.update(comment);

      return true;
    } catch(error) {
      console.log("[MongoProjectCommentLikeEngine] dislike() -> error: " + error.message);
    }

    return false;
  }

  static fromJson(json) {
    var like = new MongoProjectCommentLike();
    try {
      if (json._id != undefined) {
        like._id = json._id;
      }
      if (json.projectId != undefined) {
        like.projectId = json.projectId;
      }
      if (json.commentId != undefined) {
        like.commentId = json.commentId;
      }
      if (json.likerIds != undefined) {
        like.likerIds = json.likerIds;
      }
    } catch(error) {
      console.log("[MongoProjectCommentLikeEngine] fromJson() -> error: ", error);
    }

    return like;
  }

  static async getByCommentId(commentId) {
    var like = null;
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectCommentLike");
      var filter = {
        commentId: commentId,
      };

      var mongoProjectCommentLikes = await collection.find(filter);
      if (mongoProjectCommentLikes.length == 0) {
        return null;
      }

      like = MongoProjectCommentLikeEngine.fromJson(mongoProjectCommentLikes[0]);
    } catch(error) {
      console.log("[MongoProjectCommentLikeEngine] getByProjectId() -> error: ", error);
    }

    return like;
  }

  static async getCountByProjectId(projectId) {
    var count = 0;
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectCommentLike");
      var filter = {
        projectId: projectId,
      };

      var mongoProjectCommentLikes = await collection.find(filter);
      mongoProjectCommentLikes.forEach(mongoLike => {
        var like = MongoProjectCommentLikeEngine.fromJson(mongoLike);
        count += like.likerIds.length;
      });
    } catch(error) {
      console.log("[MongoProjectCommentLikeEngine] getCountByProjectId() -> error: ", error);
    }

    return count;
  }

  static async isLiked(commentId, userId) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectCommentLike");
      var filter = {
        commentId: commentId,
        likerIds: userId,
      };

      var count = await collection.count(filter);
      if (count > 0) {
        return true;
      }
    } catch(error) {
      console.log("[MongoProjectCommentLikeEngine] isLiked() -> error: " + error);
    }

    return false;
  }

  static async like(comment, userId) {
    try {
      var like = await MongoProjectCommentLikeEngine.getByCommentId(comment.docId);
      if (like == null) {
        like = new MongoProjectCommentLike();
        like.projectId = comment.projectId;
        like.commentId = comment.docId;
      }

      var likerIds = like.likerIds;
      var index = likerIds.find(likerId => likerId == userId);
      if (index >= 0) {
        return false;
      }

      likerIds.push(userId);
      like.likerIds = likerIds;

      await MongoProjectCommentLikeEngine.update(like);

      // increase comment like count
      comment.likeCount = likerIds.length;
      await MongoProjectCommentEngine.update(comment);

      return true;
    } catch(error) {
      console.log("[MongoProjectCommentLikeEngine] like() -> error: " + error.message);
    }

    return false;
  }

  static async update(like) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectCommentLike");
      var result = await collection.updateOne(
        { _id: like._id },
        { $set: like },
        { upsert: true },
      );
    } catch(error) {
      console.log("[MongoProjectCommentLikeEngine] update() -> error: ", error);
    }
  }
}
