import MongoBankCard from "./bankcard";

export default class MongoBankCardEngine {
  static fromJson(json) {
    var card = new MongoBankCard();
    try {
        if (json.paymentMethodId != undefined) {
          card.paymentMethodId = json.paymentMethodId;
        }
        if (json.countryCode != undefined) {
          card.countryCode = json.countryCode;
        }
        if (json.brand != undefined) {
          card.brand = json.brand;
        }
        if (json.last4 != undefined) {
          card.last4 = json.last4;
        }
        if (json.expiryYear != undefined) {
          card.expiryYear = json.expiryYear;
        }
        if (json.expiryMonth != undefined) {
          card.expiryMonth = json.expiryMonth;
        }
        if (json.default != undefined) {
          card.default = json.default;
        }
    } catch(error) {
      console.log("[MongoBankCardEngine] fromJson() -> error: ", error);
    }

    return card;
  }
}
