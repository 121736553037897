//import BcryptReactNative from "bcrypt-react-native";
import { Buffer } from "buffer";
import Moment from 'moment';
import validator from "validator";

import { COUNTRY_LIST, CURRENCY_LIST } from './constants/globalconstants';

export default class Utility {
  static attachProtoclToUrl(url) {
    var urlWithProtocal = url;
    if (!urlWithProtocal.startsWith('http://') && !urlWithProtocal.startsWith('https://')) {
      while (urlWithProtocal.charAt(0) === '/') {
        urlWithProtocal = urlWithProtocal.substring(1);
      }
      urlWithProtocal = 'https://' + urlWithProtocal;
    }

    return urlWithProtocal;
  }

  static convertDocIdToInt(docId) {
    var buffer = Buffer.from(docId);
    var result = buffer.readUInt32BE(0);
    return result;
  }

  static extractDomainName = (url) => {
    var hostname = '';
    if (url.indexOf("//") > -1) {
      hostname = url.split('/')[2];
    } else {
      hostname = url.split('/')[0];
    }
    hostname = hostname.split(':')[0];
    hostname = hostname.split('?')[0];

    // remove www prefix
    if (hostname.includes("www.")) {
      hostname = hostname.replaceAll("www.", "");
    }

    // remove mobile prefix
    if (hostname.includes("m.")) {
      hostname = hostname.replaceAll("m.", "");
    }

    // remove other prefixes
    /*var components = hostname.split(".");
    if (components.length > 1) {
      hostname = components[components.length - 2] + "." + components[components.length - 1];
    }*/

    return hostname;
  }

  static extractSearchKeywordFromUrl(url) {
    if (!Utility.isGoogle(url)) {
      return "";
    }

    // find keyword start
    var start = url.toLowerCase().indexOf("&q=");
    if (start < 0) {
      return "";
    }
    start += 3;

    // find keyword end
    var keyword = "";
    var temp = url.substring(start);
    var end = temp.indexOf("&");
    if (end < 0) {
      keyword = temp;
    } else {
      keyword = temp.substring(0, end);
    }

    keyword = decodeURIComponent(keyword);
    return keyword;
  }

  static getCountryByCode(code) {
    var country = COUNTRY_LIST.find(country => country.code.toLowerCase() === code.toLowerCase());
    if (country == null) {
      country = COUNTRY_LIST.find(country => country.code == "GB");
    }

    return country;
  }

  static getCurrencyByCode(code) {
    var currency = CURRENCY_LIST.find(currency => currency.code.toLowerCase() === code.toLowerCase());
    if (currency == null) {
      currency = COUNTRY_LIST.find(currency => currency.code == "GBP");
    }

    return currency;
  }

  static getTimestampAsNextDate = (timestamp) => {
    if (timestamp == null) {
      return "N/A";
    }

    var date = Moment(timestamp);
    var today = Moment(new Date());

    var diffMonth = date.diff(today, "months");
    var addedDate = date.add(diffMonth, "months");

    if (addedDate.diff(today, "days") < 0) {
      addedDate = addedDate.add(1, "months");
    }


    var dateString = addedDate.format('D.MM.YYYY');
    return dateString;
  }


  static getTimestampAsShortDateString = (timestamp) => {
    if (timestamp == null) {
      return "N/A";
    }

    var date = Moment(timestamp);
    var today = Moment(new Date());
    if (date.diff(today, "days") == 0) {
      return "today";
    }

    var yesterday = Moment(new Date()).add(-1, "days");
    if (date.diff(yesterday, "days") == 0) {
      return "yesterday";
    }

    var dateString = date.format('D.MM.YYYY');
    return dateString;
  }

  static getDistanceFromGeometry(lat1, lon1, lat2, lon2) {
    // The math module contains a function
    // named toRadians which converts from
    // degrees to radians.
    lon1 =  lon1 * Math.PI / 180;
    lon2 = lon2 * Math.PI / 180;
    lat1 = lat1 * Math.PI / 180;
    lat2 = lat2 * Math.PI / 180;

    // Haversine formula
    let dlon = lon2 - lon1;
    let dlat = lat2 - lat1;
    let a = Math.pow(Math.sin(dlat / 2), 2)
      + Math.cos(lat1) * Math.cos(lat2)
      * Math.pow(Math.sin(dlon / 2),2);

    let c = 2 * Math.asin(Math.sqrt(a));

    // Radius of earth in kilometers. Use 3956
    // for miles
    let r = 6371;

    // calculate the result
    return Number(c * r);
  }

  static getFileExtention(fileUrl) {
    var ext = "";
    try {
      var exts = /[.]/.exec(fileUrl) ? /[^.]+$/.exec(fileUrl) : undefined;
      if (exts != undefined && exts.length > 0) {
        ext = exts[0];
      }
    } catch(error) {}

    return ext
  };

  static getPastPeriodInUnitByMilliseconds(millis) {
    var now = Date.now();
    var diff = now - millis;

    // year
    if (diff >= 365 * 24 * 60 * 60 * 1000) {
      return parseInt(diff / (365 * 24 * 60 * 60 * 1000), 10) + "y";
    }

    // month
    if (diff >= 30 * 24 * 60 * 60 * 1000) {
      return parseInt(diff / (30 * 24 * 60 * 60 * 1000), 10) + "M";
    }

    // day
    if (diff >= 24 * 60 * 60 * 1000) {
      return parseInt(diff / (24 * 60 * 60 * 1000), 10) + "d";
    }

    // hour
    if (diff >= 60 * 60 * 1000) {
      return parseInt(diff / (60 * 60 * 1000), 10) + "h";
    }

    // min
    if (diff >= 60 * 1000) {
      return parseInt(diff / (60 * 1000), 10) + "m";
    }

    // second
    /*if (diff >= 1000) {
      return parseInt(diff / 1000, 10) + "s";
    }*/

    return "now";
  }

  /* static hash = async (text) => {
    var hashedText = text;
    try {
      var salt = await BcryptReactNative.getSalt(10);
      hashedText = await BcryptReactNative.hash(salt, text);
    } catch(error) {
      console.error("[Utility] hash() -> error: ", error);
    }

    return hashedText;
  } */

  static isGoogle(url) {
    if (url.includes("google.co")) {
      return true;
    }

    return false;
  }

  static isSVG(url) {
    if (url == null || url.length < 4) {
      return false;
    }

    var extension = url.substring(url.length - 4).toLowerCase();
    if (extension == ".svg") {
      return true;
    }

    return false;
  }

  static isUrl = (url) => {
    /*
    var isUrl = true;
    try {
      var urlObj = new URL(url);
      console.log("\turlObj: " + JSON.stringify(urlObj));
    } catch(error) {
      console.log("[Utility] isUrl() -> error: " + error.message);

      isUrl = false;
    }

    return isUrl;
    */

    return validator.isURL(url);
  }

  static isUrlWithoutProtocol(url) {
    if (url == "") {
      return false;
    }

    var pattern = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    var regExp = new RegExp(pattern);
    return url.match(regExp);
  }

  static roundWith2Decimals(num) {
    return Math.round(num * 100) / 100;
  }

  static trim(text) {
    text = text.trim().toLowerCase();
    text = text.replaceAll(/(\r\n|\n|\r|\t|@|-|!|\")/gm, " ");
    text = text.replaceAll("'", " ");
    text = text.replaceAll("&", " ");
    text = text.replaceAll("?", " ");
    text = text.replaceAll("*", " ");
    text = text.replaceAll("<", " ");
    text = text.replaceAll(">", " ");
    text = text.replaceAll("[", " ");
    text = text.replaceAll("]", " ");
    text = text.replaceAll("{", " ");
    text = text.replaceAll("}", " ");
    return text;
  }

  static isEmailValid(email) {
    // Regular expression for validating an Email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
}
