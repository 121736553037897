import { ObjectId } from 'bson';

import MongoCallback from "./callback";
import MongoEngine from ".";
import MongoEnviromateEngine from "./enviromates";
import MongoFunding from "./funding";
import MongoFundingEngine from "./fundings";
import MongoMediaEngine from "./medias";
import MongoLocation from "./location";
import MongoLocationEngine from "./locations";
import MongoNotificationEngine from "./notifications";
import MongoProject from "./project";
import MongoProjectCommentEngine from "./projectcomments";
import MongoProjectDislikeEngine from "./projectdislikes";
import MongoProjectFollowEngine from "./projectfollows";
import MongoProjectLikeEngine from "./projectlikes";
import MongoProjectPinEngine from "./projectpins";
import MongoProjectPledge from "./projectpledge";
import MongoProjectPledgeEngine from "./projectpledges";
import MongoProjectRead from './projectread';
import MongoProjectReadEngine from './projectreads';
import MongoProjectUpdateEngine from "./projectupdates";
import MongoProjectUpdateLikeEngine from "./projectupdatelikes";
import MongoProjectUpdateReadEngine from "./projectupdatereads";
import MongoTeam from "./team";
import MongoTeamEngine from "./teams";
import MongoUserEngine from "./users";

import Utility from '../utility';

import { HEART_VALUE } from '../constants/globalconstants';

export default class MongoProjectEngine {
  static changeListeners = [];

  static async add(project) {
    try {
      var db = await MongoEngine.getDB();
      var result = await db.collection("Project").insertOne(project);
      project._id = result.insertedId;
    } catch(error) {
      console.log("[MongoProjectEngine] add() -> error: ", error);
    }
  }

  static async addAsTeamMember(project, user, invitorId) {
    try {
      var member = project.members.find(m => m.memberId == user.docId);
      if (member == null) {
        member = MongoTeamEngine.generateByTypeAndMemberAndInvitorId(MongoTeam.TYPE_MEMBER, user, invitorId, false);
        project.members.push(member);
      } else {
        member.type = MongoTeam.TYPE_MEMBER;
        if (invitorId != undefined && invitorId != null && invitorId != "") {
          member.invitorId = invitorId;
        }
        member.reject = false;
      }

      await MongoProjectEngine.update(project);

      // notify
      var invitor = await MongoUserEngine.getByDocId(invitorId);
      MongoNotificationEngine.addProjectInvitationAccept(project, user, invitor);
    } catch(error) {
      console.error("[MongoProjectEngine] addAsTeamMember() -> error: ", error);
    }
  }

  static async addMicroCrowdFunding(project, microFunding) {
    try {
      if (project == null) {
        return;
      }

      var funding = project.funding;
      if (funding == null) {
        funding = new MongoFunding();
        funding.currencyCode = microFunding.currencyCode;
      }
      funding.target += Number(microFunding.target);

      await MongoProjectEngine.update(project);
    } catch(error) {
      console.error("[MongoProjectEngine] addMicroCrowdFunding() -> error: ", error);
    }
  }

  static async cancelInvitationAsTeamMember(project, inviteeId) {
    try {
      // remove from project
      var invitee = project.members.find(m => m.memberId == inviteeId);
      if (invitee == null || invitee.type != MongoTeam.TYPE_INVITED) {
        return;
      }

      var index = project.members.findIndex(m => m.memberId == inviteeId);
      if (index < 0) {
        return;
      }

      project.members.splice(index, 1);

      await MongoProjectEngine.update(project);

      // remove from notification
      MongoNotificationEngine.deleteProjectInvitation(project.docId, inviteeId);
    } catch(error) {
      console.error("[MongoProjectEngine] cancelInvitationAsTeamMember() -> error: ", error);
    }
  }

  static clone = (json) => {
    var project = new MongoProject();
    try {
      if (json.title != undefined) {
        project.title = json.title;
      }

      if (json.medias != undefined && json.medias != null) {
        json.medias.forEach(media => {
          try {
            var mongoMedia = MongoMediaEngine.fromJson(media);
            project.medias.push(mongoMedia);
          } catch(error) {
            console.log("[MongoProjectEngine] fromJson() -> error: ", error);
          }
        });
      }

      if (json.summary != undefined) {
        project.summary = json.summary;
      }
      if (json.description != undefined) {
        project.description = json.description;
      }
      if (json.category != undefined) {
        project.category = json.category;
      }
      if (json.tags != undefined) {
        json.tags.forEach(tag => {
          project.tags.push(tag);
        });
      }

      if (json.location != undefined) {
        try {
          var location = MongoLocationEngine.fromJson(json.location);
          project.location = location;
        } catch(error) {
          console.log("[MongoProjectEngine] fromJson() -> error: ", error);
        }
      }
      if (project.location == null) {
        project.location = new MongoLocation();
      }

      if (json.funding != undefined) {
        try {
          var funding = MongoFundingEngine.fromJson(json.funding);
          project.funding = funding;
        } catch(error) {
          console.log("[MongoProjectEngine] fromJson() -> error: ", error);
        }
      }
      if (project.funding == null) {
        project.funding = new MongoFunding();
      }
      funding.funded = 0;

      if (json.mentorAvailablility != undefined) {
        project.mentorAvailablility = json.mentorAvailablility;
      }
    } catch(error) {
      console.log("[MongoProjectEngine] clone() -> error: ", error);
    }

    return project;
  }

  static async delete(project) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("Project");
      var filter = {
        docId: project.docId,
      };
      var result = await collection.deleteOne(filter);

      // related
      await MongoProjectFollowEngine.deleteByProjectId(project.docId);
      await MongoProjectLikeEngine.deleteByProjectId(project.docId);
      await MongoProjectPinEngine.deleteByProjectId(project.docId);
      await MongoProjectReadEngine.deleteByProjectId(project.docId);

      await MongoProjectUpdateEngine.deleteByProjectId(project.docId);
      await MongoProjectUpdateLikeEngine.deleteByProjectId(project.docId);
      await MongoProjectUpdateReadEngine.deleteByProjectId(project.docId);

      await MongoProjectCommentEngine.deleteByProjectId(project.docId);
    } catch(error) {
      console.log("[MongoProjectEngine] delete() -> error: ", error);
    }
  }

  static async deleteAll() {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("Project");
      var result = await collection.deleteMany();
    } catch(error) {
      console.log("[MongoProjectEngine] deleteAll() -> error: ", error);
    }
  }

  static fromJson = (json) => {
    var project = new MongoProject();
    try {
      if (json._id != undefined) {
        project._id = new ObjectId(json._id);
      }

      if (json.showcaseId != undefined) {
        project.showcaseId = json.showcaseId;
      }

      if (json.creatorId != undefined) {
        project.creatorId = json.creatorId;
      }
      if (json.creatorName != undefined) {
        project.creatorName = json.creatorName;
      }
      if (json.creatorLogoUrl != undefined) {
        project.creatorLogoUrl = json.creatorLogoUrl;
      }

      project.docId = MongoEngine.objectIdToString(json.docId);
      if (project.docId == "") {
        project.docId = project._id.valueOf().toString();
      }

      if (json.title != undefined) {
        project.title = json.title;
      }
      if (json.titleAsDone != undefined) {
        project.titleAsDone = json.titleAsDone;
      }

      if (json.medias != undefined && json.medias != null) {
        json.medias.forEach(media => {
          try {
            var mongoMedia = MongoMediaEngine.fromJson(media);
            project.medias.push(mongoMedia);
          } catch(error) {
            console.log("[MongoProjectEngine] fromJson() -> error: ", error);
          }
        });
      }
      if (json.mediasAsDone != undefined) {
        project.mediasAsDone = json.mediasAsDone;
      }

      if (json.summary != undefined) {
        project.summary = json.summary;
      }
      if (json.description != undefined) {
        project.description = json.description;
      }
      if (json.category != undefined) {
        project.category = json.category;
      }
      if (json.tags != undefined) {
        json.tags.forEach(tag => {
          project.tags.push(tag);
        });
      }
      if (json.descriptionAsDone != undefined) {
        project.descriptionAsDone = json.descriptionAsDone;
      }

      if (json.location != undefined) {
        try {
          var location = MongoLocationEngine.fromJson(json.location);
          project.location = location;
        } catch(error) {
          console.log("[MongoProjectEngine] fromJson() -> error: ", error);
        }
      }
      if (project.location == null) {
        project.location = new MongoLocation();
      }
      if (json.locationAsDone != undefined) {
        project.locationAsDone = json.locationAsDone;
      }

      if (json.funding != undefined) {
        try {
          var funding = MongoFundingEngine.fromJson(json.funding);
          project.funding = funding;
        } catch(error) {
          console.log("[MongoProjectEngine] fromJson() -> error: ", error);
        }
      }
      if (project.funding == null) {
        project.funding = new MongoFunding();
      }
      if (json.fundingAsDone != undefined) {
        project.fundingAsDone = json.fundingAsDone;
      }

      if (json.members != undefined) {
        json.members.forEach(member => {
          try {
            var team = MongoTeamEngine.fromJson(member);
            project.members.push(team);
          } catch(error) {
            console.log("[MongoProjectEngine] fromJson() -> error: ", error);
          }
        });
      }
      if (json.membersAsDone != undefined) {
        project.membersAsDone = json.membersAsDone;
      }

      if (json.mentorAvailablility != undefined) {
        project.mentorAvailablility = json.mentorAvailablility;
      }
      if (json.coFundingAsDone != undefined) {
        project.coFundingAsDone = json.coFundingAsDone;
      }

      if (json.commentCount != undefined) {
        project.commentCount = json.commentCount;
      }
      if (json.postCount != undefined) {
        project.postCount = json.postCount;
      }

      if (json.pinCount != undefined) {
        project.pinCount = json.pinCount;
      }
      if (json.followCount != undefined) {
        project.followCount = json.followCount;
      }
      if (json.likeCount != undefined) {
        project.likeCount = json.likeCount;
      }
      if (json.supporterCount != undefined) {
        project.supporterCount = json.supporterCount;
      }
      if (json.readCount != undefined) {
        project.readCount = json.readCount;
      }

      if (json.status != undefined) {
        project.status = json.status;
      }

      if (json.timestamp != undefined) {
        project.timestamp = json.timestamp;
      }
      if (json.updatedTimestamp != undefined) {
        project.updatedTimestamp = json.updatedTimestamp;
      }
      if (json.launchedTimestamp != undefined) {
        project.launchedTimestamp = json.launchedTimestamp;
      }
    } catch(error) {
      console.log("[MongoProjectEngine] fromJson() -> error: ", error);
    }

    return project;
  }

  static fromShowcase(showcase) {
    var project = new MongoProject();
    var template = showcase.template;
    if (template == null) {
      return project;
    }

    try {
      project.showcaseId = showcase.docId;

      if (template.title != undefined) {
        project.title = template.title;
      }

      if (template.medias != undefined && template.medias != null) {
        template.medias.forEach(media => {
          try {
            var mongoMedia = MongoMediaEngine.fromJson(media);
            project.medias.push(mongoMedia);
          } catch(error) {
            console.log("[MongoProjectEngine] fromShowcase() -> error: ", error);
          }
        });
      }

      if (template.summary != undefined) {
        project.summary = template.summary;
      }
      if (template.description != undefined) {
        project.description = template.description;
      }
      if (template.category != undefined) {
        project.category = template.category;
      }
      if (template.tags != undefined) {
        template.tags.forEach(tag => {
          project.tags.push(tag);
        });
      }

      if (template.funding != undefined) {
        try {
          var funding = MongoFundingEngine.fromJson(template.funding);
          project.funding = funding;
        } catch(error) {
          console.log("[MongoProjectEngine] fromShowcase() -> error: ", error);
        }
      }
      if (project.funding == null) {
        project.funding = new MongoFunding();
      }
    } catch(error) {
      console.log("[MongoProjectEngine] fromShowcase() -> error: ", error);
    }

    return project;
  }

  static async fund(project, mongoPledge) {
    try {
      var mongoFunding = project.funding;
      if (mongoFunding == null) {
        mongoFunding = new MongoFunding();
      }

      var amountInCents = mongoPledge.amount;
      if (mongoPledge.type == MongoProjectPledge.TYPE_HEARTS) {
        amountInCents = mongoPledge.amount / HEART_VALUE;
      } else if (mongoPledge.type == MongoProjectPledge.TYPE_CASH) {
        // calculate amount in cash
      }
      mongoFunding.funded += amountInCents;
      project.funding = mongoFunding;

      // update supporter count of project
      var pledgeCount = await MongoProjectPledgeEngine.getCountByProjectIdAndUserId(project.docId, mongoPledge.pledgerId);
      if (pledgeCount == 1) {
        project.supporterCount++;
      }

      await MongoProjectEngine.update(project);

      // notification
      var fundedAmount = mongoFunding.raised + mongoFunding.funded;
      if (fundedAmount >= mongoFunding.target) { // project funded over 100 percents
        MongoNotificationEngine.addProjectFunded(project);
      } else {
        MongoNotificationEngine.addProjectPledged(project, mongoPledge);
      }
    } catch(error) {
      console.log("[MongoProjectEngine] fund() -> error: " + error.message);
    }
  }

  static async getByCreatorId(creatorId) {
    var projects = [];
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("Project");
      var filter = {
        creatorId: creatorId,
      };
      var sort = {
        timestamp: -1,
      };
      var mongoProjects = await collection.find(filter, {
        sort
      });

      mongoProjects.forEach(mongoProject => {
        var project = MongoProjectEngine.fromJson(mongoProject);
        projects.push(project);
      });
    } catch(error) {
      console.log("[MongoProjectEngine] getByCreatorId() -> error: ", error);
    }

    return projects;
  }

  static getByDocId = async (docId) => {
    var project = null;
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("Project");
      var filter = {
        docId: docId.toString(),
      };

      var mongoProjects = await collection.find(filter);
      if (mongoProjects.length == 0) {
        return null;
      }

      project = MongoProjectEngine.fromJson(mongoProjects[0]);
    } catch(error) {
      console.log("[MongoProjectEngine] getByDocId() -> error: ", error);
    }

    return project;
  }

  static async getByUserId(userId, curated) {
    var projects = [];
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("Project");
      var filter = {
        $or: [
          { creatorId: userId },
          {
            "members.memberId": userId ,
            "members.type": { $ne: MongoTeam.TYPE_INVITED },
          }
        ],
      };

      var sort = {
        timestamp: -1,
      };

      var mongoProjects = await collection.find(filter, {
        sort
      });

      mongoProjects.forEach(mongoProject => {
        var project = MongoProjectEngine.fromJson(mongoProject);
        projects.push(project);
      });
    } catch(error) {
      console.log("[MongoProjectEngine] getByUserId() -> error: ", error);
    }

    return projects;
  }

  static async getCountByUserId(userId) {
    var count = 0;
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("Project");
      var filter = {
        $or: [
          { creatorId: userId },
          {
            "members.memberId": userId ,
            "members.type": { $ne: MongoTeam.TYPE_INVITED },
          }
        ],
      };

      count = await collection.count(filter);
    } catch(error) {
      console.log("[MongoProjectEngine] getCountByUserId() -> error: ", error);
    }

    return count;
  }

  static getDistance(project, location) {
    if (project == null) {
      return 1000000;
    }

    if (location == null) {
      return 1000000;
    }

    if (project.location == null) {
      return 1000000;
    }

    var projectLocation = project.location;
    var distance = Utility.getDistanceFromGeometry(location.latitude, location.longitude, projectLocation.latitude, projectLocation.longitude);

    return distance;
  }

  static async getForDiscoverByUserId(userId) {
    var projects = [];
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("Project");
      var filter = {
        status: MongoProject.STATUS_ONLINE,
        //"funding.crowdFunding": false,
      };

      // if (userId != "") {
      //   filter = {
      //     ...filter,
      //     ...{
      //       creatorId: { $ne: userId },
      //       "members.memberId": { $ne: userId },
      //     }
      //   };
      // }

      var sort = {
        launchedTimestamp: -1,
      };

      var mongoProjects = await collection.find(filter, {
        sort
      });

      mongoProjects.forEach(mongoProject => {
        var project = MongoProjectEngine.fromJson(mongoProject);
        projects.push(project);
      });
    } catch(error) {
      console.log("[MongoProjectEngine] getForDiscoverByUserId() -> error: ", error);
    }

    return projects;
  }

  static getFundedAmount(project) {
    var funded = 0;
    try {
      var funding = project.funding;
      if (funding != null) {
        funded = Number(funding.raised) + Number(funding.funded);
      }
    } catch(error) {
      console.error("[MongoProjectEngine] getFundedAmount() -> error: ", error.message);
    }

    return funded;
  }

  static getFundedAmountWithDecimals(project, decimals) {
    var funded = 0;
    try {
      var funding = project.funding;
      if (funding != null) {
        funded = (Number(funding.raised) + Number(funding.funded)).toFixed(decimals);
      }
    } catch(error) {
      console.error("[MongoProjectEngine] getFundedAmountWithDecimals() -> error: ", error.message);
    }

    return funded;
  }

  static getFundedPercent(project) {
    var percent = 0;
    try {
      var funding = project.funding;
      if (funding != null && funding.target > 0) {
        percent = (Number(funding.raised) + Number(funding.funded)) / Number(funding.target) * 100;
      }
    } catch(error) {
      console.error("[MongoProjectEngine] getFundedPercent() -> error: ", error.message);
    }

    return percent;
  }

  static getFundedPercentWithDecimals(project, decimals) {
    var percent = 0;
    try {
      var decimalDigit = 10 * decimals;
      var funding = project.funding;
      if (funding != null && funding.target > 0) {
        percent = Math.floor(((Number(funding.raised) + Number(funding.funded)) / Number(funding.target) * 100 * decimalDigit)) / decimalDigit;
      }
    } catch(error) {
      console.error("[MongoProjectEngine] getFundedPercentWithDecimals() -> error: ", error.message);
    }

    return percent;
  }

  static async getInvitedByUserId(userId) {
    var projects = [];
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("Project");
      var pins = await MongoProjectPinEngine.getProjectIdsByUserId(userId);
      var filter = {
        members: {
          memberId: userId,
          type: MongoTeam.TYPE_INVITED,
          reject: false,
        },
        docId: { $nin: pins },
      };
      var sort = {
        timestamp: -1,
      };
      
      var mongoProjects = await collection.find(filter, {
        sort
      });

      mongoProjects.forEach(mongoProject => {
        var project = MongoProjectEngine.fromJson(mongoProject);
        projects.push(project);
      });
    } catch(error) {
      console.log("[MongoProjectEngine] getInvitedByUserId() -> error: ", error);
    }

    return projects;
  }

  static getLikedCount(project) {
    if (project == null || project == undefined) {
      return 0;
    }

    var count = 0;
    try {
      count = project.likerIds.length;
    } catch(error) {
      console.log("[MongoProjectEngine] getLikedCount() -> error: " + error.message);
    }

    return count;
  }

  static getLogoImage(project) {
    if (!project.medias || project.medias.length == 0) {
      return null;
    }

    var logo = null;
    project.medias.forEach(media => {
      if (logo == null && media.type == "image") {
        logo = media;
      }
    });

    if (logo == null) {
      logo = project.medias[0];
    }

    return logo;
  }

  static getLogoVideo(project) {
    if (!project.medias || project.medias.length == 0) {
      return null;
    }

    var logo = null;
    project.medias.forEach(media => {
      if (logo == null && media.type == "video") {
        logo = media;
      }
    });

    if (logo == null) {
      logo = project.medias[0];
    }

    return logo;
  }

  static getMemberByProjectAndUserId(project, userId) {
    var member = null;
    try {
      member = project.members.find(m => m.memberId == userId);
    } catch(error) {
      console.error("[MongoProjectEngine] getMemberByProjectAndUserId() -> error: " + error.message);
    }

    return member;
  }

  static async getMembersByProject(project, all) {
    var members = [];
    try {
      // member docids
      var memberDocIds = [project.creatorId];
      project.members.forEach(member => {
        if (all == false && member.type == MongoTeam.TYPE_INVITED) {
          return;
        }

        memberDocIds.push("" + member.memberId);
      });

      members = await MongoUserEngine.getByDocIds(memberDocIds);
    } catch(error) {
      console.log("[MongoProjectEngine] getMembersByProject() -> error: " + error.message);
    }

    return members;
  }

  static async getNonMembersByProject(project) {
    var members = [];
    try {
      // member docids
      var memberDocIds = [project.creatorId];
      project.members.forEach(member => {
        memberDocIds.push("" + member.memberId);
      });

      members = await MongoUserEngine.getForExcludesByDocIds(memberDocIds);
    } catch(error) {
      console.error("[MongoProjectEngine] getNonMembersByProject() -> error: ", error.message);
    }

    return members;
  }

  static async getPinnedForEnviromatesByUserId(userId) {
    var projects = [];
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("Project");
      var pins = await MongoProjectPinEngine.getProjectIdsByUserId(userId);
      var filter = {
        docId: { $in: pins } ,
        creatorId: { $ne: userId },
        //"members.memberId": { $ne: userId }
      };
      var sort = {
        timestamp: -1,
      };

      var mongoProjects = await collection.find(filter, {
        sort
      });

      mongoProjects.forEach(mongoProject => {
        var project = MongoProjectEngine.fromJson(mongoProject);
        projects.push(project);
      });
    } catch(error) {
      console.log("[MongoProjectEngine] getPinnedForEnviromatesByUserId() -> error: ", error);
    }

    return projects;
  }

  static async getPinnedForMembersByUserId(userId) {
    var projects = [];
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("Project");
      var pins = await MongoProjectPinEngine.getProjectIdsByUserId(userId);
      var filter = {
        docId: { $in: pins },
        '$or': [
          {'creatorId': userId},
          {
            'members.memberId': userId, 
            'members.type': {'$ne': MongoTeam.TYPE_INVITED}
          }
        ],
      };
      var sort = {
        timestamp: -1,
      };

      var mongoProjects = await collection.find(filter, {
        sort
      });

      mongoProjects.forEach(mongoProject => {
        var project = MongoProjectEngine.fromJson(mongoProject);
        projects.push(project);
      });
    } catch(error) {
      console.log("[MongoProjectEngine] getPinnedForMembersByUserId() -> error: ", error);
    }

    return projects;
  }

  static getPreparedPercent = (project) => {
    var totalStep = 0;
    var completedStep = 0;

    if (project.titleAsDone == true) {
      completedStep++;
    }
    totalStep++;

    if (project.mediasAsDone == true) {
      completedStep++;
    }
    totalStep++;
    
    if (project.descriptionAsDone == true) {
      completedStep++;
    }
    totalStep++;
    
    if (project.locationAsDone == true) {
      completedStep++;
    }
    totalStep++;

    if (project.fundingAsDone == true) {
      completedStep++;
    }
    totalStep++;

    if (project.membersAsDone == true) {
      completedStep++;
    }
    totalStep++;

    if (project.coFundingAsDone == true) {
      completedStep++;
    }
    totalStep++;

    return completedStep / totalStep * 100;
  }

  static async getUnpinnedForEnviromatesByUserId(userId) {
    var projects = [];
    try {
      var enviromate = await MongoEnviromateEngine.getByUserId(userId);
      var enviromateIds = MongoEnviromateEngine.getEnviromateIds(enviromate);
      var dislikeIds = [];
      var dislike = await MongoProjectDislikeEngine.getByUserId(userId);
      if (dislike != null) {
        dislikeIds = dislike.projectIds;
      }

      var pins = await MongoProjectPinEngine.getProjectIdsByUserId(userId);
      var db = await MongoEngine.getDB();
      var collection = db.collection("Project");
      var filter = {
        '$or': [
          {'creatorId': {'$in': enviromateIds}},
          {
            'members.memberId': {'$in': enviromateIds}, 
            'members.type': {'$ne': MongoTeam.TYPE_INVITED}
          }
        ],
        "creatorId": {"$ne": userId},
        //"members.memberId": {"$ne": userId},
        "$and": [
          {'docId': {'$nin': dislikeIds}},
          {"docId": {"$nin": pins}},
        ]
      };
      var sort = {
        timestamp: -1,
      };

      var mongoProjects = await collection.find(filter, {
        sort
      });

      mongoProjects.forEach(mongoProject => {
        var project = MongoProjectEngine.fromJson(mongoProject);
        projects.push(project);
      });
    } catch(error) {
      console.log("[MongoProjectEngine] getUnpinnedForEnviromatesByUserId() -> error: ", error);
    }

    return projects;
  }

  static async getUnpinnedForMembersByUserId(userId) {
    var projects = [];
    try {
      var pins = await MongoProjectPinEngine.getProjectIdsByUserId(userId);
      var db = await MongoEngine.getDB();
      var collection = db.collection("Project");
      var filter = {
        '$or': [
          {'creatorId': userId},
          {
            'members.memberId': userId, 
            'members.type': {'$ne': MongoTeam.TYPE_INVITED}
          }
        ],
        "docId": {"$nin": pins}
      };
      var sort = {
        timestamp: -1,
      };

      var mongoProjects = await collection.find(filter, {
        sort
      });

      mongoProjects.forEach(mongoProject => {
        var project = MongoProjectEngine.fromJson(mongoProject);
        projects.push(project);
      });
    } catch(error) {
      console.log("[MongoProjectEngine] getUnpinnedForMembersByUserId() -> error: ", error);
    }

    return projects;
  }

  static getUnreadCountByUserId = async (userId) => {
    var count = 0;
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("Project");
      if (userId != undefined && userId != null && userId != "") {
        var match = {
          status: MongoProject.STATUS_ONLINE,
          "reads.readerIds": {$ne: userId}
        };

        if (userId != "") {
          match = {
            ...match,
            ...{
              creatorId: { $ne: userId },
              "members.memberId": { $ne: userId },
            }
          };
        }

        var aggregate = [
          {
            $lookup: {
              from: "ProjectRead",
              localField: "docId",
              foreignField: "projectId",
              as: "reads"
            }
          },
          {
            $project: {
              docId: 1,
              reads: {
                readerIds: 1
              }
            }
          },
          {
            $match: match
          },
          {
            $count: "count"
          },
        ];

        var ret = await collection.aggregate(aggregate);
        if (ret.length > 0) {    
          count = ret[0].count;
        }
      } else {
        var filter = {
          status: MongoProject.STATUS_ONLINE,
        };

        count = await collection.count(filter);
      }
    } catch(error) {
      console.log("[MongoProjectEngine] getUnreadCountByUserId() -> error: ", error);
    }

    return count;
  }

  static async inviteAsTeamMember(project, invitorId, invitee) {
    try {
      var mentor = MongoTeamEngine.generateByTypeAndMemberAndInvitorId(MongoTeam.TYPE_INVITED, invitee, invitorId, false);
      project.members.push(mentor);
      await MongoProjectEngine.update(project);
    } catch(error) {
      console.log("[MongoProjectEngine] inviteAsTeamMember() -> error: ", error);
    }
  }

  static isAdministratorByUserId(project, userId) {
    try {
      // check if creator
      if (MongoProjectEngine.isTeamCreatorByUserId(project, userId)) {
        return true;
      }

      // check if cocreator
      if (MongoProjectEngine.isTeamCoCreatorByUserId(project, userId)) {
        return true;
      }
    } catch(error) {
      console.log("[MongoProjectEngine] isAdministratorByMemberDocId() -> error: " + error);
    }

    return false;
  }

  static isCurated = (project) => {
    return false;
  }

  static isDescriptionEdited = (project) => {
    if (project.summary != "") {
      return true;
    }
    if (project.description != "") {
      return true;
    }
    if (project.category != "") {
      return true;
    }
    if (project.tags.length > 0) {
      return true;
    }

    return false;
  }

  static isFundingEdited = (project) => {
    if (project.funding == null) {
      return false;
    }

    var funding = project.funding;
    return MongoFundingEngine.isEdited(funding);
  }

  static isLaunchable = (project) => {
    if (project.titleAsDone != true) {
      return false;
    }

    if (project.mediasAsDone != true) {
      return false;
    }

    if (project.descriptionAsDone != true) {
      return false;
    }

    if (project.locationAsDone != true) {
      return false;
    }

    if (project.fundingAsDone != true) {
      return false;
    }

    if (project.membersAsDone != true) {
      return false;
    }

    if (project.coFundingAsDone != true) {
      return false;
    }

    return true;
  }

  static isLocationEdited = (project) => {
    if (project.location == null) {
      return false;
    }

    var location = project.location;
    return MongoLocationEngine.isEdited(location);
  }

  static isTeamByUserId(project, userId) {
    try {
      // check if creator
      if (MongoProjectEngine.isTeamCreatorByUserId(project, userId)) {
        return true;
      }

      // check if cocreator
      if (MongoProjectEngine.isTeamCoCreatorByUserId(project, userId)) {
        return true;
      }

      // check if member
      if (MongoProjectEngine.isTeamMemberByUserId(project, userId)) {
        return true;
      }
    } catch(error) {
      console.log("[MongoProjectEngine] isTeamByUserId() -> error: " + error);
    }

    return false;
  }

  static isTeamCreatorByUserId(project, userId) {
    if (project.creatorId == userId) {
      return true;
    }

    return false;
  }

  static isTeamCoCreatorByUserId(project, userId) {
    var members = project.members;
    var mentor = members.find(m => m.memberId == userId);
    if (mentor == null) {
      return false;
    }

    return MongoTeamEngine.isCocreator(mentor);
  }

  static isTeamMemberByUserId(project, userId) {
    var members = project.members;
    var mentor = members.find(m => m.memberId == userId);
    if (mentor == null) {
      return false;
    }

    return MongoTeamEngine.isMember(mentor);
  }

  static isTeamInvitedByUserId(project, userId) {
    var members = project.members;
    var mentor = members.find(m => m.memberId == userId);
    if (mentor == null) {
      return false;
    }

    return MongoTeamEngine.isInvited(mentor);
  }

  static isTeamRejectByUserId(project, userId) {
    var members = project.members;
    var mentor = members.find(m => m.memberId == userId);
    if (mentor == null) {
      return false;
    }

    return MongoTeamEngine.isReject(mentor);
  }

  static async launch(project, launcher, relaunch) {
    try {
      await MongoProjectEngine.update(project);

      // notification
      if (relaunch != true) {
        MongoNotificationEngine.addProjectLaunched(project, launcher);
      }
    } catch(error) {
      console.log("[MongoProjectEngine] launch() -> error: " + error.message);
    }
  }

  static async read(projectId, readerId) {
    if (readerId == null || readerId == undefined) {
      return;
    }

    try {
      var read = await MongoProjectReadEngine.getByProjectId(projectId);
      if (read == null) {
        read = new MongoProjectRead();
        read.projectId = projectId;
        await MongoProjectReadEngine.add(read);
      }

      await MongoProjectReadEngine.read(read, readerId);
    } catch(error) {
      console.log("[MongoProjectEngine] read() -> error: " + error.message);
    }
  }

  static refine = async () => {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("Project");
      var mongoProjects = await collection.find();
      for (var mongoProject of mongoProjects) {
        var project = MongoProjectEngine.fromJson(mongoProject);
        await MongoProjectEngine.update(project);
      }
    } catch(error) {
      console.log("[MongoProjectEngine] refine() -> error: ", error);
    }
  }

  static registerChangeListener = (callback) => {
    try {
      var mongoCallback = new MongoCallback();
      mongoCallback.callback = callback;
      this.changeListeners.push(mongoCallback);
    } catch(error) {
      console.error("[MongoProjectEngine] registerChangeListener() -> error: ", error.message);
    }
  }

  static async rejectInvite(project, user, invitorId) {
    try {
      var member = project.members.find(m => m.memberId == user.docId);
      if (member == null) {
        return;
      }

      member.type = MongoTeam.TYPE_INVITED;
      if (invitorId != undefined && invitorId != null && invitorId != "") {
        member.invitorId = invitorId;
      }
      member.reject = true;

      await MongoProjectEngine.update(project);

      // notify
      var invitor = await MongoUserEngine.getByDocId(invitorId);
      MongoNotificationEngine.addProjectInvitationReject(project, user, invitor);
    } catch(error) {
      console.log("[MongoProjectEngine] rejectInvite() -> error: ", error);
    }
  }

  static unregisterChangeListenerByListenerId = (callbackId) => {
    try {
      var index = this.changeListeners.find(callback => callback.id == callbackId);
      if (index < 0) {
        return;
      }

      this.changeListeners.splice(index, 1);
    } catch(error) {
      console.error("[MongoProjectEngine] unregisterChangeListenerByListenerId() -> error: ", error.message);
    }
  }

  static async update(project) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("Project");
      project.updatedTimestamp = new Date();
      var result = await collection.updateOne(
        { _id: project._id },
        { $set: project },
        { upsert: true },
      );
    } catch(error) {
      console.log("[MongoProjectEngine] update() -> error: ", error);
    }
  }

  static watchForChanges = async () => {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("Project");
      var changeStream = collection.watch();
      for await (var change of changeStream) {
        this.changeListeners.forEach(listener => {
          try {
            listener.callback();
          } catch(error) {}
        });
      }
    } catch(error) {
      console.error("[MongoProjectEngine] watchForChanges() -> error: ", error.message);
    }
  }
}
