export default class MongoFunding {
  currencyCode = "GBP";

  crowdFunding = false;

  raised = 0;             // cent
  target = 0;             // cent
  monthly = 0;            // cent

  funded = 0;             // cent
}
