import MongoEngine from ".";
import MongoProjectLike from "./projectlike";
import MongoProjectEngine from "./projects";
import MongoUserEngine from "./users";

export default class MongoProjectLikeEngine {
  static async add(like) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectLike");
      var result = await collection.insertOne(like);
      like._id = result.insertedId;
    } catch(error) {
      console.log("[MongoProjectLikeEngine] add() -> error: ", error);
    }
  }

  static async deleteByProjectId(projectId) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectLike");
      var filter = {
        projectId: projectId,
      };
      var result = await collection.deleteMany(filter);
    } catch(error) {
      console.log("[MongoProjectLikeEngine] deleteByProjectId() -> error: ", error);
    }
  }

  static async deleteAll() {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectLike");
      var result = await collection.deleteMany();
    } catch(error) {
      console.log("[MongoProjectLikeEngine] deleteAll() -> error: ", error);
    }
  }

  static async dislike(project, userId) {
    try {
      var like = await MongoProjectLikeEngine.getByProjectId(project.docId);
      if (like == null) {
        return false;
      }

      var likerIds = like.likerIds;
      var index = likerIds.find(likerId => likerId == userId);
      if (index < 0) {
        return false;
      }

      likerIds.splice(index, 1);
      like.likerIds = likerIds;

      await MongoProjectLikeEngine.update(like);

      // decrease like count of project
      project.likeCount = likerIds.length;
      await MongoProjectEngine.update(project);

      return true;
    } catch(error) {
      console.log("[MongoProjectLikeEngine] dislike() -> error: " + error.message);
    }

    return false;
  }

  static fromJson(json) {
    var like = new MongoProjectLike();
    try {
      if (json._id != undefined) {
        like._id = json._id;
      }
      if (json.projectId != undefined) {
        like.projectId = json.projectId;
      }
      if (json.likerIds != undefined) {
        like.likerIds = json.likerIds;
      }
    } catch(error) {
      console.log("[MongoProjectLikeEngine] fromJson() -> error: ", error);
    }

    return like;
  }

  static async getByProjectId(projectId) {
    var like = null;
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectLike");
      var filter = {
        projectId: projectId,
      };

      var mongoProjectLikes = await collection.find(filter);
      if (mongoProjectLikes.length == 0) {
        return null;
      }

      like = MongoProjectLikeEngine.fromJson(mongoProjectLikes[0]);
    } catch(error) {
      console.log("[MongoProjectLikeEngine] getByProjectId() -> error: ", error);
    }

    return like;
  }

  static async getCountByProjectId(projectId) {
    var count = 0;
    try {
      var like = await MongoProjectLikeEngine.getByProjectId(projectId);
      count = like.likerIds.length;
    } catch(error) {
      console.log("[MongoProjectLikeEngine] getCountByProjectId() -> error: ", error);
    }

    return count;
  }

  static async isLiked(projectId, userId) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectLike");
      var filter = {
        projectId: projectId,
        likerIds: userId,
      };

      var count = await collection.count(filter);
      if (count > 0) {
        return true;
      }
    } catch(error) {
      console.log("[MongoProjectLikeEngine] isLikedByUserId() -> error: " + error);
    }

    return false;
  }

  static async like(project, userId) {
    try {
      var like = await MongoProjectLikeEngine.getByProjectId(project.docId);
      if (like == null) {
        like = new MongoProjectLike();
        like.projectId = project.docId;
      }

      var likerIds = like.likerIds;
      var index = likerIds.find(likerId => likerId == userId);
      if (index >= 0) {
        return false;
      }

      likerIds.push(userId);
      like.likerIds = likerIds;

      await MongoProjectLikeEngine.update(like);

      // increase like count of project
      project.likeCount = likerIds.length;
      await MongoProjectEngine.update(project);

      return true;
    } catch(error) {
      console.log("[MongoProjectLikeEngine] like() -> error: " + error.message);
    }

    return false;
  }

  static async update(like) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectLike");
      var result = await collection.updateOne(
        { _id: like._id },
        { $set: like },
        { upsert: true },
      );
    } catch(error) {
      console.log("[MongoProjectLikeEngine] update() -> error: ", error);
    }
  }
}
