import MongoNotificationProjectInvitationReject from "./notificationprojectinvitationreject";
import MongoUserEngine from "./users";

export default class MongoNotificationProjectInvitationRejectEngine {
  static fromJson(json) {
    var notification = new MongoNotificationProjectInvitationReject();
    try {
      if (json.projectId != undefined) {
        notification.projectId = json.projectId;
      }
      if (json.projectTitle != undefined) {
        notification.projectTitle = json.projectTitle;
      }

      if (json.inviteeId != undefined) {
        notification.inviteeId = json.inviteeId;
      }
      if (json.inviteeName != undefined) {
        notification.inviteeName = json.inviteeName;
      }
      if (json.inviteeLogoUrl != undefined) {
        notification.inviteeLogoUrl = json.inviteeLogoUrl;
      }

      if (json.invitorId != undefined) {
        notification.invitorId = json.invitorId;
      }
      if (json.invitorName != undefined) {
        notification.invitorName = json.invitorName;
      }
      if (json.invitorLogoUrl != undefined) {
        notification.invitorLogoUrl = json.invitorLogoUrl;
      }
    } catch(error) {
      console.log("[MongoNotificationProjectInvitationRejectEngine] fromJson() -> error: ", error);
    }

    return notification;
  }

  static generateByProjectAndInviteeAndInvitor(project, invitee, invitor) {
    var reject = new MongoNotificationProjectInvitationReject();
    reject.projectId = project.docId;
    reject.projectTitle = project.title;
    reject.inviteeId = invitee.docId;
    reject.inviteeName = MongoUserEngine.getFriendlyUserName(invitee);
    reject.inviteeLogoUrl = invitee.logoUrl;

    if (invitor != null) {
      reject.invitorId = invitor.docId;
      reject.invitorName = MongoUserEngine.getFriendlyUserName(invitor);
      reject.invitorLogoUrl = invitor.logoUrl;
    }

    return reject;
  }
}
