export default class MongoNotificationProjectInvitationAccept {
  projectId = "";
  projectTitle = "";

  inviteeId = "";
  inviteeName = "";
  inviteeLogoUrl = "";

  invitorId = "";
  invitorName = "";
  invitorLogoUrl = "";
}
