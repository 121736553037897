import { ObjectId } from 'bson';

import MongoCallback from "./callback";
import MongoEngine from ".";
import MongoNotification from "./notification";
import MongoNotificationProjectFundedEngine from "./notificationprojectfundeds";
import MongoNotificationProjectPledgedEngine from "./notificationprojectpledgeds";
import MongoNotificationProjectInvitationEngine from "./notificationprojectinvitations";
import MongoNotificationProjectInvitationAcceptEngine from "./notificationprojectinvitationaccepts";
import MongoNotificationProjectInvitationRejectEngine from "./notificationprojectinvitationrejects";
import MongoNotificationProjectLaunchedEngine from "./notificationprojectlauncheds";
import MongoNotificationShowcaseInvitationEngine from "./notificationshowcaseinvitations";
import MongoNotificationShowcaseInvitationAcceptEngine from "./notificationshowcaseinvitationaccepts";
import MongoNotificationShowcaseInvitationRejectEngine from "./notificationshowcaseinvitationrejects";
import MongoNotificationShowcaseReviewEngine from "./notificationshowcasereviews";
import MongoTeam from "./team";
import MongoProjectPledgeEngine from "./projectpledges";

export default class MongoNotificationEngine {
  static changeListeners = [];

  static async add(notification) {
    try {
      var db = await MongoEngine.getDB();
      var result = await db.collection("Notification").insertOne(notification);
      notification._id = result.insertedId;
    } catch(error) {
      console.error("[MongoNotificationEngine] add() -> error: ", error);
    }
  }

  static async addMany(notifications) {
    try {
      var db = await MongoEngine.getDB();
      var result = await db.collection("Notification").insertMany(notifications);
    } catch(error) {
      console.error("[MongoNotificationEngine] addMany() -> error: ", error);
    }
  }

  static addProjectFunded = async (project) => {
    try {
      var data = MongoNotificationProjectFundedEngine.generateByProject(project);

      // to creator
      var notification = new MongoNotification();
      notification.type = MongoNotification.TYPE_PROJECT_FUNDED;
      notification.toUserId = project.creatorId;
      notification.data = data;
      MongoNotificationEngine.add(notification);

      // to team
      project.members.forEach(member => {
        var notification = new MongoNotification();
        notification.type = MongoNotification.TYPE_PROJECT_FUNDED;
        notification.toUserId = member.memberId;
        notification.data = data;
        MongoNotificationEngine.add(notification);
      });

      // to supporter
      var pledgerIds = await MongoProjectPledgeEngine.getPledgerIdsByProjectId(project.docId);
      pledgerIds.forEach(pledgerId => {
        // check if team member
        var index = project.members.findIndex(member => member.memberId == pledgerId);
        if (index >= 0) {
          return;
        }

        var notification = new MongoNotification();
        notification.type = MongoNotification.TYPE_PROJECT_FUNDED;
        notification.toUserId = pledgerId;
        notification.data = data;
        MongoNotificationEngine.add(notification);
      });
    } catch(error) {
      console.error("[MongoNotificationEngine] addProjectFunded() -> error: ", error.message);
    }
  }

  static addProjectPledged = async (project, mongoPledge) => {
    try {
      var data = MongoNotificationProjectPledgedEngine.generateByProjectAndPledge(project, mongoPledge);

      // to creator
      var notification = new MongoNotification();
      notification.type = MongoNotification.TYPE_PROJECT_PLEDGED;
      notification.toUserId = project.creatorId;
      notification.data = data;
      MongoNotificationEngine.add(notification);

      // to team
      project.members.forEach(member => {
        var notification = new MongoNotification();
        notification.type = MongoNotification.TYPE_PROJECT_PLEDGED;
        notification.toUserId = member.memberId;
        notification.data = data;
        MongoNotificationEngine.add(notification);
      });
    } catch(error) {
      console.log("[MongoNotificationEngine] addProjectPledged() -> error: ", error);
    }
  }

  static addProjectInvitation(project, invitor, invitee) {
    try {
      var data = MongoNotificationProjectInvitationEngine.generateByProjectAndInvitor(project, invitor);
      var notification = new MongoNotification();
      notification.type = MongoNotification.TYPE_PROJECT_INVITE;
      notification.toUserId = invitee.docId;
      notification.data = data;
      MongoNotificationEngine.add(notification);
    } catch(error) {
      console.error("[MongoNotificationEngine] addProjectInvitation() -> error: ", error.message);
    }
  }

  static addProjectInvitationAccept(project, invitee, invitor) {
    try {
      var data = MongoNotificationProjectInvitationAcceptEngine.generateByProjectAndInviteeAndInvitor(project, invitee, invitor);
      var notifications = [];

      // creator
      var notification = new MongoNotification();
      notification.type = MongoNotification.TYPE_PROJECT_INVITE_ACCEPT;
      notification.toUserId = project.creatorId;
      notification.data = data;
      notifications.push(notification);

      // members
      project.members.forEach(member => {
        if (member.memberId == invitee.docId) {
          return;
        }
        if (member.type == MongoTeam.TYPE_INVITED) {
          return;
        }

        var notification = new MongoNotification();
        notification.type = MongoNotification.TYPE_PROJECT_INVITE_ACCEPT;
        notification.toUserId = member.memberId;
        notification.data = data;
        notifications.push(notification);
      });

      MongoNotificationEngine.addMany(notifications);
    } catch(error) {
      console.error("[MongoNotificationEngine] addProjectInvitationAccept() -> error: ", error.message);
    }
  }

  static addProjectInvitationReject(project, invitee, invitor) {
    try {
      var data = MongoNotificationProjectInvitationRejectEngine.generateByProjectAndInviteeAndInvitor(project, invitee, invitor);
      var notifications = [];

      // creator
      var notification = new MongoNotification();
      notification.type = MongoNotification.TYPE_PROJECT_INVITE_REJECT;
      notification.toUserId = project.creatorId;
      notification.data = data;
      notifications.push(notification);

      // invitor
      if (project.creatorId != invitor.docId) {
        notification = new MongoNotification();
        notification.type = MongoNotification.TYPE_PROJECT_INVITE_REJECT;
        notification.toUserId = invitor.docId
        notification.data = data;
        notifications.push(notification);
      }

      MongoNotificationEngine.addMany(notifications);
    } catch(error) {
      console.error("[MongoNotificationEngine] addProjectInvitationReject() -> error: ", error.message);
    }
  }

  static addProjectLaunched = async (project, launcher) => {
    try {
      var data = MongoNotificationProjectLaunchedEngine.generateByProjectAndLauncher(project, launcher);

      // to creator
      // if (project.creatorId != launcher.docId) {
        var notification = new MongoNotification();
        notification.type = MongoNotification.TYPE_PROJECT_LAUNCHED;
        notification.toUserId = project.creatorId;
        notification.data = data;
        MongoNotificationEngine.add(notification);
      // }

      project.members.forEach(member => {
        // if (member.memberId == launcher.docId) {
        //   return;
        // }

        var notification = new MongoNotification();
        if (member.type == MongoTeam.TYPE_INVITED) {
          notification.type = MongoNotification.TYPE_PROJECT_LAUNCHED_INVITEE;
        } else {
          notification.type = MongoNotification.TYPE_PROJECT_LAUNCHED;
        }
        notification.toUserId = member.memberId;
        notification.data = data;
        MongoNotificationEngine.add(notification);
      });
    } catch(error) {
      console.error("[MongoNotificationEngine] addProjectLaunched() -> error: ", error.message);
    }
  }

  static addShowcaseInvitation(showcase, invitor, invitee) {
    try {
      var data = MongoNotificationShowcaseInvitationEngine.generateByShowcaseAndInvitor(showcase, invitor);
      var notification = new MongoNotification();
      notification.type = MongoNotification.TYPE_SHOWCASE_INVITE;
      notification.toUserId = invitee.docId;
      notification.data = data;
      MongoNotificationEngine.add(notification);
    } catch(error) {
      console.error("[MongoNotificationEngine] addShowcaseInvitation() -> error: ", error.message);
    }
  }

  static addShowcaseInvitationAccept(showcase, invitee, invitor) {
    try {
      var data = MongoNotificationShowcaseInvitationAcceptEngine.generateByShowcaseAndInviteeAndInvitor(showcase, invitee, invitor);
      var notifications = [];

      // creator
      var notification = new MongoNotification();
      notification.type = MongoNotification.TYPE_SHOWCASE_INVITE_ACCEPT;
      notification.toUserId = showcase.creatorId;
      notification.data = data;
      notifications.push(notification);

      // mentors
      showcase.mentors.forEach(member => {
        if (member.memberId == invitee.docId) {
          return;
        }
        if (member.type == MongoTeam.TYPE_INVITED) {
          return;
        }

        var notification = new MongoNotification();
        notification.type = MongoNotification.TYPE_SHOWCASE_INVITE_ACCEPT;
        notification.toUserId = member.memberId;
        notification.data = data;
        notifications.push(notification);
      });

      MongoNotificationEngine.addMany(notifications);
    } catch(error) {
      console.error("[MongoNotificationEngine] addShowcaseInvitationAccept() -> error: ", error.message);
    }
  }

  static addShowcaseInvitationReject(showcase, invitee, invitor) {
    try {
      var data = MongoNotificationShowcaseInvitationRejectEngine.generateByShowcaseAndInviteeAndInvitor(showcase, invitee, invitor);
      var notifications = [];

      // creator
      var notification = new MongoNotification();
      notification.type = MongoNotification.TYPE_SHOWCASE_INVITE_REJECT;
      notification.toUserId = showcase.creatorId;
      notification.data = data;
      notifications.push(notification);

      // invitor
      if (showcase.creatorId != invitor.docId) {
        notification = new MongoNotification();
        notification.type = MongoNotification.TYPE_SHOWCASE_INVITE_REJECT;
        notification.toUserId = invitor.docId
        notification.data = data;
        notifications.push(notification);
      }

      MongoNotificationEngine.addMany(notifications);
    } catch(error) {
      console.error("[MongoNotificationEngine] addShowcaseInvitationReject() -> error: ", error.message);
    }
  }

  // status accepted | denied
  static addShowcaseReview(showcase, reviewer, status = 'accepted') {
    try {
      var data = MongoNotificationShowcaseReviewEngine.generateByShowcaseAndReviewer(showcase, reviewer, status);
      var notification = new MongoNotification();
      notification.type = MongoNotification.TYPE_CURATED_PROJECT_REVIEW;
      notification.toUserId = showcase.creatorId;
      notification.data = data;
      MongoNotificationEngine.add(notification);
    } catch(error) {
      console.error("[MongoNotificationEngine] addShowcaseReview() -> error: ", error.message);
    }
  }


  static async deleteByDocId(notificationId) {
    try {
       var db = await MongoEngine.getDB();
      var collection = db.collection("Notification");
      var filter = {
        docId: notificationId,
      };
      var result = await collection.deleteOne(filter);
    } catch(error) {
      console.log("[MongoNotificationEngine] deleteByDocId() -> error: ", error);
    }
  }

  static async deleteProjectInvitation(projectId, inviteeId) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("Notification");
      var filter = {
        type: MongoNotification.TYPE_PROJECT_INVITE,
        toUserId: inviteeId,
        "data.projectId": projectId,
      };
      var result = await collection.deleteMany(filter);
    } catch(error) {
      console.log("[MongoNotificationEngine] deleteProjectInvitation() -> error: ", error);
    }
  }

  static async deleteProjectLaunched(projectId) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("Notification");
      var filter = null;
      if (projectId) {
        filter = {
          type: MongoNotification.TYPE_PROJECT_LAUNCHED,
          "data.projectId": projectId,
        };
      } else {
        filter = {
          type: MongoNotification.TYPE_PROJECT_LAUNCHED,
        };
      }

      var result = await collection.deleteMany(filter);
    } catch(error) {
      console.error("[MongoNotificationEngine] deleteProjectLaunched() -> error: ", error);
    }
  }

  static async deleteShowcaseInvitation(showcaseId, inviteeId) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("Notification");
      var filter = {
        type: MongoNotification.TYPE_SHOWCASE_INVITE,
        toUserId: inviteeId,
        "data.showcaseId": showcaseId,
      };

      var result = await collection.deleteMany(filter);
    } catch(error) {
      console.log("[MongoNotificationEngine] deleteShowcaseInvitation() -> error: ", error);
    }
  }

  static fromJson = (json) => {
    var notification = new MongoNotification();
    try {
      if (json._id != undefined) {
        notification._id = new ObjectId(json._id);
      }

      if (json.docId != undefined && json.docId != null && json.docId != "") {
        notification.docId = json.docId;
      } else {
        notification.docId = notification._id.valueOf().toString();
      }

      if (json.type != undefined) {
        notification.type = json.type;
      }
      if (json.toUserId != undefined) {
        notification.toUserId = json.toUserId;
      }
      if (json.data != undefined) {
        notification.data = json.data;
      }

      if (json.createdAt != undefined) {
        notification.createdAt = json.createdAt;
      }
      if (json.readAt != undefined) {
        notification.readAt = json.readAt;
      }
    } catch(error) {
      console.log("[MongoNotificationEngine] fromJson() -> error: ", error);
    }

    return notification;
  }

  static getByDocId = async (docId) => {
    var notification = null;
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("Notification");
      var filter = {
        docId: docId,
      };

      var mongoNotifications = await collection.find(filter);
      if (mongoNotifications.length == 0) {
        return null;
      }

      notification = MongoNotificationEngine.fromJson(mongoNotifications[0]);
    } catch(error) {
      console.log("[MongoNotificationEngine] getByDocId() -> error: ", error);
    }

    return notification;
  }

  static async getByToUserId(toUserId) {
    //console.log("[MongoNotificationEngine] getByToUserId()");
    //console.log("\ttoUserId: ", toUserId);

    var notifications = [];
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("Notification");
      var filter = {
        toUserId: toUserId,
        type: {
          $in: [
            MongoNotification.TYPE_PROJECT_FUNDED,
            MongoNotification.TYPE_PROJECT_LAUNCHED,
            MongoNotification.TYPE_PROJECT_LAUNCHED_INVITEE,
            MongoNotification.TYPE_PROJECT_INVITE,
            MongoNotification.TYPE_PROJECT_INVITE_ACCEPT,
            MongoNotification.TYPE_PROJECT_PLEDGED,
            MongoNotification.TYPE_SHOWCASE_INVITE,
            MongoNotification.TYPE_SHOWCASE_INVITE_ACCEPT,
          ]
        },
      };

      var sort = {
        createdAt: -1,
      };

      var mongoNotifications = await collection.find(filter, {
        sort
      });

      mongoNotifications.forEach(mongoNotification => {
        var notification = MongoNotificationEngine.fromJson(mongoNotification);
        notifications.push(notification);
      });

      notifications.sort((a, b) => {
        var readAtA = a.readAt == null ? Infinity : a.readAt.getTime();
        var readAtB = b.readAt == null ? Infinity : b.readAt.getTime();
        if (readAtA == readAtB) {
          return b.createdAt - a.createdAt;
        }

        return readAtB - readAtA;
      });
    } catch(error) {
      console.log("[MongoNotificationEngine] getByToUserId() -> error: ", error);
    }

    return notifications;
  }

  static async getCountByToUserId(toUserId) {
    var count = 0;
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("Notification");
      var filter = {
        toUserId: toUserId,
      };
      count = await collection.count(filter);
    } catch(error) {
      console.log("[MongoNotificationEngine] getCountByToUserId() -> error: ", error);
    }

    return count;
  }

  static async getUnreadByToUserId(toUserId) {
    var notifications = [];
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("Notification");
      var filter = {
        toUserId: toUserId,
        type: {
          $in: [
            MongoNotification.TYPE_PROJECT_FUNDED,
            MongoNotification.TYPE_PROJECT_LAUNCHED,
            MongoNotification.TYPE_PROJECT_LAUNCHED_INVITEE,
            MongoNotification.TYPE_PROJECT_INVITE,
            MongoNotification.TYPE_PROJECT_INVITE_ACCEPT,
            MongoNotification.TYPE_PROJECT_PLEDGED,
            MongoNotification.TYPE_SHOWCASE_INVITE,
            MongoNotification.TYPE_SHOWCASE_INVITE_ACCEPT,
          ]
        },
        readAt: null,
      };

      var sort = {
        createdAt: -1,
      };

      var mongoNotifications = await collection.find(filter, {
        sort
      });

      mongoNotifications.forEach(mongoNotification => {
        var notification = MongoNotificationEngine.fromJson(mongoNotification);
        notifications.push(notification);
      });

      notifications.sort((a, b) => {
        return b.createdAt - a.createdAt;
      });
    } catch(error) {
      console.log("[MongoNotificationEngine] getByToUserId() -> error: ", error);
    }

    return notifications;
  }

  static getUnreadCountByToUserId = async (toUserId) => {
    var count = 0;
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("Notification");
      var filter = {
        toUserId: toUserId,
        type: {
          $in: [
            MongoNotification.TYPE_PROJECT_FUNDED,
            MongoNotification.TYPE_PROJECT_LAUNCHED,
            MongoNotification.TYPE_PROJECT_LAUNCHED_INVITEE,
            MongoNotification.TYPE_PROJECT_INVITE,
            MongoNotification.TYPE_PROJECT_INVITE_ACCEPT,
            MongoNotification.TYPE_PROJECT_PLEDGED,
            MongoNotification.TYPE_SHOWCASE_INVITE,
            MongoNotification.TYPE_SHOWCASE_INVITE_ACCEPT,
          ]
        },
        readAt: null,
      };
      count = await collection.count(filter);
    } catch(error) {
      console.error("[MongoNotificationEngine] getUnreadCountByToUserId() -> error: ", error);
    }

    return count;
  }

  static async read(notification, readerId) {
    console.log("[MongoNotificationEngine] read()");

    if(notification.toUserId != readerId) {
      return;
    }

    try {
      notification.readAt = new Date();
      await MongoNotificationEngine.update(notification);
    } catch(error) {
      console.error("[MongoNotificationEngine] read() -> error: " + error.message);
    }
  }

  static registerChangeListener = (callback) => {
    try {
      var mongoCallback = new MongoCallback();
      mongoCallback.callback = callback;
      this.changeListeners.push(mongoCallback);

      return mongoCallback.id;

    } catch(error) {
      console.error("[MongoNotificationEngine] registerChangeListener() -> error: ", error.message);
    }

    return "";
  }

  static unregisterChangeListenerByListenerId = (callbackId) => {
    try {
      var index = this.changeListeners.find(callback => callback.id == callbackId);
      if (index < 0) {
        return;
      }
      this.changeListeners.splice(index, 1);

    } catch(error) {
      console.error("[MongoNotificationEngine] registerChangeListener() -> error: ", error.message);
    }
  }
    
  static async update(notification) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("Notification");
      var result = await collection.updateOne(
        { _id: notification._id },
        { $set: notification },
        { upsert: true },
      );
    } catch(error) {
      console.error("[MongoNotificationEngine] update() -> error: ", error.message);
    }
  }

  static watchForChanges = async (userId) => {
    console.log("[MongoNotificationEngine] watchForChanges()");

    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("Notification");

      //collection.addListener(MongoNotificationEngine.onChanged);

      var changeStream = collection.watch();
      for await (var change of changeStream) {
        console.log("\tchange: ", change);

        this.changeListeners.forEach(listener => {
          try {
            listener.callback();
          } catch(error) {
            console.error("[MongoNotificationEngine] watchForChanges() -> changeStream -> error: ", error.message);
          }
        });
      }
      
    } catch(error) {
      console.error("[MongoNotificationEngine] watchForChanges() -> error: ", error);

      //MongoNotificationEngine.watchForChanges();
    }
  }

  static onChanged = (collection, changes) => {
    try {
      this.changeListeners.forEach(listener => {
        try {
          listener.callback();
        } catch(error) {
          console.error("[MongoNotificationEngine] onChanged() -> listener -> error: ", error.message);
        }
      });

    } catch(error) {
      console.error("[MongoNotificationEngine] onChanged() -> error: ", error.message);
    }
  }
}
