import { ObjectId } from 'bson';

import { HEART_VALUE } from '../constants/globalconstants';

import MongoEngine from '.';
import MongoMediaEngine from './medias';
import MongoProjectUpdateEngine from './projectupdates';
import MongoProjectUpdatePledge from './projectupdatepledge';
import MongoUserEngine from './users';
import MongoProjectPledgeEngine from './projectpledges';

export default class MongoProjectUpdatePledgeEngine {
  static async add(pledge) {
    try {
      var db = await MongoEngine.getDB();
      var result = await db.collection("ProjectUpdatePledge").insertOne(pledge);
      pledge._id = result.insertedId;
    } catch(error) {
      console.log("[MongoProjectUpdatePledgeEngine] add() -> error: ", error);
    }
  }

  static async deleteAll() {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdatePledge");
      var result = await collection.deleteMany();
    } catch(error) {
      console.log("[MongoProjectUpdatePledgeEngine] deleteAll() -> error: ", error);
    }
  }

  static fromJson = (json) => {
    var pledge = new MongoProjectUpdatePledge();
    try {
      if (json._id != undefined) {
        pledge._id = new ObjectId(json._id);
      }
      if (json.docId != undefined && json.docId != null && json.docId != "") {
        pledge.docId = json.docId;
      } else {
        pledge.docId = pledge._id.valueOf();
      }

      if (json.updateId != undefined) {
        pledge.updateId = json.updateId;
      }
      if (json.updateComment != undefined) {
        pledge.updateComment = json.updateComment;
        if (typeof(pledge.updateComment) == "object") {
          pledge.updateComment = JSON.stringify(pledge.updateComment);
        }
      }
      if (json.updateMedia != undefined) {
        try {
          pledge.updateMedia = MongoMediaEngine.fromJson(json.updateMedia);
        } catch(error) {
          console.log("[MongoProjectUpdatePledgeEngine] fromJson() -> error: ", error);
        }
      }

      if (json.creatorId != undefined) {
        pledge.creatorId = json.creatorId;
      }
      if (json.creatorName != undefined) {
        pledge.creatorName = json.creatorName;
      }
      if (json.creatorLogoUrl != undefined) {
        pledge.creatorLogoUrl = json.creatorLogoUrl;
      }

      if (json.pledgerId != undefined) {
        pledge.pledgerId = json.pledgerId;
      }
      if (json.pledgerName != undefined) {
        pledge.pledgerName = json.pledgerName;
      }
      if (json.pledgerLogoUrl != undefined) {
        pledge.pledgerLogoUrl = json.pledgerLogoUrl;
      }

      if (json.type != undefined) {
        pledge.type = json.type;
      }
      if (json.amount != undefined) {
        pledge.amount = json.amount;
      }
      if (json.currencyCode != undefined) {
        pledge.currencyCode = json.currencyCode;
      }

      if (json.firedBy != undefined) {
        pledge.firedBy = json.firedBy;
      }

      if (json.timestamp != undefined) {
        pledge.timestamp = json.timestamp;
      }
    } catch(error) {
      console.log("[MongoProjectUpdatePledgeEngine] fromJson() -> error: ", error);
    }

    return pledge;
  }

  static async getAmountByUserId(userId) {
    var amount = 0;
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdatePledge");
      var aggregate = [
        { $match: { pledgerId: userId } },
        { $project: { amount: 1 } },
        {
          $group: {
            _id: null,
            totalAmount: { $sum: "$amount" },
          },
        }
      ];

      var ret = await collection.aggregate(aggregate);
      if (ret.length == 0) {
        return 0;
      }

      amount = ret[0].totalAmount;
    } catch(error) {
      console.log("[MongoProjectUpdatePledgeEngine] getAmountByUserId() -> error: " + error.message);
    }

    return amount;
  }

  static getByDocId = async (docId) => {
    var pledge = null;
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdatePledge");
      var filter = {
        docId: docId,
      };

      var pledges = await collection.find(filter);
      if (pledges.length == 0) {
        return null;
      }

      pledge = MongoProjectUpdatePledgeEngine.fromJson(pledges[0]);
    } catch(error) {
      console.log("[MongoProjectUpdatePledgeEngine] getByDocId() -> error: ", error);
    }

    return pledge;
  }

  static getByUserId = async (userId) => {
    var pledges = [];
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdatePledge");
      var filter = {
        pledgerId: userId,
      };

      var sort = {
        timestamp: -1,
      };

      var mongoPledges = await collection.find(filter, {
        sort
      });
      mongoPledges.forEach(mongoPledge => {
        var pledge = MongoProjectUpdatePledgeEngine.fromJson(mongoPledge);
        pledges.push(pledge);
      });
    } catch(error) {
      console.log("[MongoProjectUpdatePledgeEngine] getByUserId() -> error: ", error);
    }

    return pledges;
  }

  static getCountByUpdateIdAndUserId = async (updateId, userId) => {
    var count = 0;
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdatePledge");
      var filter = {
        updateId: updateId,
        pledgerId: userId,
      };

      count = await collection.count(filter);
    } catch(error) {
      console.log("[MongoProjectUpdatePledgeEngine] getCountByUpdateIdAndUserId() -> error: ", error);
    }

    return count;
  }

  static getCountByUserId = async (userId) => {
    var count = 0;
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdatePledge");
      var filter = {
        pledgerId: userId,
      };

      count = await collection.count(filter);
    } catch(error) {
      console.log("[MongoProjectUpdatePledgeEngine] getCountByUserId() -> error: ", error);
    }

    return count;
  }

  static getPledgerIdsByUpdateId = async (updateId) => {
    var pledgerIds = [];
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdatePledge");
      var filter = {
        updateId: updateId,
      };

      var pledges = await collection.find(filter);
      pledges.forEach(pledge => {
        var pledgerId = pledge.pledgerId;
        if (pledgerId == undefined || pledgerId == null) {
          return;
        }

        var index = pledgerIds.findIndex(id => id == pledgerId);
        if (index >= 0) {
          return;
        }

        pledgerIds.push(pledgerId);
      });
    } catch(error) {
      console.log("[MongoProjectUpdatePledgeEngine] getPledgerIdsByUpdateId() -> error: ", error);
    }

    return pledgerIds;
  }

  static async pledge(update, pledger, type, amount, bMonthly, monthlyAmount) {
    try {
      // record pledging
      var mongoPledge = new MongoProjectUpdatePledge();
      mongoPledge.updateId = update.docId;
      mongoPledge.updateComment = update.comment;
      mongoPledge.updateMedia = MongoProjectUpdateEngine.getLogoImage(update);
      mongoPledge.creatorId = update.creatorId;
      mongoPledge.creatorName = update.creatorName;
      mongoPledge.creatorLogoUrl = update.creatorLogoUrl;
      mongoPledge.pledgerId = pledger.docId;
      mongoPledge.pledgerName = MongoUserEngine.getFriendlyUserName(pledger);
      mongoPledge.pledgerLogoUrl = pledger.logoUrl;
      mongoPledge.currencyCode = pledger.currencyCode;
      mongoPledge.type = type;
      if (bMonthly) {
        mongoPledge.firedBy = MongoProjectUpdatePledge.FIREDBY_AUTO;
        mongoPledge.percentAmount = monthlyAmount;
      }

      var amountInCents = amount;
      if (type == MongoProjectUpdatePledge.TYPE_HEARTS) {
        amountInCents = amount * HEART_VALUE;
      }
      mongoPledge.amount = amountInCents;

      await MongoProjectUpdatePledgeEngine.add(mongoPledge);

      // increase support count and amount of pledger
      pledger.supportCount = await MongoProjectPledgeEngine.getCountByUserId(pledger.docId);
      pledger.supportSpent = await MongoProjectPledgeEngine.getAmountByUserId(pledger.docId);

      pledger.supportCount += await MongoProjectUpdatePledgeEngine.getCountByUserId(pledger.docId);
      pledger.supportSpent += await MongoProjectUpdatePledgeEngine.getAmountByUserId(pledger.docId);

      await MongoUserEngine.update(pledger);

      // fund project
      await MongoProjectUpdateEngine.fund(update, mongoPledge);

      return true;
    } catch(error) {
      console.log("[MongoProjectUpdatePledgeEngine] pledge() -> error: ", error);
    }

    return false;
  }

  static async update(pledge) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdatePledge");
      var result = await collection.updateOne(
        { _id: pledge._id },
        { $set: pledge },
        { upsert: true },
      );
    } catch(error) {
      console.log("[MongoProjectUpdatePledgeEngine] update() -> error: ", error);
    }
  }
}
