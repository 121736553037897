import MongoEngine from ".";
import MongoProjectPin from "./projectpin";
import MongoUserEngine from "./users";

export default class MongoProjectPinEngine {
  static async add(pin) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectPin");
      var result = await collection.insertOne(pin);
      pin._id = result.insertedId;
    } catch(error) {
      console.log("[MongoProjectPinEngine] add() -> error: ", error);
    }
  }

  static async deleteByProjectId(projectId) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectPin");
      var filter = {
        projectId: projectId,
      };
      var result = await collection.deleteMany(filter);
    } catch(error) {
      console.log("[MongoProjectPinEngine] deleteByProjectId() -> error: ", error);
    }
  }

  static async deleteAll() {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectPin");
      var result = await collection.deleteMany();
    } catch(error) {
      console.log("[MongoProjectPinEngine] deleteAll() -> error: ", error);
    }
  }

  static fromJson(json) {
    var pin = new MongoProjectPin();
    try {
      if (json._id != undefined) {
        pin._id = json._id;
      }
      if (json.projectId != undefined) {
        pin.projectId = json.projectId;
      }
      if (json.pinnerIds != undefined) {
        pin.pinnerIds = json.pinnerIds;
      }
    } catch(error) {
      console.log("[MongoProjectPinEngine] fromJson() -> error: ", error);
    }

    return pin;
  }

  static getByProjectId = async (projectId) => {
    var pin = null;
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectPin");
      var filter = {
        projectId: projectId,
      };

      var mongoProjectPins = await collection.find(filter);
      if (mongoProjectPins.length == 0) {
        return null;
      }

      pin = MongoProjectPinEngine.fromJson(mongoProjectPins[0]);
    } catch(error) {
      console.log("[MongoProjectPinEngine] getByProjectId() -> error: ", error);
    }

    return pin;
  }

  static getByUserId = async (userId) => {
    var pins = [];
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectPin");
      var filter = {
        pinnerIds: userId,
      };

      var mongoPins = await collection.find(filter);
      mongoPins.forEach(mongoPin => {
        const pin = MongoProjectPinEngine.fromJson(mongoPin);
        pins.push(pin);
      });
    } catch(error) {
      console.log("[MongoProjectPinEngine] getByUserId() -> error: ", error);
    }

    return pins;
  }

  static getProjectIdsByUserId = async (userId) => {
    var pins = [];
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectPin");
      var filter = {
        pinnerIds: userId,
      };

      var mongoPins = await collection.find(filter);
      mongoPins.forEach(mongoPin => {
        pins.push(mongoPin.projectId);
      });
    } catch(error) {
      console.log("[MongoProjectPinEngine] getProjectIdsByUserId() -> error: ", error);
    }

    return pins;
  }

  static async isPinned(projectId, userId) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectPin");
      var filter = {
        projectId: projectId,
        pinnerIds: userId,
      };

      var count = await collection.count(filter);
      if (count > 0) {
        return true;
      }
    } catch(error) {
      console.log("[MongoProjectPinEngine] isPinned() -> error: " + error);
    }

    return false;
  }

  static async pin(projectId, userId) {
    try {
      var mongoPin = await MongoProjectPinEngine.getByProjectId(projectId);
      if (mongoPin == null) {
        mongoPin = new MongoProjectPin();
        mongoPin.projectId = projectId;
      }

      var pinnerIds = mongoPin.pinnerIds;
      var index = pinnerIds.find(pinnerId => pinnerId == userId);
      if (index >= 0) {
        return false;
      }

      pinnerIds.push(userId);
      mongoPin.pinnerIds = pinnerIds;

      await MongoProjectPinEngine.update(mongoPin);

      return true;
    } catch(error) {
      console.log("[MongoProjectPinEngine] pin() -> error: " + error.message);
    }

    return false;
  }

  static async unpin(projectId, userId) {
    try {
      var pin = await MongoProjectPinEngine.getByProjectId(projectId);
      if (pin == null) {
        return false;
      }

      var pinnerIds = pin.pinnerIds;
      var index = pinnerIds.find(pinnerId => pinnerId == userId);
      if (index < 0) {
        return false;
      }

      pinnerIds.splice(index, 1);
      pin.pinnerIds = pinnerIds;

      await MongoProjectPinEngine.update(pin);

      return true;
    } catch(error) {
      console.log("[MongoProjectPinEngine] unpin() -> error: ", error);
    }
    
    return false;
  }

  static async update(pin) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectPin");
      var result = await collection.updateOne(
        { _id: pin._id },
        { $set: pin },
        { upsert: true },
      );
    } catch(error) {
      console.log("[MongoProjectPinEngine] update() -> error: ", error);
    }
  }
}
