import { ObjectId } from 'bson';

export default class MongoProjectUpdateComment {
  static TYPE_COMMENT = "comment";
  static TYPE_REPLY = "reply";

  _id = null;
  docId = "";

  type = MongoProjectUpdateComment.TYPE_COMMENT;

  updateId = "";
  commentId = "";
  
  creatorId = "";
  creatorName = "";
  creatorLogoUrl = "";

  text = "";
  heartCount = 0;

  likeCount = 0;
  replyCount = 0;

  timestamp = null;

  constructor() {
    this._id = new ObjectId();
    this.docId = this._id.valueOf().toString();
    this.timestamp = new Date();
  }
}
