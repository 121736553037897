import MongoNotificationShowcaseInvitation from "./notificationshowcaseinvitation";
import MongoUserEngine from "./users";

export default class MongoNotificationShowcaseInvitationEngine {
  static fromJson(json) {
    var notification = new MongoNotificationShowcaseInvitation();
    try {
      if (json.showcaseId != undefined) {
        notification.showcaseId = json.showcaseId;
      }
      if (json.showcaseTitle != undefined) {
        notification.showcaseTitle = json.showcaseTitle;
      }
      if (json.invitorId != undefined) {
        notification.invitorId = json.invitorId;
      }
      if (json.invitorName != undefined) {
        notification.invitorName = json.invitorName;
      }
      if (json.invitorLogoUrl != undefined) {
        notification.invitorLogoUrl = json.invitorLogoUrl;
      }
    } catch(error) {
      console.log("[MongoNotificationShowcaseInvitationEngine] fromJson() -> error: ", error);
    }

    return notification;
  }

  static generateByShowcaseAndInvitor(showcase, invitor) {
    var invitation = new MongoNotificationShowcaseInvitation();
    invitation.showcaseId = showcase.docId;
    invitation.showcaseTitle = showcase.title;
    invitation.invitorId = invitor.docId;
    invitation.invitorName = MongoUserEngine.getFriendlyUserName(invitor);
    invitation.invitorLogoUrl = invitor.logoUrl;
    return invitation;
  }
}
