import MongoPaypal from "./paypal";

export default class MongoPaypalEngine {
  static fromJson(json) {
    var paypal = new MongoPaypal();
    try {
      if (json.userId != undefined) {
        paypal.userId = json.userId;
      }
      if (json.email != undefined) {
        paypal.email = json.email;
      }
      if (json.default != undefined) {
        paypal.default = json.default;
      }
    } catch(error) {
      console.log("[MongoPaypalEngine] fromJson() -> error: ", error);
    }

    return paypal;
  }
}
