import { ObjectId } from 'bson';

export default class MongoUserInfo {
  static MENTOR_AVAILABLE = "Available";
  static MENTOR_LATER = "Maybe later";

  static PLEDGE_SHOW = "Show pledges";
  static PLEDGE_HIDE = "Hide pledges";

  _id = null;

  userDocId = "";
  userEnactonId = "";

  birthday = null;

  url = "";

  countryCode = "";

  phoneNumber = null;             // MongoPhone

  businessName = "";
  companyUrl = "";
  businessEmail = "";

  location = null;                // MongoLocation

  latitude = 0;
  longitude = 0;

  categories = [];                // string

  billing = null;                // MonboBilling

  mentor = MongoUserInfo.MENTOR_LATER;
  showPledge = MongoUserInfo.PLEDGE_SHOW;

  defaultScreen = "";

  supportAsPrivate = true;

  hasSeenAdOnboarding = false;

  timestamp = null;

  constructor() {
    this._id = new ObjectId();
    this.docId = this._id.valueOf().toString();
    this.timestamp = new Date();
  }
}
