export default class MongoPhoneEngine {
  static getFullNumberAsString = (phone) => {
    if (phone == undefined || phone == null) {
      return "";
    }

    var fullNumber = "";
    try {
      fullNumber =  "+" + phone.callingCode + phone.number;
    } catch(error) {
      console.error("[MongoPhoneEngine] getFullNumberAsString() -> error: ", error.message);
    }

    return fullNumber;
  }
}
