import MongoBankCardEngine from "./bankcards";
import MongoBilling from "./billing";
import MongoPaypalEngine from "./paypals";
import MongoStripeBankEngine from "./stripebanks";

export default class MongoBillingEngine {
  static deleteBankCard = (billing, bankCard) => {
    try {
      if (billing.bankCards.length == 0) {
        return false;
      }

      var index = billing.bankCards.findIndex(card => card.paymentMethodId == bankCard.paymentMethodId);
      if (index < 0) {
        return false;
      }

      billing.bankCards.splice(index, 1);
      return true
    } catch(error) {
      console.error("[MongoBillingEngine] deleteBankCard() -> error: ", error.message);
    }

    return false;
  }

  static deletePaypal = (billing, paypal) => {
    try {
      if (billing.paypals.length == 0) {
        return false;
      }

      var index = billing.paypals.findIndex(p => p.userId == paypal.userId);
      if (index < 0) {
        return false;
      }

      billing.paypals.splice(index, 1);
      return true
    } catch(error) {
      console.error("[MongoBillingEngine] deletePaypal() -> error: ", error.message);
    }

    return false;
  }

  static fromJson(json) {
    var billing = new MongoBilling();
    try {
      if (json.countryCode != undefined) {
        billing.countryCode = json.countryCode;
      }

      if (json.stripeCustomerId != undefined) {
        billing.stripeCustomerId = json.stripeCustomerId;
      }

      if (json.bankCards != undefined && json.bankCards != null) {
        json.bankCards.forEach(jsonBankCard => {
          try {
            var bankCard = MongoBankCardEngine.fromJson(jsonBankCard);
            billing.bankCards.push(bankCard);
          } catch(error) {
            console.log("[MongoBillingEngine] fromJson() -> error: ", error);
          }
        });
      }

      if (json.paypals != undefined && json.paypals != null) {
        json.paypals.forEach(jsonPaypal => {
          try {
            var paypal = MongoPaypalEngine.fromJson(jsonPaypal);
            billing.paypals.push(paypal);
          } catch(error) {
            console.log("[MongoBillingEngine] fromJson() -> error: ", error);
          }
        });
      }

      if (json.stripeBanks != undefined && json.stripeBanks != null) {
        json.stripeBanks.forEach(jsonStripeBank => {
          try {
            var stripeBank = MongoStripeBankEngine.fromJson(jsonStripeBank);
            billing.stripeBanks.push(stripeBank);
          } catch(error) {
            console.log("[MongoBillingEngine] fromJson() -> error: ", error);
          }
        });
      }

      if (json.monthlyBudget != undefined) {
        billing.monthlyBudget = json.monthlyBudget;
      }
      if (json.excludeCashback != undefined) {
        billing.excludeCashback = json.excludeCashback;
      }
      if (json.excludeWinkback != undefined) {
        billing.excludeWinkback = json.excludeWinkback;
      }
      if (json.reclaimCashback != undefined) {
        billing.reclaimCashback = json.reclaimCashback;
      }
      if (json.reclaimOrderNo != undefined) {
        billing.reclaimOrderNo = json.reclaimOrderNo;
      }
    } catch(error) {
      console.error("[MongoBillingEngine] fromJson() -> error: ", error);
    }

    return billing;
  }

  static getDefaultBankCard = (billing) => {
    try {
      if (billing.bankCards.length == 0) {
        return null;
      }

      var bankCard = billing.bankCards.find(card => card.default == true);
      if (bankCard != null) {
        return bankCard;
      }

      return billing.bankCards[0];
    } catch(error) {
      console.error("[MongoBillingEngine] getDefaultBankCard() -> error: ", error);
    }

    return null;
  }

  static getDefaultPaypal = (billing) => {
    try {
      if (billing.paypals.length == 0) {
        return null;
      }

      var paypal = billing.paypals.find(p => p.default == true);
      if (paypal != null) {
        return paypal;
      }

      return billing.paypals[0];
    } catch(error) {
      console.error("[MongoBillingEngine] getDefaultPaypal() -> error: ", error);
    }

    return null;
  }

  static hasPaymentMethods = (billing) => {
    try {
      if (billing == null) {
        return false;
      }
      if (billing.bankCards.length > 0) {
        return true;
      }
      if (billing.paypals.length > 0) {
        return true;
      }
    } catch(error) {
      console.error("[MongoBillingEngine] hasPaymentMethods() -> error: ", error);
    }

    return false;
  }

  static hasWithdrawMethods = (billing) => {
    try {
      if (billing == null) {
        return false;
      }
      if (billing.stripeBanks.length > 0) {
        return true;
      }
    } catch(error) {
      console.error("[MongoBillingEngine] hasWithdrawMethods() -> error: ", error);
    }

    return false;
  }

  static setDefaultBankCard = (billing, bankCard) => {
    try {
      billing.bankCards.forEach(card => {
        card.default = false;
      });
      bankCard.default = true;
    } catch(error) {
      console.error("[MongoBillingEngine] setDefaultBankCard() -> error: ", error.message);
    }
  }

  static setDefaultPaypal = (billing, paypal) => {
    try {
      billing.paypals.forEach(p => {
        p.default = false;
      });
      paypal.default = true;
    } catch(error) {
      console.error("[MongoBillingEngine] setDefaultPaypal() -> error: ", error.message);
    }
  }
}
