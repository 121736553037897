import { ObjectId } from 'bson';

import Formatter from "../formatter";

import MongoBillingEngine from "./billings";
import MongoEngine from ".";
import MongoLocationEngine from "./locations";
import MongoPhoneEngine from "./phones";
import MongoUserInfo from "./userinfo";

export default class MongoUserInfoEngine {
  static async add(user) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("UserInfo");
      var result = await collection.insertOne(user);
      user._id = result.insertedId;
    } catch(error) {
      console.log("[MongoUserInfoEngine] add() -> error: ", error);
    }
  }

  static async deleteAll() {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("UserInfo");
      var result = await collection.deleteMany();
    } catch(error) {
      console.log("[MongoUserInfoEngine] deleteAll() -> error: ", error);
    }
  }

  static deleteBankCard = async (userInfo, bankCard) => {
    try {
      if (!MongoBillingEngine.deleteBankCard(userInfo.billing, bankCard)) {
        return false;
      }

      await MongoUserInfoEngine.update(userInfo);
      return true;
    } catch(error) {
      console.error("[MongoUserInfoEngine] deleteBankCard() -> error: ", error.message);
    }

    return false;
  }

  static deletePaypal = async (userInfo, paypal) => {
    try {
      if (!MongoBillingEngine.deletePaypal(userInfo.billing, paypal)) {
        return false;
      }

      await MongoUserInfoEngine.update(userInfo);
      return true;
    } catch(error) {
      console.error("[MongoUserInfoEngine] deletePaypal() -> error: ", error.message);
    }

    return false;
  }

  static fromJson(json) {
    var user = new MongoUserInfo();
    try {
      if (json._id != undefined) {
        user._id = new ObjectId(json._id);
      }
      if (json.userDocId != undefined && json.userDocId != null && json.userDocId != "") {
        user.userDocId = json.userDocId;
      } else {
        user.userDocId = user._id.valueOf().toString();
      }

      if (json.userEnactonId != undefined) {
        user.userEnactonId = json.userEnactonId;
      }

      if (json.birthday != undefined) {
        user.birthday = json.birthday;
      }

      if (json.url != undefined) {
        user.url = json.url;
      }

      if (json.countryCode != undefined) {
        user.countryCode = json.countryCode;
      }

      if (json.phoneNumber != undefined) {
        user.phoneNumber = json.phoneNumber;
      }

      if (json.businessName != undefined) {
        user.businessName = json.businessName;
      }
      if (json.companyUrl != undefined) {
        user.companyUrl = json.companyUrl;
      }
      if (json.businessEmail != undefined) {
        user.businessEmail = json.businessEmail;
      }

      if (json.location != undefined) {
        user.location = MongoLocationEngine.fromJson(json.location);
      }

      if (json.categories != undefined) {
        user.categories = json.categories;
      }

      if (json.billing != undefined) {
        user.billing = MongoBillingEngine.fromJson(json.billing);
      }

      if (json.mentor != undefined) {
        user.mentor = json.mentor;
      }
      if (json.showPledge != undefined) {
        user.showPledge = json.showPledge;
      }

      if (json.defaultScreen != undefined) {
        user.defaultScreen = json.defaultScreen;
      }

      if (json.supportAsPrivate != undefined) {
        user.supportAsPrivate = json.supportAsPrivate;
      }

      if (json.hasSeenAdOnboarding != undefined) {
        user.hasSeenAdOnboarding = json.hasSeenAdOnboarding;
      }

      if (json.timestamp != undefined) {
        user.timestamp = new Date(json.timestamp);
      }

      if (json.latitude != undefined) {
        user.latitude = json.latitude;
      }
      
      if (json.longitude != undefined) {
        user.longitude = json.longitude;
      }

    } catch(error) {
      console.log("[MongoUserInfoEngine] fromJson() -> error: ", error);
    }

    return user;
  }

  static fromUser(user) {
    var userInfo = new MongoUserInfo();
    userInfo.userDocId = user.docId;
    userInfo.userEnactonId = user.enactonId;
    userInfo.businessEmail = user.email;
    return userInfo;
  }

  static getBirthdayAsString = (userInfo) => {
    if (userInfo == null) {
      return "";
    }

    try {
      return Formatter.timestampToDateString(userInfo.birthday);
    } catch(error) {
      console.error("[MongoUserInfoEngine] getBirthdayAsString() -> error: ", error.message);
    }

    return "";
  }

  static async getByUserDocId(userDocId) {
    var user = null;
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("UserInfo");
      var filter = {
        userDocId: userDocId,
      };

      var mongoUsers = await collection.find(filter);
      if (mongoUsers.length == 0) {
        return null;
      }

      user = MongoUserInfoEngine.fromJson(mongoUsers[0]);
    } catch(error) {
      console.log("[MongoUserInfoEngine] getByUserDocId() -> error: ", error);
    }

    return user;
  }

  static getDefaultBankCard = (userInfo) => {
    try {
      var billing = userInfo.billing;
      return MongoBillingEngine.getDefaultBankCard(billing);
    } catch(error) {
      console.error("[MongoUserInfoEngine] getDefaultBankCard() -> error: ", error);
    }

    return null;
  }

  static getDefaultPaypal = (userInfo) => {
    try {
      var billing = userInfo.billing;
      return MongoBillingEngine.getDefaultPaypal(billing);
    } catch(error) {
      console.error("[MongoUserInfoEngine] getDefaultPaypal() -> error: ", error);
    }

    return null;
  }

  static getPhoneNumberAsFullString = (userInfo) => {
    if (userInfo.phoneNumber == null) {
      return "";
    }

    return MongoPhoneEngine.getFullNumberAsString(userInfo.phoneNumber);
  }

  static getStripeCustomerId = (userInfo) => {
    var stripeCustomerId = "";
    try {
      var billing = userInfo.billing;
      if (billing != null) {
        stripeCustomerId = billing.stripeCustomerId;
      }
    } catch(error) {
      console.error("[MongoUserInfoEngine] getStripeCustomerId() -> error: ", error.message);
    }

    return stripeCustomerId;
  }

  static setDefaultBankCard = async (userInfo, bankCard) => {
    try {
      MongoBillingEngine.setDefaultBankCard(userInfo.billing, bankCard);
      await MongoUserInfoEngine.update(userInfo);
    } catch(error) {
      console.error("[MongoUserInfoEngine] setDefaultCard() -> error: ", error.message);
    }
  }

  static setDefaultPaypal = async (userInfo, paypal) => {
    try {
      MongoBillingEngine.setDefaultPaypal(userInfo.billing, paypal);
      await MongoUserInfoEngine.update(userInfo);
    } catch(error) {
      console.error("[MongoUserInfoEngine] setDefaultPaypal() -> error: ", error.message);
    }
  }

  static async update(userInfo) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("UserInfo");
      var result = await collection.updateOne(
        { _id: userInfo._id },
        { $set: userInfo },
        { upsert: true },
      );
    } catch(error) {
      console.log("[MongoUserInfoEngine] update() -> error: ", error);
    }
  }
}
