import MongoNotificationShowcaseReview from "./notificationshowcasereview";
import MongoUserEngine from "./users";

export default class MongoNotificationShowcaseReviewEngine {
  static fromJson(json) {
    var notification = new MongoNotificationShowcaseReview();
    try {
      if (json.projectId != undefined) {
        notification.projectId = json.projectId;
      }
      if (json.projectTitle != undefined) {
        notification.projectTitle = json.projectTitle;
      }

      if (json.creatorId != undefined) {
        notification.creatorId = json.creatorId;
      }

      if (json.creatorName != undefined) {
        notification.creatorName = json.creatorName;
      }

      if (json.creatorLogoUrl != undefined) {
        notification.creatorLogoUrl = json.creatorLogoUrl;
      }

      if (json.reviewerId != undefined) {
        notification.reviewerId = json.reviewerId;
      }

      if (json.reviewerName != undefined) {
        notification.reviewerName = json.reviewerName;
      }

      if (json.status != undefined) {
        notification.status = json.status;
      }

    } catch(error) {
      console.log("[MongoNotificationShowcaseReviewEngine] fromJson() -> error: ", error);
    }

    return notification;
  }

  static generateByShowcaseAndReviewer(showcase, reviewer, status) {
    var review = new MongoNotificationShowcaseReview();
    review.projectId = showcase.docId;
    review.projectTitle = showcase.title;
    review.creatorId = showcase.creatorId;
    review.creatorName = showcase.creatorName;
    review.creatorLogoUrl = showcase.creatorLogoUrl;

    if (reviewer != null) {
      review.reviewerId = reviewer.docId;
      review.reviewerName = MongoUserEngine.getFriendlyUserName(reviewer);
      review.reviewerLogoUrl = reviewer.logoUrl;
    }

    review.status = status;

    return review;
  }
}
