import { ObjectId } from 'bson';

export default class MongoProjectUpdate {
  _id = null;
  docId = "";

  projectId = "";
  projectCreatorId = "";
  projectTitle = "";
  projectLogoUrl = "";

  creatorId = "";
  creatorName = "";
  creatorLogoUrl = "";

  medias = [];                                            // MongoMedia
  comment = "";

  additionalFunding = null;                               // MongoFunding

  commentCount = 0;
  likeCount = 0;
  readCount = 0;

  timestamp = null;

  constructor() {
    this._id = new ObjectId();
    this.docId = this._id.valueOf().toString();
    this.timestamp = new Date();
  }
}
