import { ObjectId } from 'bson';

export default class MongoProjectPin {
  _id = null;

  projectId = "";
  pinnerIds = [];

  constructor() {
    this._id = new ObjectId();
  }
}
