export default class MongoMedia {
  static TYPE_IMAGE = "image";
  static TYPE_VIDEO = "video";

  type = "";
  url = "";

  constructor(type, url) {
    if (type != undefined) {
      this.type = type;
    }
    if (url != undefined) {
      this.url = url;
    }
  }
}
