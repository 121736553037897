import MongoNotificationShowcaseInvitationReject from "./notificationshowcaseinvitationreject";
import MongoUserEngine from "./users";

export default class MongoNotificationShowcaseInvitationRejectEngine {
  static fromJson(json) {
    var notification = new MongoNotificationShowcaseInvitationReject();
    try {
      if (json.showcaseId != undefined) {
        notification.showcaseId = json.showcaseId;
      }
      if (json.showcaseTitle != undefined) {
        notification.showcaseTitle = json.showcaseTitle;
      }

      if (json.inviteeId != undefined) {
        notification.inviteeId = json.inviteeId;
      }
      if (json.inviteeName != undefined) {
        notification.inviteeName = json.inviteeName;
      }
      if (json.inviteeLogoUrl != undefined) {
        notification.inviteeLogoUrl = json.inviteeLogoUrl;
      }

      if (json.invitorId != undefined) {
        notification.invitorId = json.invitorId;
      }
      if (json.invitorName != undefined) {
        notification.invitorName = json.invitorName;
      }
      if (json.invitorLogoUrl != undefined) {
        notification.invitorLogoUrl = json.invitorLogoUrl;
      }
    } catch(error) {
      console.log("[MongoNotificationShowcaseInvitationRejectEngine] fromJson() -> error: ", error);
    }

    return notification;
  }

  static generateByShowcaseAndInviteeAndInvitor(showcase, invitee, invitor) {
    var reject = new MongoNotificationShowcaseInvitationReject();
    reject.showcaseId = showcase.docId;
    reject.showcaseTitle = showcase.title;
    reject.inviteeId = invitee.docId;
    reject.inviteeName = MongoUserEngine.getFriendlyUserName(invitee);
    reject.inviteeLogoUrl = invitee.logoUrl;

    if (invitor != null) {
      reject.invitorId = invitor.docId;
      reject.invitorName = MongoUserEngine.getFriendlyUserName(invitor);
      reject.invitorLogoUrl = invitor.logoUrl;
    }

    return reject;
  }
}
