import MongoEngine from ".";
import MongoProjectUpdateCommentEngine from "./projectupdatecomments";
import MongoProjectUpdateCommentLike from "./projectupdatecommentlike";

export default class MongoProjectUpdateCommentLikeEngine {
  static async add(like) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdateCommentLike");
      var result = await collection.insertOne(like);
      like._id = result.insertedId;
    } catch(error) {
      console.log("[MongoProjectUpdateCommentLikeEngine] add() -> error: ", error);
    }
  }

  static async deleteByCommentId(commentId) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdateCommentLike");
      var filter = {
        commentId: commentId,
      };
      var result = await collection.deleteMany(filter);
    } catch(error) {
      console.log("[MongoProjectUpdateCommentEngine] deleteByUpdateId() -> error: ", error);
    }
  }

  static async deleteByCommentIds(commentIds) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdateCommentLike");
      var filter = {
        commentId: { $in: commentIds },
      };
      var result = await collection.deleteMany(filter);
    } catch(error) {
      console.log("[MongoProjectUpdateCommentEngine] deleteByCommentIds() -> error: ", error);
    }
  }

  static async deleteAll() {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdateCommentLike");
      var result = await collection.deleteMany();
    } catch(error) {
      console.log("[MongoProjectUpdateCommentLikeEngine] deleteAll() -> error: ", error);
    }
  }

  static async dislike(commentId, userId) {
    try {
      var like = await MongoProjectUpdateCommentLikeEngine.getByCommentId(commentId);
      if (like == null) {
        return false;
      }

      var likerIds = like.likerIds;
      var index = likerIds.findIndex(likerId => likerId == userId);
      if (index < 0) {
        return false;
      }

      likerIds.splice(index, 1);
      like.likerIds = likerIds;

      await MongoProjectUpdateCommentLikeEngine.update(like);

      return true;
    } catch(error) {
      console.log("[MongoProjectUpdateCommentLikeEngine] dislike() -> error: " + error.message);
    }

    return false;
  }

  static fromJson(json) {
    var like = new MongoProjectUpdateCommentLike();
    try {
      if (json._id != undefined) {
        like._id = json._id;
      }
      if (json.updateId != undefined) {
        like.updateId = json.updateId;
      }
      if (json.commentId != undefined) {
        like.commentId = json.commentId;
      }
      if (json.likerIds != undefined) {
        like.likerIds = json.likerIds;
      }
    } catch(error) {
      console.log("[MongoProjectUpdateCommentLikeEngine] fromJson() -> error: ", error);
    }

    return like;
  }

  static async getByCommentId(commentId) {
    var like = null;
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdateCommentLike");
      var filter = {
        commentId: commentId,
      };

      var mongoProjectUpdateCommentLikes = await collection.find(filter);
      if (mongoProjectUpdateCommentLikes.length == 0) {
        return null;
      }

      like = MongoProjectUpdateCommentLikeEngine.fromJson(mongoProjectUpdateCommentLikes[0]);
    } catch(error) {
      console.log("[MongoProjectUpdateCommentLikeEngine] getByProjectId() -> error: ", error);
    }

    return like;
  }

  static async getCountByCommentId(commentId) {
    var count = 0;
    try {
      var like = await MongoProjectUpdateCommentLikeEngine.getByCommentId(commentId);
      if (like == null) {
        return 0;
      }

      count = like.likerIds.length;
    } catch(error) {
      console.log("[MongoProjectUpdateCommentLikeEngine] getCountByCommentId() -> error: ", error);
    }

    return count;
  }

  static async getCountByUpdateId(updateId) {
    var count = 0;
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdateCommentLike");
      var filter = {
        updateId: updateId,
      };

      var mongoLikes = await collection.find(filter);
      mongoLikes.forEach(mongoLike => {
        var like = MongoProjectUpdateCommentLikeEngine.fromJson(mongoLike);
        count += like.likerIds.length;
      });
    } catch(error) {
      console.log("[MongoProjectUpdateCommentLikeEngine] getCountByUpdateId() -> error: ", error);
    }

    return count;
  }

  static async isLiked(commentId, userId) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdateCommentLike");
      var filter = {
        commentId: commentId,
        likerIds: userId,
      };

      var count = await collection.count(filter);
      if (count > 0) {
        return true;
      }
    } catch(error) {
      console.log("[MongoProjectUpdateCommentLikeEngine] isLiked() -> error: " + error);
    }

    return false;
  }

  static async like(updateId, commentId, userId) {
    try {
      var like = await MongoProjectUpdateCommentLikeEngine.getByCommentId(commentId);
      if (like == null) {
        like = new MongoProjectUpdateCommentLike();
        like.updateId = updateId;
        like.commentId = commentId;
      }

      var likerIds = like.likerIds;
      var index = likerIds.findIndex(likerId => likerId == userId);
      if (index >= 0) {
        return false;
      }

      likerIds.push(userId);
      like.likerIds = likerIds;

      await MongoProjectUpdateCommentLikeEngine.update(like);

      return true;
    } catch(error) {
      console.log("[MongoProjectUpdateCommentLikeEngine] like() -> error: " + error.message);
    }

    return false;
  }

  static async update(like) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdateCommentLike");
      var result = await collection.updateOne(
        { _id: like._id },
        { $set: like },
        { upsert: true },
      );
    } catch(error) {
      console.log("[MongoProjectUpdateCommentLikeEngine] update() -> error: ", error);
    }
  }
}
