import MongoNotificationShowcaseInvitationAccept from "./notificationshowcaseinvitationaccept";
import MongoUserEngine from "./users";

export default class MongoNotificationShowcaseInvitationAcceptEngine {
  static fromJson(json) {
    var notification = new MongoNotificationShowcaseInvitationAccept();
    try {
      if (json.showcaseId != undefined) {
        notification.showcaseId = json.showcaseId;
      }
      if (json.showcaseTitle != undefined) {
        notification.showcaseTitle = json.showcaseTitle;
      }

      if (json.inviteeId != undefined) {
        notification.inviteeId = json.inviteeId;
      }
      if (json.inviteeName != undefined) {
        notification.inviteeName = json.inviteeName;
      }
      if (json.inviteeLogoUrl != undefined) {
        notification.inviteeLogoUrl = json.inviteeLogoUrl;
      }

      if (json.invitorId != undefined) {
        notification.invitorId = json.invitorId;
      }
      if (json.invitorName != undefined) {
        notification.invitorName = json.invitorName;
      }
      if (json.invitorLogoUrl != undefined) {
        notification.invitorLogoUrl = json.invitorLogoUrl;
      }
    } catch(error) {
      console.log("[MongoNotificationShowcaseInvitationAcceptEngine] fromJson() -> error: ", error);
    }

    return notification;
  }

  static generateByShowcaseAndInviteeAndInvitor(showcase, invitee, invitor) {
    var accept = new MongoNotificationShowcaseInvitationAccept();
    accept.showcaseId = showcase.docId;
    accept.showcaseTitle = showcase.title;
    accept.inviteeId = invitee.docId;
    accept.inviteeName = MongoUserEngine.getFriendlyUserName(invitee);
    accept.inviteeLogoUrl = invitee.logoUrl;

    if (invitor != null) {
      accept.invitorId = invitor.docId;
      accept.invitorName = MongoUserEngine.getFriendlyUserName(invitor);
      accept.invitorLogoUrl = invitor.logoUrl;
    }

    return accept;
  }
}
