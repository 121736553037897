import { ObjectId } from 'bson';

export default class MongoProjectFollow {
  _id = null;

  projectId = "";
  followerIds = [];

  constructor() {
    this._id = new ObjectId();
  }
}
