import { ObjectId } from 'bson';

export default class MongoCallback {
  id = "";
  callback = null;

  constructor() {
    this.id = new ObjectId().toString();
  }
}
