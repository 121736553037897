export default class MongoNotificationProjectPledged {
  projectId = "";
  projectTitle = "";
  pledgerId = "";
  pledgerName = "";
  type = "";
  amount = 0;
  percent = 0;
  currency = "";
}
