// heart
// heart value should be calculated on base price
export const HEART_VALUE = 1; // 1 heart = 10 pence / 10 cents


export const COUNTRY_LIST = [
    {name: 'Afghanistan', code: 'AF', currency: "USD"},
    {name: 'Åland Islands', code: 'AX', currency: "USD"},
    {name: 'Albania', code: 'AL', currency: "USD"},
    {name: 'Algeria', code: 'DZ', currency: "USD"},
    {name: 'American Samoa', code: 'AS', currency: "USD"},
    {name: 'AndorrA', code: 'AD', currency: "USD"},
    {name: 'Angola', code: 'AO', currency: "USD"},
    {name: 'Anguilla', code: 'AI', currency: "USD"},
    {name: 'Antarctica', code: 'AQ', currency: "USD"},
    {name: 'Antigua and Barbuda', code: 'AG', currency: "USD"},
    {name: 'Argentina', code: 'AR', currency: "USD"},
    {name: 'Armenia', code: 'AM', currency: "USD"},
    {name: 'Aruba', code: 'AW', currency: "USD"},
    {name: 'Australia', code: 'AU', currency: "AUD"},
    {name: 'Austria', code: 'AT', currency: "EUR"},
    {name: 'Azerbaijan', code: 'AZ', currency: "USD"},
    {name: 'Bahamas', code: 'BS', currency: "USD"},
    {name: 'Bahrain', code: 'BH', currency: "USD"},
    {name: 'Bangladesh', code: 'BD', currency: "USD"},
    {name: 'Barbados', code: 'BB', currency: "USD"},
    {name: 'Belarus', code: 'BY', currency: "USD"},
    {name: 'Belgium', code: 'BE', currency: "EUR"},
    {name: 'Belize', code: 'BZ', currency: "USD"},
    {name: 'Benin', code: 'BJ', currency: "USD"},
    {name: 'Bermuda', code: 'BM', currency: "USD"},
    {name: 'Bhutan', code: 'BT', currency: "USD"},
    {name: 'Bolivia', code: 'BO', currency: "USD"},
    {name: 'Bosnia and Herzegovina', code: 'BA', currency: "USD"},
    {name: 'Botswana', code: 'BW', currency: "USD"},
    {name: 'Bouvet Island', code: 'BV', currency: "USD"},
    {name: 'Brazil', code: 'BR', currency: "USD"},
    {name: 'British Indian Ocean Territory', code: 'IO', currency: "USD"},
    {name: 'Brunei Darussalam', code: 'BN', currency: "USD"},
    {name: 'Bulgaria', code: 'BG', currency: "EUR"},
    {name: 'Burkina Faso', code: 'BF', currency: "USD"},
    {name: 'Burundi', code: 'BI', currency: "USD"},
    {name: 'Cambodia', code: 'KH', currency: "USD"},
    {name: 'Cameroon', code: 'CM', currency: "USD"},
    {name: 'Canada', code: 'CA', currency: "CAD"},
    {name: 'Cape Verde', code: 'CV', currency: "USD"},
    {name: 'Cayman Islands', code: 'KY', currency: "USD"},
    {name: 'Central African Republic', code: 'CF', currency: "USD"},
    {name: 'Chad', code: 'TD', currency: "USD"},
    {name: 'Chile', code: 'CL', currency: "USD"},
    {name: 'China', code: 'CN', currency: "USD"},
    {name: 'Christmas Island', code: 'CX', currency: "USD"},
    {name: 'Cocos (Keeling) Islands', code: 'CC', currency: "USD"},
    {name: 'Colombia', code: 'CO', currency: "USD"},
    {name: 'Comoros', code: 'KM', currency: "USD"},
    {name: 'Congo', code: 'CG', currency: "USD"},
    {name: 'Congo, The Democratic Republic of the', code: 'CD', currency: "USD"},
    {name: 'Cook Islands', code: 'CK', currency: "USD"},
    {name: 'Costa Rica', code: 'CR', currency: "USD"},
    {name: 'Cote D\'Ivoire', code: 'CI', currency: "USD"},
    {name: 'Croatia', code: 'HR', currency: "EUR"},
    {name: 'Cuba', code: 'CU', currency: "USD"},
    {name: 'Cyprus', code: 'CY', currency: "EUR"},
    {name: 'Czech Republic', code: 'CZ', currency: "USD"},
    {name: 'Denmark', code: 'DK', currency: "EUR"},
    {name: 'Djibouti', code: 'DJ', currency: "USD"},
    {name: 'Dominica', code: 'DM', currency: "USD"},
    {name: 'Dominican Republic', code: 'DO', currency: "USD"},
    {name: 'Ecuador', code: 'EC', currency: "USD"},
    {name: 'Egypt', code: 'EG', currency: "USD"},
    {name: 'El Salvador', code: 'SV', currency: "USD"},
    {name: 'Equatorial Guinea', code: 'GQ', currency: "USD"},
    {name: 'Eritrea', code: 'ER', currency: "USD"},
    {name: 'Estonia', code: 'EE', currency: "EUR"},
    {name: 'Ethiopia', code: 'ET', currency: "USD"},
    {name: 'Falkland Islands (Malvinas)', code: 'FK', currency: "USD"},
    {name: 'Faroe Islands', code: 'FO', currency: "USD"},
    {name: 'Fiji', code: 'FJ', currency: "USD"},
    {name: 'Finland', code: 'FI', currency: "EUR"},
    {name: 'France', code: 'FR', currency: "EUR"},
    {name: 'French Guiana', code: 'GF', currency: "USD"},
    {name: 'French Polynesia', code: 'PF', currency: "USD"},
    {name: 'French Southern Territories', code: 'TF', currency: "USD"},
    {name: 'Gabon', code: 'GA', currency: "USD"},
    {name: 'Gambia', code: 'GM', currency: "USD"},
    {name: 'Georgia', code: 'GE', currency: "USD"},
    {name: 'Germany', code: 'DE', currency: "EUR"},
    {name: 'Ghana', code: 'GH', currency: "USD"},
    {name: 'Gibraltar', code: 'GI', currency: "USD"},
    {name: 'Greece', code: 'GR', currency: "EUR"},
    {name: 'Greenland', code: 'GL', currency: "USD"},
    {name: 'Grenada', code: 'GD', currency: "USD"},
    {name: 'Guadeloupe', code: 'GP', currency: "USD"},
    {name: 'Guam', code: 'GU', currency: "USD"},
    {name: 'Guatemala', code: 'GT', currency: "USD"},
    {name: 'Guernsey', code: 'GG', currency: "USD"},
    {name: 'Guinea', code: 'GN', currency: "USD"},
    {name: 'Guinea-Bissau', code: 'GW', currency: "USD"},
    {name: 'Guyana', code: 'GY', currency: "USD"},
    {name: 'Haiti', code: 'HT', currency: "USD"},
    {name: 'Heard Island and Mcdonald Islands', code: 'HM', currency: "USD"},
    {name: 'Holy See (Vatican City State)', code: 'VA', currency: "USD"},
    {name: 'Honduras', code: 'HN', currency: "USD"},
    {name: 'Hong Kong', code: 'HK', currency: "USD"},
    {name: 'Hungary', code: 'HU', currency: "EUR"},
    {name: 'Iceland', code: 'IS', currency: "USD"},
    {name: 'India', code: 'IN', currency: "USD"},
    {name: 'Indonesia', code: 'ID', currency: "USD"},
    {name: 'Iran, Islamic Republic Of', code: 'IR', currency: "USD"},
    {name: 'Iraq', code: 'IQ', currency: "USD"},
    {name: 'Ireland', code: 'IE', currency: "EUR"},
    {name: 'Isle of Man', code: 'IM', currency: "USD"},
    {name: 'Israel', code: 'IL', currency: "USD"},
    {name: 'Italy', code: 'IT', currency: "EUR"},
    {name: 'Jamaica', code: 'JM', currency: "USD"},
    {name: 'Japan', code: 'JP', currency: "USD"},
    {name: 'Jersey', code: 'JE', currency: "USD"},
    {name: 'Jordan', code: 'JO', currency: "USD"},
    {name: 'Kazakhstan', code: 'KZ', currency: "USD"},
    {name: 'Kenya', code: 'KE', currency: "USD"},
    {name: 'Kiribati', code: 'KI', currency: "USD"},
    {name: 'Korea, Democratic People\'S Republic of', code: 'KP', currency: "USD"},
    {name: 'Korea, Republic of', code: 'KR', currency: "USD"},
    {name: 'Kuwait', code: 'KW', currency: "USD"},
    {name: 'Kyrgyzstan', code: 'KG', currency: "USD"},
    {name: 'Lao People\'S Democratic Republic', code: 'LA', currency: "USD"},
    {name: 'Latvia', code: 'LV', currency: "EUR"},
    {name: 'Lebanon', code: 'LB', currency: "USD"},
    {name: 'Lesotho', code: 'LS', currency: "USD"},
    {name: 'Liberia', code: 'LR', currency: "USD"},
    {name: 'Libyan Arab Jamahiriya', code: 'LY', currency: "USD"},
    {name: 'Liechtenstein', code: 'LI', currency: "USD"},
    {name: 'Lithuania', code: 'LT', currency: "EUR"},
    {name: 'Luxembourg', code: 'LU', currency: "EUR"},
    {name: 'Macao', code: 'MO', currency: "USD"},
    {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK', currency: "USD"},
    {name: 'Madagascar', code: 'MG', currency: "USD"},
    {name: 'Malawi', code: 'MW', currency: "USD"},
    {name: 'Malaysia', code: 'MY', currency: "USD"},
    {name: 'Maldives', code: 'MV', currency: "USD"},
    {name: 'Mali', code: 'ML', currency: "USD"},
    {name: 'Malta', code: 'MT', currency: "EUR"},
    {name: 'Marshall Islands', code: 'MH', currency: "USD"},
    {name: 'Martinique', code: 'MQ', currency: "USD"},
    {name: 'Mauritania', code: 'MR', currency: "USD"},
    {name: 'Mauritius', code: 'MU', currency: "USD"},
    {name: 'Mayotte', code: 'YT', currency: "USD"},
    {name: 'Mexico', code: 'MX', currency: "USD"},
    {name: 'Micronesia, Federated States of', code: 'FM', currency: "USD"},
    {name: 'Moldova, Republic of', code: 'MD', currency: "USD"},
    {name: 'Monaco', code: 'MC', currency: "USD"},
    {name: 'Mongolia', code: 'MN', currency: "USD"},
    {name: 'Montserrat', code: 'MS', currency: "USD"},
    {name: 'Morocco', code: 'MA', currency: "USD"},
    {name: 'Mozambique', code: 'MZ', currency: "USD"},
    {name: 'Myanmar', code: 'MM', currency: "USD"},
    {name: 'Namibia', code: 'NA', currency: "USD"},
    {name: 'Nauru', code: 'NR', currency: "USD"},
    {name: 'Nepal', code: 'NP', currency: "USD"},
    {name: 'Netherlands', code: 'NL', currency: "EUR"},
    {name: 'Netherlands Antilles', code: 'AN', currency: "USD"},
    {name: 'New Caledonia', code: 'NC', currency: "USD"},
    {name: 'New Zealand', code: 'NZ', currency: "USD"},
    {name: 'Nicaragua', code: 'NI', currency: "USD"},
    {name: 'Niger', code: 'NE', currency: "USD"},
    {name: 'Nigeria', code: 'NG', currency: "USD"},
    {name: 'Niue', code: 'NU', currency: "USD"},
    {name: 'Norfolk Island', code: 'NF', currency: "USD"},
    {name: 'Northern Mariana Islands', code: 'MP', currency: "USD"},
    {name: 'Norway', code: 'NO', currency: "USD"},
    {name: 'Oman', code: 'OM', currency: "USD"},
    {name: 'Pakistan', code: 'PK', currency: "USD"},
    {name: 'Palau', code: 'PW', currency: "USD"},
    {name: 'Palestinian Territory, Occupied', code: 'PS', currency: "USD"},
    {name: 'Panama', code: 'PA', currency: "USD"},
    {name: 'Papua New Guinea', code: 'PG', currency: "USD"},
    {name: 'Paraguay', code: 'PY', currency: "USD"},
    {name: 'Peru', code: 'PE', currency: "USD"},
    {name: 'Philippines', code: 'PH', currency: "USD"},
    {name: 'Pitcairn', code: 'PN', currency: "USD"},
    {name: 'Poland', code: 'PL', currency: "EUR"},
    {name: 'Portugal', code: 'PT', currency: "EUR"},
    {name: 'Puerto Rico', code: 'PR', currency: "USD"},
    {name: 'Qatar', code: 'QA', currency: "USD"},
    {name: 'Reunion', code: 'RE', currency: "USD"},
    {name: 'Romania', code: 'RO', currency: "EUR"},
    {name: 'Russian Federation', code: 'RU', currency: "USD"},
    {name: 'RWANDA', code: 'RW', currency: "USD"},
    {name: 'Saint Helena', code: 'SH', currency: "USD"},
    {name: 'Saint Kitts and Nevis', code: 'KN', currency: "USD"},
    {name: 'Saint Lucia', code: 'LC', currency: "USD"},
    {name: 'Saint Pierre and Miquelon', code: 'PM', currency: "USD"},
    {name: 'Saint Vincent and the Grenadines', code: 'VC', currency: "USD"},
    {name: 'Samoa', code: 'WS', currency: "USD"},
    {name: 'San Marino', code: 'SM', currency: "USD"},
    {name: 'Sao Tome and Principe', code: 'ST', currency: "USD"},
    {name: 'Saudi Arabia', code: 'SA', currency: "USD"},
    {name: 'Senegal', code: 'SN', currency: "USD"},
    {name: 'Serbia and Montenegro', code: 'CS', currency: "USD"},
    {name: 'Seychelles', code: 'SC', currency: "USD"},
    {name: 'Sierra Leone', code: 'SL', currency: "USD"},
    {name: 'Singapore', code: 'SG', currency: "USD"},
    {name: 'Slovakia', code: 'SK', currency: "EUR"},
    {name: 'Slovenia', code: 'SI', currency: "EUR"},
    {name: 'Solomon Islands', code: 'SB', currency: "USD"},
    {name: 'Somalia', code: 'SO', currency: "USD"},
    {name: 'South Africa', code: 'ZA', currency: "USD"},
    {name: 'South Georgia and the South Sandwich Islands', code: 'GS', currency: "USD"},
    {name: 'Spain', code: 'ES', currency: "EUR"},
    {name: 'Sri Lanka', code: 'LK', currency: "USD"},
    {name: 'Sudan', code: 'SD', currency: "USD"},
    {name: 'Suriname', code: 'SR', currency: "USD"},
    {name: 'Svalbard and Jan Mayen', code: 'SJ', currency: "USD"},
    {name: 'Swaziland', code: 'SZ', currency: "USD"},
    {name: 'Sweden', code: 'SE', currency: "EUR"},
    {name: 'Switzerland', code: 'CH', currency: "USD"},
    {name: 'Syrian Arab Republic', code: 'SY', currency: "USD"},
    {name: 'Taiwan, Province of China', code: 'TW', currency: "USD"},
    {name: 'Tajikistan', code: 'TJ', currency: "USD"},
    {name: 'Tanzania, United Republic of', code: 'TZ', currency: "USD"},
    {name: 'Thailand', code: 'TH', currency: "USD"},
    {name: 'Timor-Leste', code: 'TL', currency: "USD"},
    {name: 'Togo', code: 'TG', currency: "USD"},
    {name: 'Tokelau', code: 'TK', currency: "USD"},
    {name: 'Tonga', code: 'TO', currency: "USD"},
    {name: 'Trinidad and Tobago', code: 'TT', currency: "USD"},
    {name: 'Tunisia', code: 'TN', currency: "USD"},
    {name: 'Turkey', code: 'TR', currency: "USD"},
    {name: 'Turkmenistan', code: 'TM', currency: "USD"},
    {name: 'Turks and Caicos Islands', code: 'TC', currency: "USD"},
    {name: 'Tuvalu', code: 'TV', currency: "USD"},
    {name: 'Uganda', code: 'UG', currency: "USD"},
    {name: 'Ukraine', code: 'UA', currency: "USD"},
    {name: 'United Arab Emirates', code: 'AE', currency: "USD"},
    {name: 'United Kingdom', code: 'GB', currency: "GBP"},
    {name: 'United States', code: 'US', currency: "USD"},
    {name: 'United States Minor Outlying Islands', code: 'UM', currency: "USD"},
    {name: 'Uruguay', code: 'UY', currency: "USD"},
    {name: 'Uzbekistan', code: 'UZ', currency: "USD"},
    {name: 'Vanuatu', code: 'VU', currency: "USD"},
    {name: 'Venezuela', code: 'VE', currency: "USD"},
    {name: 'Viet Nam', code: 'VN', currency: "USD"},
    {name: 'Virgin Islands, British', code: 'VG', currency: "USD"},
    {name: 'Virgin Islands, U.S.', code: 'VI', currency: "USD"},
    {name: 'Wallis and Futuna', code: 'WF', currency: "USD"},
    {name: 'Western Sahara', code: 'EH', currency: "USD"},
    {name: 'Yemen', code: 'YE', currency: "USD"},
    {name: 'Zambia', code: 'ZM', currency: "USD"},
    {name: 'Zimbabwe', code: 'ZW'}
  ];
  
  export const CURRENCY_LIST = [
    {code: 'GBP', symbol: "£", cent: "pence"},
    {code: 'USD', symbol: "$", cent: "cents"},
    {code: 'EUR', symbol: "€", cent: "cents euro"},
    {code: 'CAD', symbol: "$", cent: "cents"},
    {code: 'AUD', symbol: "$", cent: "cents"}
  ];