import { ObjectId } from 'bson';

export default class MongoNotification {
  static TYPE_PROJECT_FUNDED = "project_funded";
  static TYPE_PROJECT_PLEDGED = "project_pledged";

  static TYPE_PROJECT_INVITE = "project_invite";
  static TYPE_PROJECT_INVITE_ACCEPT = "project_invite_accept";
  static TYPE_PROJECT_INVITE_REJECT = "project_invite_reject";

  static TYPE_PROJECT_LAUNCHED = "project_launched";
  static TYPE_PROJECT_LAUNCHED_INVITEE = "project_launched_invitee";

  static TYPE_SHOWCASE_INVITE = "showcase_invite";
  static TYPE_SHOWCASE_INVITE_ACCEPT = "showcase_invite_accept";
  static TYPE_SHOWCASE_INVITE_REJECT = "showcase_invite_reject";

  static TYPE_CURATED_PROJECT_REVIEW = "showcase_curated_project_review";

  _id = null;
  docId = "";

  type = "";
  toUserId = "";
  data = null;

  createdAt = null;
  readAt = null;

  constructor() {
    this._id = new ObjectId();
    this.docId = this._id.valueOf().toString();
    this.createdAt = new Date();
  }
}
