export default class MongoNotificationShowcaseReview {
    projectId = "";
    projectTitle = "";

    creatorId = "";
    creatorName = "";
    creatorLogoUrl = "";

    reviewerId = "";
    reviewerName = "";

    status = "";
  }
  