export default class MongoBilling {
  countryCode = "";

  stripeCustomerId = "";

  bankCards = [];                // MonboBankCard
  paypals = [];                  // MongoPaypal
  stripeBanks = [];              // MongoStripeBank

  monthlyBudget = 1000000;

  excludeCashback = true;
  excludeWinkback = true;

  reclaimCashback = 500000;
  reclaimOrderNo = "";
}
