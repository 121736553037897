import MongoTeam from "./team";
import MongoUserEngine from "./users";

export default class MongoTeamEngine {
  static fromJson(json) {
    var team = new MongoTeam();
    if (json.type != undefined) {
      team.type = json.type;
    }

    if (json.memberId != undefined) {
      team.memberId = json.memberId;
    }
    if (json.memberName != undefined) {
      team.memberName = json.memberName;
    }
    if (json.memberLogoUrl != undefined) {
      team.memberLogoUrl = json.memberLogoUrl;
    }

    if (json.invitorId != undefined) {
      team.invitorId = json.invitorId;
    }
    if (json.reject != undefined) {
      team.reject = json.reject;
    }

    return team;
  }

  static generateByTypeAndMemberAndInvitorId(type, member, invitorId, reject) {
    var team = new MongoTeam();
    if (type != undefined) {
      team.type = type;
    }

    if (member != null) {
      team.memberId = member.docId;
      team.memberName = MongoUserEngine.getFriendlyUserName(member);
      team.memberLogoUrl = member.logoUrl;
    }

    if (invitorId != undefined) {
      team.invitorId = invitorId;
    }
    if (reject != undefined) {
      team.reject = reject;
    }

    return team;
  }

  static getCreatorByProject(project) {
    var creator = new MongoTeam();
    creator.type = MongoTeam.TYPE_CREATOR;
    creator.memberId = project.creatorId;
    creator.memberName = project.creatorName;
    creator.memberLogoUrl = project.creatorLogoUrl;
    return creator
  }

  static isCreator = (team) => {
    if (team.type.toLowerCase() == MongoTeam.TYPE_CREATOR.toLowerCase()) {
      return true;
    }

    return false;
  }

  static isCocreator = (team) => {
    if (team.type.toLowerCase() == MongoTeam.TYPE_COCREATOR.toLowerCase()) {
      return true;
    }

    return false;
  }

  static isInvited = (team) => {
    if (team.type.toLowerCase() == MongoTeam.TYPE_INVITED.toLowerCase()) {
      return true;
    }

    return false;
  }

  static isMember = (team) => {
    if (team.type.toLowerCase() == MongoTeam.TYPE_MEMBER.toLowerCase()) {
      return true;
    }

    return false;
  }

  static isReject = (team) => {
    if (MongoTeamEngine.isInvited(team) && team.reject == true) {
      return true;
    }

    return false;
  }
}
