import { ObjectId } from 'bson';

export default class MongoProjectLike {
  _id = null;

  projectId = "";
  likerIds = [];

  constructor() {
    this._id = new ObjectId();
  }
}
