import { ObjectId } from 'bson';

export default class MongoEnviromate {
  _id = null;

  userId = "";

  enviromatees = [];
  enviromators = [];

  constructor() {
    this._id = new ObjectId();
  }
}
