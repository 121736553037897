import MongoNotificationProjectLaunched from "./notificationprojectlaunched";
import MongoProjectEngine from "./projects";
import MongoUserEngine from "./users";

export default class MongoNotificationProjectLaunchedEngine {
  static fromJson(json) {
    var notification = new MongoNotificationProjectLaunched();
    try {
      if (json.projectId != undefined) {
        notification.projectId = json.projectId;
      }
      if (json.projectTitle != undefined) {
        notification.projectTitle = json.projectTitle;
      }
      if (json.projectLogoUrl != undefined) {
        notification.projectLogoUrl = json.projectLogoUrl;
      }

      if (json.launcherId != undefined) {
        notification.launcherId = json.launcherId;
      }
      if (json.launcherName != undefined) {
        notification.launcherName = json.launcherName;
      }
      if (json.launcherLogoUrl != undefined) {
        notification.launcherLogoUrl = json.launcherLogoUrl;
      }
    } catch(error) {
      console.log("[MongoNotificationProjectLaunchedEngine] fromJson() -> error: ", error);
    }

    return notification;
  }

  static generateByProjectAndLauncher(project, launcher) {
    var notification = new MongoNotificationProjectLaunched();
    notification.projectId = project.docId;
    notification.projectTitle = project.title;
    var logo = MongoProjectEngine.getLogoImage(project);
    notification.projectLogoUrl = logo == null ? "" : logo.url;
    notification.launcherId = launcher.docId;
    notification.launcherName = MongoUserEngine.getFriendlyUserName(launcher);
    notification.launcherLogoUrl = launcher.logoUrl;

    return notification;
  }
}
