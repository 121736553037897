import MongoNotificationProjectPledged from "./notificationprojectpledged";
import MongoProjectEngine from "./projects";

export default class MongoNotificationProjectPledgedEngine {
  static fromJson(json) {
    var notification = new MongoNotificationProjectPledged();

    try {
      if (json.projectId != undefined) {
        notification.projectId = json.projectId;
      }
      if (json.projectTitle != undefined) {
        notification.projectTitle = json.projectTitle;
      }
      if (json.pledgerId != undefined) {
        notification.pledgerId = json.pledgerId;
      }
      if (json.pledgerName != undefined) {
        notification.pledgerName = json.pledgerName;
      }
      if (json.type != undefined) {
        notification.type = json.type;
      }
      if (json.amount != undefined) {
        notification.amount = json.amount;
      }
      if (json.percent != undefined) {
        notification.percent = json.percent;
      }
      if (json.currency != undefined) {
        notification.currency = json.currency;
      }
    } catch(error) {
      console.log("[MongoNotificationProjectPledgedEngine] fromJson() -> error: ", error);
    }

    return notification;
  }

  static generateByProjectAndPledge(project, mongoPledge) {
    var fundedPercent = MongoProjectEngine.getFundedPercentWithDecimals(project, 1);
    var notification = new MongoNotificationProjectPledged();
    notification.projectId = project.docId;
    notification.projectTitle = project.title;
    notification.pledgerId = mongoPledge.pledgerId;
    notification.pledgerName = mongoPledge.pledgerName;
    notification.type = mongoPledge.type;
    notification.amount = mongoPledge.amount;
    notification.percent = fundedPercent;
    notification.currency = mongoPledge.currencyCode;
    return notification;
  }
}
