import MongoNotificationProjectInvitationAccept from "./notificationprojectinvitationaccept";
import MongoUserEngine from "./users";

export default class MongoNotificationProjectInvitationAcceptEngine {
  static fromJson(json) {
    var notification = new MongoNotificationProjectInvitationAccept();
    try {
      if (json.projectId != undefined) {
        notification.projectId = json.projectId;
      }
      if (json.projectTitle != undefined) {
        notification.projectTitle = json.projectTitle;
      }

      if (json.inviteeId != undefined) {
        notification.inviteeId = json.inviteeId;
      }
      if (json.inviteeName != undefined) {
        notification.inviteeName = json.inviteeName;
      }
      if (json.inviteeLogoUrl != undefined) {
        notification.inviteeLogoUrl = json.inviteeLogoUrl;
      }

      if (json.invitorId != undefined) {
        notification.invitorId = json.invitorId;
      }
      if (json.invitorName != undefined) {
        notification.invitorName = json.invitorName;
      }
      if (json.invitorLogoUrl != undefined) {
        notification.invitorLogoUrl = json.invitorLogoUrl;
      }
    } catch(error) {
      console.log("[MongoNotificationProjectInvitationAcceptEngine] fromJson() -> error: ", error);
    }

    return notification;
  }

  static generateByProjectAndInviteeAndInvitor(project, invitee, invitor) {
    var accept = new MongoNotificationProjectInvitationAccept();
    accept.projectId = project.docId;
    accept.projectTitle = project.title;
    accept.inviteeId = invitee.docId;
    accept.inviteeName = MongoUserEngine.getFriendlyUserName(invitee);
    accept.inviteeLogoUrl = invitee.logoUrl;

    if (invitor != null) {
      accept.invitorId = invitor.docId;
      accept.invitorName = MongoUserEngine.getFriendlyUserName(invitor);
      accept.invitorLogoUrl = invitor.logoUrl;
    }

    return accept;
  }
}
