import MongoEngine from ".";
import MongoProjectFollow from "./projectfollow";
import MongoProjectEngine from "./projects";
import MongoUserEngine from "./users";

export default class MongoProjectFollowEngine {
  static async add(follow) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectFollow");
      var result = await collection.insertOne(follow);
      follow._id = result.insertedId;
    } catch(error) {
      console.log("[MongoProjectFollowEngine] add() -> error: ", error);
    }
  }

  static async deleteByProjectId(projectId) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectFollow");
      var filter = {
        projectId: projectId,
      };
      var result = await collection.deleteMany(filter);
    } catch(error) {
      console.log("[MongoProjectFollowEngine] deleteByProjectId() -> error: ", error);
    }
  }

  static async deleteAll() {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectFollow");
      var result = await collection.deleteMany();
    } catch(error) {
      console.log("[MongoProjectFollowEngine] deleteAll() -> error: ", error);
    }
  }

  static async follow(project, userId) {
    try {
      var mongoFollow = await MongoProjectFollowEngine.getByProjectId(project.docId);
      if (mongoFollow == null) {
        mongoFollow = new MongoProjectFollow();
        mongoFollow.projectId = project.docId;
      }

      var followerIds = mongoFollow.followerIds;
      var index = followerIds.find(followerId => followerId == userId);
      if (index >= 0) {
        return false;
      }

      followerIds.push(userId);
      mongoFollow.followerIds = followerIds;

      await MongoProjectFollowEngine.update(mongoFollow);

      // increase follow count of project
      project.followCount = followerIds.length;
      await MongoProjectEngine.update(project);

      return true;
    } catch(error) {
      console.log("[MongoProjectFollowEngine] follow() -> error: " + error.message);
    }

    return false;
  }

  static fromJson(json) {
    var follow = new MongoProjectFollow();
    try {
      if (json._id != undefined) {
        follow._id = json._id;
      }
      if (json.projectId != undefined) {
        follow.projectId = json.projectId;
      }
      if (json.followerIds != undefined) {
        follow.followerIds = json.followerIds;
      }
    } catch(error) {
      console.log("[MongoProjectFollowEngine] fromJson() -> error: ", error);
    }

    return follow;
  }

  static async getByProjectId(projectId) {
    var follow = null;
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectFollow");
      var filter = {
        projectId: projectId,
      };

      var mongoProjectFollows = await collection.find(filter);
      if (mongoProjectFollows.length == 0) {
        return null;
      }

      follow = MongoProjectFollowEngine.fromJson(mongoProjectFollows[0]);
    } catch(error) {
      console.log("[MongoProjectFollowEngine] getByProjectId() -> error: ", error);
    }

    return follow;
  }

  static async isFollowed(projectId, userId) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectFollow");
      var filter = {
        projectId: projectId,
        followerIds: userId,
      };

      var count = await collection.count(filter);
      if (count > 0) {
        return true;
      }
    } catch(error) {
      console.log("[MongoProjectFollowEngine] isFollowed() -> error: " + error);
    }

    return false;
  }

  static async unfollow(project, userId) {
    try {
      var follow = await MongoProjectFollowEngine.getByProjectId(project.docId);
      if (follow == null) {
        return false;
      }

      var followerIds = follow.followerIds;
      var index = followerIds.find(followerId => followerId == userId);
      if (index < 0) {
        return false;
      }

      followerIds.splice(index, 1);
      follow.followerIds = followerIds;

      await MongoProjectFollowEngine.update(follow);

      // decrease follow count of project
      project.followCount = followerIds.length;
      await MongoProjectEngine.update(project);

      return true;
    } catch(error) {
      console.log("[MongoProjectFollowEngine] unfollow() -> error: ", error);
    }

    return false;
  }

  static async update(follow) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectFollow");
      var result = await collection.updateOne(
        { _id: follow._id },
        { $set: follow },
        { upsert: true },
      );
    } catch(error) {
      console.log("[MongoProjectFollowEngine] update() -> error: ", error);
    }
  }
}
