import { ObjectId } from 'bson';

export default class MongoProjectUpdateLike {
  _id = null;

  projectId= "";
  updateId = "";
  likerIds = [];

  constructor() {
    this._id = new ObjectId();
    this.docId = this._id.valueOf().toString();
  }
}
