import React, { Component } from "react";
import {Link, useParams} from 'react-router-dom';

import { Player, BigPlayButton, ControlBar } from "video-react";
import "video-react/dist/video-react.css";

import Header from "../components/header";
import Footer from "../components/footer";

import AppStoreButton from "../components/buttons/appstore";
import GoogleStoreButton from "../components/buttons/googlestore";

import "../../App.css";
import "./styles.css";

import backgroundImage from "../../res/images/img-home-background.png";
import beeLeftImage from "../../res/images/img-global-bee-left.png";
import beeRightImage from "../../res/images/img-global-bee-right.png";
import qrcodeImage from "../../res/images/img-home-qrcode.png";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faInstagram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { Avatar, Tab, Tabs } from '@mui/material';

import MongoUserEngine from "../../engine/mongodb/users";
import MongoUserInfoEngine from "../../engine/mongodb/userinfos";
import MongoProject from '../../engine/mongodb/project';
import MongoProjectEngine from "../../engine/mongodb/projects";
import MongoProjectUpdateEngine from '../../engine/mongodb/projectupdates';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
//import SimpleLineIcon from 'react-simple-line-icons';
import { SlPin } from "react-icons/sl";


import ProjectPanel from './components/panel';
import ProjectPostPanel from './components/post/panel';

import Popup from "react-popup";
import { FaUnlink } from "react-icons/fa";

const BACKGROUND_SIZE = {
  height: 3286,
  width: 2861,
};

const SCREEN_HEIGHT = 1600;
const SCREEN_WIDTH = 1440;
const SCREEN_ASPECTRATIO = BACKGROUND_SIZE.width / BACKGROUND_SIZE.height;

const MOBILE_SCREEN_HEIGHT = 1360;
const MOBILE_SCREEN_WIDTH = 1440;
const MOBILE_SCREEN_ASPECTRATIO = MOBILE_SCREEN_WIDTH / MOBILE_SCREEN_HEIGHT;

const BACKGROUND_MARGINTOP = 800;
const VIDEO_WIDTH = 333;

// adjust later
const TAB_HEIGHT = 580;

class UserProfile extends Component {
  constructor(props) {
    super(props);

    var screenWidth = window.innerWidth;

    this.state = {
      screenWidth: screenWidth,
      videoStopped: false,
      userId: "",
      user: null,
      userInfo: null,
      name: '',
      enviromateCount: 0,
      followerCount: 0,
      pledgeCount: 0,
      projectCount: 0,

      isFollowee: false,
      isEnviromate: false,

      pinnedProjects: [],
      unpinnedProjects: [],
      enviromatesPinnedProjects: [],
      enviromateInvitedProjects: [],
      enviromatesUnpinnedProjects: [],
      updates: [],
      
      tab: "projects",
      
      userLogoUrls: [],
      showUserLogoView: false,
      userVideoUrl: "",
      showUserVideoView: false,

      showActionComment: false,
      availableHearts: [],
      targetProject: null,
      targetPost: null,
      targetComment: null,
      commentUpdatedCallback: null,
      commentText: "",
      commentHeartCount: 0,
      loading: false,
    };

    this.player = null;
    this.projectPanels = [];
    this.enviromateProjectPanels = [];
  }

  componentDidUpdate(prevProps) {

  }

  componentWillUnmount() {
    // this._unsubscribeFocus();
  }

  componentDidMount = async () => {
    window.addEventListener("resize", this.onWindowResized);
    await this.refresh();
  }

  refreshPledges = async () => {
    this.setState({
      loading: true,
    });

    var user = this.state.user;
    var pledgeCount = user.supportCount;

    this.setState({
      pledgeCount: pledgeCount,
      loading: false,
    });
  }

  refreshProjects = async () => {
    this.setState({
      loading: true,
    });

    var projectCount = 0;
    var pinnedProjects = [];
    var unpinnedProjects = [];
    var user = this.state.user;
    try {
      pinnedProjects = await MongoProjectEngine.getPinnedForMembersByUserId(user.docId);
      pinnedProjects.forEach(project => {
        if (project.status == MongoProject.STATUS_ONLINE) {
          projectCount++;
        }
      });

      unpinnedProjects = await MongoProjectEngine.getUnpinnedForMembersByUserId(user.docId);
      unpinnedProjects.forEach(project => {
        if (project.status == MongoProject.STATUS_ONLINE) {
          projectCount++;
        }
      });
    } catch(error) {
      console.log("[ProfileEnviromate] refreshProjects() -> error: ", error.message);
    }

    this.setState({
      projectCount: projectCount,
      pinnedProjects: pinnedProjects,
      unpinnedProjects: unpinnedProjects,
      loading: false,
    });
  }

  refreshEnviromatesProjects = async () => {
    this.setState({
      loading: true,
    });

    var user = this.state.user;

    // pinned projects
    var enviromatesPinnedProjects = await MongoProjectEngine.getPinnedForEnviromatesByUserId(user.docId);

    // invited projects
    var enviromateInvitedProjects = await MongoProjectEngine.getInvitedByUserId(user.docId);
    
    // unpinned projects
    var enviromatesUnpinnedProjects = await MongoProjectEngine.getUnpinnedForEnviromatesByUserId(user.docId);

    this.setState({
      enviromatesPinnedProjects: enviromatesPinnedProjects,
      enviromateInvitedProjects: enviromateInvitedProjects,
      enviromatesUnpinnedProjects: enviromatesUnpinnedProjects,
      loading: false,
    });
  }

  refreshUpdates = async () => {
    this.setState({
      loading: true,
    });

    var projectIds = [];
    var pinnedProjects = this.state.pinnedProjects;
    pinnedProjects.forEach(project => {
      projectIds.push(project.docId);
    });

    var unpinnedProjects = this.state.unpinnedProjects;
    unpinnedProjects.forEach(project => {
      projectIds.push(project.docId);
    });

    console.log('projectIds', projectIds);

    var updates = await MongoProjectUpdateEngine.getByProjectIds(projectIds);
    console.log('updates', updates);
    this.setState({
      updates: updates,
      loading: false,
    });
  }

  refreshHearts = async () => {
    // // available hearts
    // var availableHearts = [];
    // if (this.props.signedUser != null) {
    //   /*
    //   var cashbackAmount = await EnactonSaleEngine.getCashbackAmountByUserId(this.props.signedUser.enactonId);
    //   var earnedHeartCount = await EnactonUserHeartEngine.getTotalHeartsByUserId(this.props.signedUser.enactonId, -1, 0, 0);
    //   var pledgedHeartCount = await FirebasePledgesEngine.getTotalAmountByUserDocId(this.props.signedUser.docId);
    //   var userHeartCount = parseInt(cashbackAmount / HEART_VALUE) + parseInt(earnedHeartCount) - parseInt(pledgedHeartCount);
    //   */

    //   var userHeartCount = 0;
    //   if (this.props.signedUser != null) {
    //     userHeartCount = MongoUserEngine.getAvailableHeartCount(this.props.signedUser);
    //   }

    //   var heartCountList = [10, 20, 30, 40, 50, 75, 100, 150, 200];
    //   heartCountList.forEach(heartCount => {
    //     var availableHeart = {
    //       count: heartCount,
    //       available: userHeartCount < heartCount ? false : true,
    //     };

    //     availableHearts.push(availableHeart);
    //   });
    // }

    // this.setState({
    //   showActionComment: false,
    //   availableHearts: availableHearts,
    //   targetProject: null,
    //   targetPost: null,
    //   targetComment: null,
    //   commentUpdatedCallback: null,
    //   commentText: "",
    //   commentHeartCount: 0,
    // });
  }

  refresh = async () => {
    try {
      // load user
      const user = await MongoUserEngine.getByUsername(this.state.userId);
      console.log('user', user);
      const enviromateCount = user.enviromateCount;
      const followerCount = user.followerCount;


      // load user info
      const userInfo = await MongoUserInfoEngine.getByUserDocId(user.docId);
      console.log('userInfo', userInfo);
      
      // this needs current user info
      //var isFollowee = await MongoFollowEngine.isFollower(this.props.signedUser.docId, user.docId);
      //var isEnviromate = await MongoEnviromateEngine.isEnviromate(this.props.signedUser.docId, user.docId);
 
      this.setState({
        user: user,
        name: MongoUserEngine.getFriendlyName(user),
        userinfo: userInfo,
        enviromateCount: enviromateCount,
        followerCount: followerCount,

        isFollowee: false,
        isEnviromate: false,
        loading: false,
      }, async () => {
        await this.refreshPledges();
        await this.refreshProjects();
        await this.refreshEnviromatesProjects();
        await this.refreshUpdates();
        await this.refreshHearts();
      });
    } catch(err) {
      console.log("[UserProfile]->refresh() error:", err);
    }
  }

  onWindowResized = () => {
    var screenWidth = window.innerWidth;
    this.setState({
      screenWidth: screenWidth,
    });
  }

  onPressFollow = () => {

  }

  onPressAddEnviromate = () => {

  }

  handleTabChange = (e, newValue) => {
    this.setState({
      tab: newValue,
    });

  }

  onPressAction = () => {
    Popup.alert('This feature is only supported on mobile app only. Please download the app to use it.', 'Notice' );
  }

  renderUserLogo = () => {
    if (this.state.user && this.state.user.logoUrl != "") {
      return (
        <Avatar src={this.state.user.logoUrl} className="userLogo" sx={{ width: 80, height: 80 }} />
      );
    }

    return (
      <FontAwesomeIcon icon={ faUserCircle } size="4x" className="userLogoEmpty" />
    );
  }

  onProjectPledged = (project) => {

  }

  onProjectDeleted = (project) => {

  }

  onPressProjectCommenting = (project) => {

  }

  onProjectPost = () => {

  }

  onProjectProfile = () => {

  }

  

  renderProject = (project, index, userBlockWidth) => {
    var fundedPercent = MongoProjectEngine.getFundedPercentWithDecimals(project, 1);
    return (
      <div className="bodyTabPanelContentSectionPanel">
        <ProjectPanel
          navigation={this.props.navigation}
          onCommenting={this.onPressProjectCommenting}
          onDeleted={this.onProjectDeleted}
          onPledged={(project) => this.onProjectPledged(project)}
          onPost={this.onProjectPost}
          onProfile={this.onProjectProfile}
          overlay={true}
          project={project}
          ref={ref => (this.projectPanels[index] = ref)}
          //signedUser={this.props.signedUser}
          user={this.state.user}
          fundedPercent={fundedPercent}
          width={userBlockWidth}
          onPressAction={()=>this.onPressAction()}
        />
      </div>
    );
  }

  renderTabProjects = (userBlockWidth) => {
    return (
      <div className="bodyTapPanel" style={{maxHeight: this.state.tab == "projects" ? TAB_HEIGHT : 0, minHeight: TAB_HEIGHT, width: userBlockWidth}}>
        {(this.state.pinnedProjects.length + this.state.unpinnedProjects.length) == 0 ? (
          <div className="bodyTabPanelContentEmpty">
            <div className="bodyTabPanelContentEmptyTitle">No projects yet</div>
          </div>
        ): (
          <div className="bodyTabPanelContent">
            {/** pinned projects */}
            {this.state.pinnedProjects.length > 0 && (
              <div className="bodyTabPanelContentSection">
                <div className="bodyTabPanelContentSectionHeader">
                  <p className="bodyTabPanelContentSectionHeaderTitle">{this.state.name} Pinned</p>
                  <SlPin size="Medium" color="black" style={{marginLeft: 20}} />
                </div>
              </div>              
            )}
            {this.state.pinnedProjects.map((project, index)=>(
              this.renderProject(project, index, userBlockWidth)
            ))}
            {/** unpinned projects */}
            {this.state.unpinnedProjects.length > 0 && (
              <div className="bodyTabPanelContentSection">
                <div className="bodyTabPanelContentSectionHeader">
                  <p className="bodyTabPanelContentSectionHeaderTitle">{this.state.name} Projects</p>
                </div>
              </div>              
            )}
            {this.state.unpinnedProjects.map((project, index)=>(
              this.renderProject(project, index, userBlockWidth)
            ))}
          </div>
        )}
      </div>
    );

  }

  renderTabEnviromates = (userBlockWidth) => {
    var user = this.state.user;
    return (
      <div className="bodyTapPanel" style={{maxHeight: this.state.tab == "enviromates" ? TAB_HEIGHT : 0, minHeight: TAB_HEIGHT, width: userBlockWidth}}>
        {(this.state.enviromatesPinnedProjects.length + this.state.enviromateInvitedProjects.length) == 0 ? (
          <div className="bodyTabPanelContentEmpty">
            <div className="bodyTabPanelContentEmptyTitle">No Enviromates projects yet</div>
          </div>
        ): (
          <div className="bodyTabPanelContent">
            { this.state.enviromatesPinnedProjects.length > 0 &&
              <div className="bodyTabPanelContentSection">
                <div className="bodyTabPanelContentSectionHeader">
                  <span className="bodyTabPanelContentSectionHeaderTitle">{MongoUserEngine.getFriendlyUserName(user) + " pinned"}</span>
                  <SlPin size={18} style={{color: 'black', marginLeft: 20}} />
                </div>
                {this.state.enviromatesPinnedProjects.map((project, index) => this.renderEnviromateProject(project, index, userBlockWidth))}
              </div>
            }
            { this.state.enviromateInvitedProjects.length > 0 &&
              <div className="bodyTabPanelContentSection">
                <div className="bodyTabPanelContentSectionHeader">
                  <span className="bodyTabPanelContentSectionHeaderTitle">{MongoUserEngine.getFriendlyUserName(user) + "'s invited projects"}</span>
                  <FaUnlink size={18} style={{color: 'black', marginLeft: 20}}/>
                </div>
                {this.state.enviromateInvitedProjects.map((project, index) => this.renderEnviromateProject(project, index, userBlockWidth))}
              </div>
            }
            { this.state.enviromatesUnpinnedProjects.length > 0 &&
              <div className="bodyTabPanelContentSection">
                <div className="bodyTabPanelContentSectionHeader">
                  <span className="bodyTabPanelContentSectionHeaderTitle">{MongoUserEngine.getFriendlyUserName(user) + "'s Enviromates projects"}</span>
                  <FaUnlink size={18} style={{color: 'black', marginLeft: 20}}/>
                </div>
                {this.state.enviromatesUnpinnedProjects.map((project, index) => this.renderEnviromateProject(project, index, userBlockWidth))}
              </div>
            }
          </div>
        )}
        
      </div>
    );
  }

  renderEnviromateProject = (project, index, userBlockWidth) => {
    var fundedPercent = MongoProjectEngine.getFundedPercentWithDecimals(project, 1);
    return (
      <div className="bodyTabPanelContentSectionPanel">
        <ProjectPanel
          navigation={this.props.navigation}
          //onClone={this.onProjectClone}
          onCommenting={this.onPressProjectCommenting}
          onDeleted={this.onProjectDeleted}
          onPin={this.onProjectPin}
          onPinned={(project, pinned) => this.onEnviromateProjectPinned(project, pinned)}
          onPledged={(project) => this.onProjectPledged(project)}
          onPost={this.onProjectPost}
          onProfile={this.onProjectProfile}
          onUnfollow={this.onProjectUnfollow}
          overlay={true}
          project={project}
          ref={ref => (this.enviromateProjectPanels[index] = ref)}
          //signedUser={this.props.signedUser}
          fundedPercent={fundedPercent}
          width={userBlockWidth}
          onPressAction={()=>this.onPressAction()}
        />
      </div>
    );
  }


  renderTabUpdates = (userBlockWidth) => {
    return (
      <div className="bodyTapPanel" style={{maxHeight: this.state.tab == "updates" ? TAB_HEIGHT : 0, minHeight: TAB_HEIGHT, width: userBlockWidth}}>
        { this.state.updates.length == 0 ? (
          <div className="bodyTabPanelContentEmpty">
            <span className="bodyTabPanelContentEmptyTitle">No updates yet</span>
          </div>
        ) : (
          <div className="bodyTabPanelContent">
            {this.state.updates.map((post, index) => this.renderPost(post, userBlockWidth))}
          </div>
        )}
      </div>
    );
  }

  renderPost = (post, userBlockWidth) => {
    return (
      <div className="bodyTabPanelContentSectionPanel">
        <ProjectPostPanel
          availableHearts={this.state.availableHearts}
          navigation={this.props.navigation}
          onDeleted={(post) => this.onPostDeleted(post)}
          onPledged={(project) => this.onProjectPledged(project)}
          post={post}

          //signedUser={this.props.signedUser}
          width={userBlockWidth}
        />
      </div>
    );
  }



  renderMobile = () => {
    // body size
    var screenWidth = this.state.screenWidth - this.props.scrollbarWidth;
    var marginTop = BACKGROUND_MARGINTOP / (BACKGROUND_SIZE.width / this.state.screenWidth);
    var screenHeight = MOBILE_SCREEN_HEIGHT * (screenWidth / MOBILE_SCREEN_WIDTH);
    screenHeight = MOBILE_SCREEN_HEIGHT / MOBILE_SCREEN_ASPECTRATIO; //screenWidth / SCREEN_ASPECTRATIO - marginTop;

    var userBlockWidth = screenWidth - 60;

    // body margin
    // var bodyMarginHorizontal = (screenWidth - VIDEO_WIDTH * 4) / 2;

    return (
      <div className="App page">
        <Header
          backgroundImage={backgroundImage}
          backgroundMarginTop={marginTop}
          screen="home"
          isMobile={true}
          screenSize={{
            height: screenWidth / SCREEN_ASPECTRATIO,
            width: screenWidth,
          }}
        />
        <div
          className="body"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundPosition: "bottom",
            backgroundSize: "cover",
            height: screenHeight,
            width: screenWidth,
          }}
        >
          <div className="mobileHomeTitle">
            <div className="mobileHomeTitleText" style={{fontSize:80, lineHeight:0.7}}>Join the</div>
            <div className="mobileHomeTitleText">revolution!</div>
            <img className="mobileHomeBeeLeft" src={beeRightImage}/>
            <img className="mobileHomeBeeRight" src={beeLeftImage}/>
          </div>
          <AppStoreButton isMobile={true} style={{marginTop: 40}}/>
          <GoogleStoreButton isMobile={true} style={{marginTop: 15}}/>

          <div className="mobileBodyContent" style={{width: "100%"}}>
            {/** user block  */}
            <div className="userBlock" style={{width: "100%"}}>
              {/** user header */}
              <div className="userStatusBlock">
                {this.renderUserLogo()}
                <div className="bodyInfoSummary">
                  {/** summary enviromates count */}
                  <div className="bodyInfoSummaryItem">
                    <p className="bodyInfoSummaryItemValue">
                      {this.state.enviromateCount}
                    </p>
                    <p className="bodyInfoSummaryItemTitle">ENVIROMATES</p>
                  </div>
                  {/** summary enviromates count end */}
                  {/** summary followers count */}
                  <div className="bodyInfoSummaryItem">
                    <p className="bodyInfoSummaryItemValue">
                      {this.state.followerCount}
                    </p>
                    <p className="bodyInfoSummaryItemTitle">FOLLOWERS</p>
                  </div>
                  {/** summary followers count end */}
                  {/** summary pledges count */}
                  <div className="bodyInfoSummaryItem">
                    <p className="bodyInfoSummaryItemValue">
                      {this.state.pledgeCount}
                    </p>
                    <p className="bodyInfoSummaryItemTitle">PLEDGES</p>
                  </div>
                  {/** summary pledges count end */}
                  {/** summary projects count */}
                  <div className="bodyInfoSummaryItem">
                    <p className="bodyInfoSummaryItemValue">
                      {this.state.projectCount}
                    </p>
                    <p className="bodyInfoSummaryItemTitle">PROJECTS</p>
                  </div>
                  {/** summary pledges count end */}
                </div>
              </div>
              {/** user header end */}
              {/** user intro */}
              <div className="userIntroBlock">
                <h4 className='bodyIntroName'>{this.state.name}</h4>
                <p className="bodyIntroComment">
                  {this.state.user?this.state.user.intro: ''}
                </p>
              </div>
              {/** user intro end */}
              {/** body menu */}
              <div className='bodyMenu'>
                <div className='bodyMenuSocial'>
                  <FontAwesomeIcon icon={ faInstagram } size="1x" className="bodyMenuShare" />
                  <FontAwesomeIcon icon={ faTwitter } size="1x" className="bodyMenuShare" />
                  <FontAwesomeIcon icon={ faYoutube } size="1x" className="bodyMenuShare" />
                  <FontAwesomeIcon icon={ faFacebookF } size="1x" className="bodyMenuShare" />
                </div>
                <div className='bodyMenuActions'>
                  <div className="bodyMenuFollow">
                    <Link to="" className="bodyMenuFollowText" onClick={this.onPressFollow}>Follow</Link>
                  </div>
                  <div className="bodyMenuAddEnviromates">
                    <Link to="" className="bodyMenuAddEnviromateText" onClick={this.onPressAddEnviromate}>Add Enviromate</Link>
                  </div>
                </div>
              </div>
              {/** body menu end */}
              <TabContext value={this.state.tab}>
                {/** Tab menu */}
                <TabList
                  onChange={this.handleTabChange}
                  variant="fullWidth"
                  textColor="primary"
                  indicatorColor="primary"
                >
                  <Tab value="projects" label="Projects" />
                  <Tab value="enviromates" label="Enviromates projects" wrapped="false" />
                  <Tab value="updates" label="Updates" />
                </TabList>
                {/** Tab menu end */}
                {/** Tab panel */}
                <TabPanel value="projects" sx={{ p: 0 }}>
                  {this.renderTabProjects(userBlockWidth)}
                </TabPanel>
                <TabPanel value="enviromates" sx={{ p: 0 }}>
                  {this.renderTabEnviromates(userBlockWidth)}
                </TabPanel>
                <TabPanel value="updates" sx={{ p: 0 }}>
                  {this.renderTabUpdates(userBlockWidth)}
                </TabPanel>
                {/** Tab panel end */}
              </TabContext>
            </div>
            {/** user block end */}
          </div>
          <div className="seperator"/>
          <Footer isMobile={true}/>
        </div>
      </div>
    );
    
    /* var screenWidth = this.state.screenWidth - this.props.scrollbarWidth;
    var marginTop = BACKGROUND_MARGINTOP / (BACKGROUND_SIZE.width / this.state.screenWidth);
    var screenHeight = MOBILE_SCREEN_HEIGHT * (screenWidth / MOBILE_SCREEN_WIDTH);
    screenHeight = MOBILE_SCREEN_HEIGHT / MOBILE_SCREEN_ASPECTRATIO; //screenWidth / SCREEN_ASPECTRATIO - marginTop;

    // video width
    var videoWidth = screenWidth - 48;

    // body margin
    // var bodyMarginHorizontal = (screenWidth - VIDEO_WIDTH * 4) / 2;

    return (
      <div className="App page">
        <Header
          // backgroundImage={backgroundImage}
          // backgroundMarginTop={marginTop}
          isMobile={true}
          screen="home"
          screenSize={{
            height: screenWidth / SCREEN_ASPECTRATIO,
            width: screenWidth,
          }}
        />
        <div
          className="body"
          style={{
            backgroundColor: "rgb(0, 95, 176)",
            backgroundImage: `url(${backgroundImage})`,
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            height: screenHeight,
            width: screenWidth,
          }}
        >
          <div className="mobileHomeTitle">
            <div className="mobileHomeTitleText" style={{fontSize:80, lineHeight:0.7}}>Join the</div>
            <div className="mobileHomeTitleText">revolution!</div>
            <img className="mobileHomeBeeLeft" src={beeRightImage}/>
            <img className="mobileHomeBeeRight" src={beeLeftImage}/>
          </div>
          <AppStoreButton isMobile={true} style={{marginTop: 40}}/>
          <GoogleStoreButton isMobile={true} style={{marginTop: 15}}/>
          <div className="bodyContentLeft" style={{marginTop: 40}}>
            <Player
              autoPlay={true}
              className="bodyVideo"
              fluid={false}
              height={videoWidth / (607 / 1080)}
              muted={false}
              playsInline={true}
              ref={player => {this.player = player}}
              src={VIDEO_URL}
              width={videoWidth}
            >
              <BigPlayButton className="bodyVideoBigButton" position="center"/>
              <ControlBar autoHide={true} className="bodyVideoControlBar"/>
            </Player>
          </div>
          <div className="seperator"/>
          <Footer isMobile={true}/>
        </div>
      </div>
    ); */
  }

  render = () => {
    if (this.state.screenWidth < 768) {
      return this.renderMobile();
    }

    // body size
    var screenWidth = this.state.screenWidth - this.props.scrollbarWidth;
    var marginTop = BACKGROUND_MARGINTOP / (BACKGROUND_SIZE.width / this.state.screenWidth);
    var screenHeight = screenWidth / SCREEN_ASPECTRATIO - marginTop;

    var userBlockWidth = 480;

    // body margin
    // var bodyMarginHorizontal = (screenWidth - VIDEO_WIDTH * 4) / 2;

    return (
      <div className="App page">
        <Header
          backgroundImage={backgroundImage}
          backgroundMarginTop={marginTop}
          screen="home"
          screenSize={{
            height: screenWidth / SCREEN_ASPECTRATIO,
            width: screenWidth,
          }}
        />
        <div
          className="body"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundPosition: "bottom",
            backgroundSize: "cover",
            height: screenHeight,
            width: screenWidth,
          }}
        >
          <div className="bodyContent" style={{width: "100%"}}>
            {/** user block  */}
            <div className="userBlock">
              {/** user header */}
              <div className="userStatusBlock">
                {this.renderUserLogo()}
                <div className="bodyInfoSummary">
                  {/** summary enviromates count */}
                  <div className="bodyInfoSummaryItem">
                    <p className="bodyInfoSummaryItemValue">
                      {this.state.enviromateCount}
                    </p>
                    <p className="bodyInfoSummaryItemTitle">ENVIROMATES</p>
                  </div>
                  {/** summary enviromates count end */}
                  {/** summary followers count */}
                  <div className="bodyInfoSummaryItem">
                    <p className="bodyInfoSummaryItemValue">
                      {this.state.followerCount}
                    </p>
                    <p className="bodyInfoSummaryItemTitle">FOLLOWERS</p>
                  </div>
                  {/** summary followers count end */}
                  {/** summary pledges count */}
                  <div className="bodyInfoSummaryItem">
                    <p className="bodyInfoSummaryItemValue">
                      {this.state.pledgeCount}
                    </p>
                    <p className="bodyInfoSummaryItemTitle">PLEDGES</p>
                  </div>
                  {/** summary pledges count end */}
                  {/** summary projects count */}
                  <div className="bodyInfoSummaryItem">
                    <p className="bodyInfoSummaryItemValue">
                      {this.state.projectCount}
                    </p>
                    <p className="bodyInfoSummaryItemTitle">PROJECTS</p>
                  </div>
                  {/** summary pledges count end */}
                </div>
              </div>
              {/** user header end */}
              {/** user intro */}
              <div className="userIntroBlock">
                <h4 className='bodyIntroName'>{this.state.name}</h4>
                <p className="bodyIntroComment">
                  {this.state.user?this.state.user.intro: ''}
                </p>
              </div>
              {/** user intro end */}
              {/** body menu */}
              <div className='bodyMenu'>
                <div className='bodyMenuSocial'>
                  <FontAwesomeIcon icon={ faInstagram } size="1x" className="bodyMenuShare" />
                  <FontAwesomeIcon icon={ faTwitter } size="1x" className="bodyMenuShare" />
                  <FontAwesomeIcon icon={ faYoutube } size="1x" className="bodyMenuShare" />
                  <FontAwesomeIcon icon={ faFacebookF } size="1x" className="bodyMenuShare" />
                </div>
                <div className='bodyMenuActions'>
                  <div className="bodyMenuFollow">
                    <Link to="" className="bodyMenuFollowText" onClick={this.onPressFollow}>Follow</Link>
                  </div>
                  <div className="bodyMenuAddEnviromates">
                    <Link to="" className="bodyMenuAddEnviromateText" onClick={this.onPressAddEnviromate}>Add Enviromate</Link>
                  </div>
                </div>
              </div>
              {/** body menu end */}
              <TabContext value={this.state.tab}>
                {/** Tab menu */}
                <TabList
                  onChange={this.handleTabChange}
                  variant="fullWidth"
                  textColor="primary"
                  indicatorColor="primary"
                >
                  <Tab value="projects" label="Projects" />
                  <Tab value="enviromates" label="Enviromates projects" wrapped="false" />
                  <Tab value="updates" label="Updates" />
                </TabList>
                {/** Tab menu end */}
                {/** Tab panel */}
                <TabPanel value="projects" sx={{ p: 0 }}>
                  {this.renderTabProjects(userBlockWidth)}
                </TabPanel>
                <TabPanel value="enviromates" sx={{ p: 0 }}>
                  {this.renderTabEnviromates(userBlockWidth)}
                </TabPanel>
                <TabPanel value="updates" sx={{ p: 0 }}>
                  {this.renderTabUpdates(userBlockWidth)}
                </TabPanel>
                {/** Tab panel end */}
              </TabContext>
            </div>
            {/** user block end */}
            <div className="bodyStore">
              <div className="bodyStoreTitle">
                <p className="bodyStoreTitleText">Join the</p>
                <p className="bodyStoreTitleBottomText">revolution!</p>
                <img className="bodyStoreTitleBeeRight" src={beeRightImage}/>
                <img className="bodyStoreTitleBeeLeft" src={beeLeftImage}/>
              </div>
              <div className="bodyStoreContent">
                <div className="bodyStoreContentButtons">
                  <AppStoreButton/>
                  <GoogleStoreButton/>
                </div>
                <div className="bodyStoreContentBarcode">
                  <img className="bodyStoreContentBarcodeImage" src={qrcodeImage}/>
                </div>
              </div>
            </div>
          </div>
          <div className="seperator"/>
          <Footer/>
        </div>
        <Popup />
      </div>
    );
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      userId : nextProps.params.userId ? nextProps.params.userId.replaceAll('-', ' ') : '',
    }
  }
}

/* This is a higher order component that 
*  inject a special prop   to our component.
*/ 
function withRouter(Component) {
    function ComponentWithRouter(props) {
        let params = useParams()
        return <Component {...props} params={params} />
    }
    return ComponentWithRouter
}
const UserProfileWrapper = withRouter(UserProfile);

export default UserProfileWrapper;
