import { ObjectId } from 'bson';

import MongoCallback from './callback';
import MongoEngine from '.';
import MongoFunding from './funding';
import MongoFundingEngine from './fundings';
import MongoMediaEngine from './medias';
import MongoProjectEngine from './projects';
import MongoProjectUpdate from './projectupdate';
import MongoProjectUpdateCommentEngine from './projectupdatecomments';
import MongoProjectUpdateLikeEngine from './projectupdatelikes';
import MongoProjectUpdatePledge from './projectupdatepledge';
import MongoProjectUpdatePledgeEngine from './projectupdatepledges';
import MongoProjectUpdateRead from './projectupdateread';
import MongoProjectUpdateReadEngine from './projectupdatereads';
import MongoTeam from './team';
import MongoUserEngine from './users';

import { HEART_VALUE } from '../constants/globalconstants';

export default class MongoProjectUpdateEngine {
  static changeListeners = [];

  static async add(update) {
    try {
      var db = await MongoEngine.getDB();
      var result = await db.collection("ProjectUpdate").insertOne(update);
      update._id = result.insertedId;
    } catch(error) {
      console.log("[MongoProjectUpdateEngine] add() -> error: ", error);
    }
  }

  static async addComment(update, comment) {
    try {
      await MongoProjectUpdateCommentEngine.add(comment);
      var commentCount = await MongoProjectUpdateCommentEngine.getCountByUpdateId(update.docId);
      update.commentCount = commentCount;
      await MongoProjectUpdateEngine.update(update);
    } catch(error) {
      console.log("[MongoProjectUpdateEngine] add() -> error: ", error);
    }
  }

  static async delete(update) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdate");
      var filter = {
        docId: update.docId,
      };
      var result = await collection.deleteOne(filter);

      // related
      await MongoProjectUpdateLikeEngine.deleteByUpdateId(update.docId);
      await MongoProjectUpdateReadEngine.deleteByUpdateId(update.docId);
      await MongoProjectUpdateCommentEngine.deleteByUpdateId(update.docId);
    } catch(error) {
      console.error("[MongoProjectUpdateEngine] delete() -> error: ", error);
    }
  }

  static async deleteByProjectId(projectId) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdate");
      var filter = {
        projectId: projectId,
      };
      var result = await collection.deleteMany(filter);
    } catch(error) {
      console.log("[MongoProjectUpdateEngine] deleteByProjectId() -> error: ", error);
    }
  }

  static async deleteAll() {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdate");
      var result = await collection.deleteMany();
    } catch(error) {
      console.log("[MongoProjectUpdateEngine] deleteAll() -> error: ", error);
    }
  }

  static async deleteComment(update, commentId) {
    try {
      await MongoProjectUpdateCommentEngine.delete(commentId);
      var commentCount = await MongoProjectUpdateCommentEngine.getCountByUpdateId(update.docId);
      update.commentCount = commentCount;
      await MongoProjectUpdateEngine.update(update);
    } catch(error) {
      console.log("[MongoProjectUpdateEngine] deleteComment() -> error: ", error);
    }
  }

  static fromJson(json) {
    var update = new MongoProjectUpdate();
    try {
      if (json._id != undefined) {
        update._id = new ObjectId(json._id);
      }
      if (json.docId != undefined && json.docId != null && json.docId != "") {
        update.docId = json.docId;
      } else {
        update.docId = update._id.valueOf().toString();
      }

      if (json.projectId != undefined) {
        update.projectId = json.projectId;
      }
      if (json.projectCreatorId != undefined) {
        update.projectCreatorId = json.projectCreatorId;
      }
      if (json.projectTitle != undefined) {
        update.projectTitle = json.projectTitle;
      }
      if (json.projectLogoUrl != undefined) {
        update.projectLogoUrl = json.projectLogoUrl;
      }

      if (json.creatorId != undefined) {
        update.creatorId = json.creatorId;
      }
      if (json.creatorName != undefined) {
        update.creatorName = json.creatorName;
      }
      if (json.creatorLogoUrl != undefined) {
        update.creatorLogoUrl = json.creatorLogoUrl;
      }

      if (json.medias != undefined && json.medias != null) {
        json.medias.forEach(media => {
          try {
            var mongoMedia = MongoMediaEngine.fromJson(media);
            update.medias.push(mongoMedia);
          } catch(error) {
            console.log("[MongoProjectUpdateEngine] fromJson() -> error: ", error);
          }
        });
      }
      if (json.comment != undefined) {
        update.comment = json.comment;
      }

      if (json.additionalFunding != undefined) {
        try {
          var funding = MongoFundingEngine.fromJson(json.additionalFunding);
          update.additionalFunding = funding;
        } catch(error) {
          console.log("[MongoProjectUpdateEngine] fromJson() -> error: ", error);
        }
      }

      if (json.commentCount != undefined) {
        update.commentCount = json.commentCount;
      }
      if (json.likeCount != undefined) {
        update.likeCount = json.likeCount;
      }
      if (json.readCount != undefined) {
        update.readCount = json.readCount;
      }

      if (json.timestamp != undefined) {
        update.timestamp = json.timestamp;
      }
    } catch(error) {
      console.log("[MongoProjectUpdateEngine] fromJson() -> error: ", error);
    }

    return update;
  }

  static getByDocId = async (docId) => {
    var update = null;
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdate");
      var filter = {
        docId: docId,
      };

      var mongoProjectUpdates = await collection.find(filter);
      if (mongoProjectUpdates.length == 0) {
        return null;
      }

      update = MongoProjectUpdateEngine.fromJson(mongoProjectUpdates[0]);
    } catch(error) {
      console.log("[MongoProjectUpdateEngine] getByDocId() -> error: ", error);
    }

    return update;
  }

  static async getByProjectId(projectId) {
    var updates = [];
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdate");
      var filter = {
        projectId: projectId,
      };
      var sort = {
        timestamp: -1,
      };

      var mongoUpdates = await collection.find(filter, { sort });
      mongoUpdates.forEach(mongoUpdate => {
        var update = MongoProjectUpdateEngine.fromJson(mongoUpdate);
        updates.push(update);
      });
    } catch(error) {
      console.log("[MongoProjectUpdateEngine] getByProjectId() -> error: ", error);
    }

    return updates;
  }

  static async getByProjectIds(projectIds) {
    var updates = [];
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdate");
      var filter = {
        projectId: {
          $in: projectIds,
        },
      };
      var sort = {
        timestamp: -1,
      };

      var mongoUpdates = await collection.find(filter, { sort });
      mongoUpdates.forEach(mongoUpdate => {
        var update = MongoProjectUpdateEngine.fromJson(mongoUpdate);
        updates.push(update);
      });
    } catch(error) {
      console.log("[MongoProjectUpdateEngine] getByProjectIds() -> error: ", error);
    }

    return updates;
  }

  static async getByUserId(userId) {
    var updates = [];
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdate");
      var aggregate = [
        {
          $lookup: {
            from: "Project",
            localField: "projectId",
            foreignField: "docId",
            as: "projects"
          }
        },
        {
          $match: {
            $or: [
              { "projects.creatorId": userId },
              {
                "projects.members.memberId": userId ,
                "projects.members.type": { $ne: MongoTeam.TYPE_INVITED },
              }
            ],
          }
        },
        {
          $sort: {
            timestamp: -1,
          }
        },
      ];

      var mongoUpdates = await collection.aggregate(aggregate);
      mongoUpdates.forEach(mongoUpdate => {
        var update = MongoProjectUpdateEngine.fromJson(mongoUpdate);
        updates.push(update);
      });
    } catch(error) {
      console.log("[MongoProjectUpdateEngine] getByProjectIds() -> error: ", error);
    }

    return updates;
  }

  static async getCountByProjectId(projectId) {
    var count = 0;
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdate");
      var filter = {
        projectId: projectId,
      };
      count = await collection.count(filter); 
    } catch(error) {
      console.log("[MongoProjectUpdateEngine] getCountByProjectId() -> error: ", error);
    }

    return count;
  }

  static getLogoImage(update) {
    if (!update.medias || update.medias.length == 0) {
      return null;
    }

    var logo = null;
    update.medias.forEach(media => {
      if (logo == null && media.type == "image") {
        logo = media;
      }
    });

    if (logo == null) {
      logo = update.medias[0];
    }

    return logo;
  }

  static getUnreadCountByUserId = async (userId) => {
    var count = 0;
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdate");
      var aggregate = [
        {
          $lookup: {
            from: "Project",
            localField: "projectId",
            foreignField: "docId",
            as: "projects"
          }
        },
        {
          $lookup: {
            from: "ProjectUpdateRead",
            localField: "docId",
            foreignField: "updateId",
            as: "reads"
          }
        },
        {
          $match: {
            $or: [
              { "projects.creatorId": userId },
              {
                "projects.members.memberId": userId ,
                "projects.members.type": { $ne: MongoTeam.TYPE_INVITED },
              }
            ],
            "reads.readerIds": {$ne: userId}
          }
        },
        {
          $count: "count"
        },
      ];

      var ret = await collection.aggregate(aggregate);
      if (ret.length == 0) {
        return 0;
      }

      count = ret[0].count;
    } catch(error) {
      console.log("[MongoProjectUpdateEngine] getUnreadCountByUserId() -> error: ", error);
    }

    return count;
  }

  static async read(update, readerId) {
    if (readerId == null || readerId == undefined) {
      return;
    }

    try {
      var read = await MongoProjectUpdateReadEngine.getByUpdateId(update.docId);
      if (read == null) {
        read = new MongoProjectUpdateRead();
        read.projectId = update.projectId;
        read.updateId = update.docId;

        await MongoProjectUpdateReadEngine.add(read);
      }

      await MongoProjectUpdateReadEngine.read(read, readerId);
    } catch(error) {
      console.log("[MongoProjectUpdateEngine] read() -> error: " + error.message);
    }
  }

  static registerChangeListener = (callback) => {
    try {
      var mongoCallback = new MongoCallback();
      mongoCallback.callback = callback;
      this.changeListeners.push(mongoCallback);
    } catch(error) {
      console.error("[MongoProjectUpdateEngine] registerChangeListener() -> error: ", error.message);
    }
  }

  static unregisterChangeListenerByListenerId = (callbackId) => {
    try {
      var index = this.changeListeners.find(callback => callback.id == callbackId);
      if (index < 0) {
        return;
      }

      this.changeListeners.splice(index, 1);
    } catch(error) {
      console.error("[MongoProjectUpdateEngine] unregisterChangeListenerByListenerId() -> error: ", error.message);
    }
  }

  static async update(update) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdate");
      var result = await collection.updateOne(
        { _id: update._id },
        { $set: update },
        { upsert: true },
      );
    } catch(error) {
      console.log("[MongoProjectUpdateEngine] update() -> error: ", error);
    }
  }

  static watchForChanges = async () => {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdate");
      var changeStream = collection.watch();
      for await (var change of changeStream) {
        this.changeListeners.forEach(listener => {
          try {
            listener.callback();
          } catch(error) {}
        });
      }
    } catch(error) {
      console.error("[MongoProjectUpdateEngine] watchForChanges() -> error: ", error.message);
    }
  }

  static getFundedPercentWithDecimals(update, decimals) {
    var percent = 0;
    try {
      var decimalDigit = 10 * decimals;
      var funding = update.additionalFunding;
      if (funding != null && funding.target > 0) {
        percent = Math.floor(((Number(funding.raised) + Number(funding.funded)) / Number(funding.target) * 100 * decimalDigit)) / decimalDigit;
      }
    } catch(error) {
      console.error("[MongoProjectEngine] getFundedPercentWithDecimals() -> error: ", error.message);
    }

    return percent;
  }

  static getLogoVideo(update) {
    if (!update.medias || update.medias.length == 0) {
      return null;
    }

    var logo = null;
    update.medias.forEach(media => {
      if (logo == null && media.type == "video") {
        logo = media;
      }
    });

    if (logo == null) {
      logo = update.medias[0];
    }

    return logo;
  }

  static async fund(update, mongoPledge) {
    try {
      var mongoFunding = update.additionalFunding;
      if (mongoFunding == null) {
        mongoFunding = new MongoFunding();
      }

      var amountInCents = mongoPledge.amount;
      if (mongoPledge.type == MongoProjectUpdatePledge.TYPE_HEARTS) {
        amountInCents = mongoPledge.amount / HEART_VALUE;
      }
      mongoFunding.funded += amountInCents;
      update.additionalFunding = mongoFunding;

      // update supporter count of update
      var pledgeCount = await MongoProjectUpdatePledgeEngine.getCountByUpdateIdAndUserId(update.docId, mongoPledge.pledgerId);
      if (pledgeCount == 1) {
        // update.supporterCount++;
      }

      await MongoProjectUpdateEngine.update(update);

      // notification
      var fundedAmount = mongoFunding.raised + mongoFunding.funded;
      if (fundedAmount >= mongoFunding.target) { // update funded over 100 percents
        // MongoNotificationEngine.addProjectUpdateFunded(update);
      } else {
        // MongoNotificationEngine.addProjectUpdatePledged(update, mongoPledge);
      }
    } catch(error) {
      console.log("[MongoProjectUpateEngine] fund() -> error: " + error.message);
    }
  }
}
