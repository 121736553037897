import { ObjectId } from 'bson';

export default class MongoProjectUpdateRead {
  _id = null;

  projectId = "";
  updateId = "";
  readerIds = [];

  constructor() {
    this._id = new ObjectId();
  }
}
