import MongoNotificationProjectInvitation from "./notificationprojectinvitation";
import MongoUserEngine from "./users";

export default class MongoNotificationProjectInvitationEngine {
  static fromJson(json) {
    var notification = new MongoNotificationProjectInvitation();
    try {
      if (json.projectId != undefined) {
        notification.projectId = json.projectId;
      }
      if (json.projectTitle != undefined) {
        notification.projectTitle = json.projectTitle;
      }
      if (json.invitorId != undefined) {
        notification.invitorId = json.invitorId;
      }
      if (json.invitorName != undefined) {
        notification.invitorName = json.invitorName;
      }
      if (json.invitorLogoUrl != undefined) {
        notification.invitorLogoUrl = json.invitorLogoUrl;
      }
    } catch(error) {
      console.log("[MongoNotificationProjectInvitationEngine] fromJson() -> error: ", error);
    }

    return notification;
  }

  static generateByProjectAndInvitor(project, invitor) {
    var invitation = new MongoNotificationProjectInvitation();
    invitation.projectId = project.docId;
    invitation.projectTitle = project.title;
    invitation.invitorId = invitor.docId;
    invitation.invitorName = MongoUserEngine.getFriendlyUserName(invitor);
    invitation.invitorLogoUrl = invitor.logoUrl;

    return invitation;
  }
}
