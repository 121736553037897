import { ObjectId } from 'bson';

import MongoEngine from ".";
import MongoProjectPledge from "./projectpledge";
import MongoProjectPledgeEngine from "./projectpledges";
import MongoProjectUpdateComment from "./projectupdatecomment";
import MongoProjectUpdateCommentLikeEngine from "./projectupdatecommentlikes";
import MongoProjectUpdateEngine from "./projectupdates";
import MongoUserEngine from "./users";

export default class MongoProjectUpdateCommentEngine {
  static async add(comment) {
    try {
      var db = await MongoEngine.getDB();
      var result = await db.collection("ProjectUpdateComment").insertOne(comment);
      comment._id = result.insertedId;
    } catch(error) {
      console.log("[MongoProjectUpdateCommentEngine] add() -> error: ", error);
    }
  }

  static async addReply(comment, reply) {
    try {
      await MongoProjectUpdateCommentEngine.add(reply);
      var replyCount = await MongoProjectUpdateCommentEngine.getCountByCommentId(comment.docId);
      comment.replyCount = replyCount;
      var mongoComment = MongoProjectUpdateCommentEngine.fromJson(comment);
      await MongoProjectUpdateCommentEngine.update(mongoComment);
    } catch(error) {
      console.log("[MongoProjectUpdateCommentEngine] addReply() -> error: ", error);
    }
  }

  static async comment(project, update, commentor, text, heartCount) {
    try {
      var mongoComment = new MongoProjectUpdateComment();
      mongoComment.type = MongoProjectUpdateComment.TYPE_COMMENT;
      mongoComment.updateId = update.docId;
      mongoComment.creatorId = commentor.docId;
      mongoComment.creatorName = MongoUserEngine.getFriendlyUserName(commentor);
      mongoComment.creatorLogoUrl = commentor.logoUrl;
      mongoComment.text = text;
      mongoComment.heartCount = heartCount;
      await MongoProjectUpdateCommentEngine.add(mongoComment);

      // increase comment count
      update.commentCount = await MongoProjectUpdateCommentEngine.getCountByUpdateId(update.docId);
      await MongoProjectUpdateEngine.update(update);

      // pledge
      if (heartCount > 0 && project != null) {
        await MongoProjectPledgeEngine.pledge(project, commentor, MongoProjectPledge.TYPE_HEARTS, heartCount);
      }
    } catch(error) {
      console.log("[MongoProjectUpdateCommentEngine] comment() -> error: ", error);
    }
  }

  static async delete(comment) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdateComment");
      var filter = {
        docId: comment.docId,
      };
      var result = await collection.deleteMany(filter);

      // likes
      await MongoProjectUpdateCommentLikeEngine.deleteByCommentId(comment.docId);

      // replies
      var replyIds = [];
      var replies = await MongoProjectUpdateCommentEngine.getByCommentId(comment.docId);
      replies.forEach(reply => {
        replyIds.push(reply.docId);
      });

      await MongoProjectUpdateCommentLikeEngine.deleteByCommentIds(replyIds);
      await MongoProjectUpdateCommentEngine.deleteByCommentId(comment.docId);

      // decrease comment count
      if (comment.commentId == "") {
        var update = await MongoProjectUpdateEngine.getByDocId(comment.updateId);
        if (update == null) {
          return;
        }

        var commentCount = await MongoProjectUpdateCommentEngine.getCountByUpdateId(comment.updateId);
        update.commentCount = commentCount;
        MongoProjectUpdateEngine.update(update);
      } else {
        var parentComment = await MongoProjectUpdateCommentEngine.getByDocId(comment.commentId);
        if (parentComment == null) {
          return;
        }

        var replyCount = await MongoProjectUpdateCommentEngine.getCountByCommentId(comment.commentId);
        parentComment.replyCount = replyCount;
        MongoProjectUpdateCommentEngine.update(parentComment);
      }
    } catch(error) {
      console.log("[MongoProjectUpdateCommentEngine] delete() -> error: ", error);
    }
  }

  static async deleteByCommentId(commentId) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdateComment");
      var filter = {
        type: MongoProjectUpdateComment.TYPE_REPLY,
        commentId: commentId,
      };
      var result = await collection.deleteMany(filter);
    } catch(error) {
      console.log("[MongoProjectUpdateCommentEngine] deleteByCommentId() -> error: ", error);
    }
  }

  static async deleteByUpdateId(updateId) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdateComment");
      var filter = {
        updateId: updateId,
      };
      var result = await collection.deleteMany(filter);
    } catch(error) {
      console.log("[MongoProjectUpdateCommentEngine] deleteByUpdateId() -> error: ", error);
    }
  }

  static async deleteAll() {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdateComment");
      var result = await collection.deleteMany();
    } catch(error) {
      console.log("[MongoProjectUpdateCommentEngine] deleteAll() -> error: ", error);
    }
  }

  static async dislike(comment, userId) {
    try {
      var result = await MongoProjectUpdateCommentLikeEngine.dislike(comment.docId, userId);
      if (result == false) {
        return false;
      }

      var likeCount = await MongoProjectUpdateCommentLikeEngine.getCountByCommentId(comment.docId);
      comment.likeCount = likeCount;
      var mongoComment = MongoProjectUpdateCommentEngine.fromJson(comment);
      await MongoProjectUpdateCommentEngine.update(mongoComment);

      return true;
    } catch(error) {
      console.log("[MongoProjectUpdateCommentEngine] dislike() -> error: ", error);
    }

    return false;
  }

  static fromJson(json) {
    var comment = new MongoProjectUpdateComment();
    try {
      if (json._id != undefined) {
        comment._id = new ObjectId(json._id);
      }
      if (json.docId != undefined && json.docId != null && json.docId != "") {
        comment.docId = json.docId;
      } else {
        comment.docId = comment._id.valueOf().toString();
      }

      if (json.type != undefined) {
        comment.type = json.type;
      }

      if (json.updateId != undefined) {
        comment.updateId = json.updateId;
      }
      if (json.commentId != undefined) {
        comment.commentId = json.commentId;
      }

      if (json.creatorId != undefined) {
        comment.creatorId = json.creatorId;
      }
      if (json.creatorName != undefined) {
        comment.creatorName = json.creatorName;
      }
      if (json.creatorLogoUrl != undefined) {
        comment.creatorLogoUrl = json.creatorLogoUrl;
      }

      if (json.text != undefined) {
        comment.text = json.text;
      }
      if (json.heartCount != undefined) {
        comment.heartCount = json.heartCount;
      }

      if (json.likeCount != undefined) {
        comment.likeCount = json.likeCount;
      }
      if (json.replyCount != undefined) {
        comment.replyCount = json.replyCount;
      }

      if (json.timestamp != undefined) {
        comment.timestamp = json.timestamp;
      }
    } catch(error) {
      console.log("[MongoProjectUpdateCommentEngine] fromJson() -> error: ", error);
    }

    return comment;
  }

  static getByDocId = async (docId) => {
    var comment = null;
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdateComment");
      var filter = {
        docId: docId,
      };

      var mongoComments = await collection.find(filter);
      if (mongoComments.length == 0) {
        return null;
      }

      comment = MongoProjectUpdateCommentEngine.fromJson(mongoComments[0]);
    } catch(error) {
      console.log("[MongoProjectUpdateCommentEngine] getByDocId() -> error: ", error);
    }

    return comment;
  }

  static async getByCommentId(commentId) {
    var comments = [];
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdateComment");
      var filter = {
        type: MongoProjectUpdateComment.TYPE_REPLY,
        commentId: commentId,
      };
      var sort = {
        timestamp: -1,
      };

      var mongoComments = await collection.find(filter, { sort });
      mongoComments.forEach(mongoComment => {
        var comment = MongoProjectUpdateCommentEngine.fromJson(mongoComment);
        comments.push(comment);
      });
    } catch(error) {
      console.log("[MongoProjectUpdateCommentEngine] getByCommentId() -> error: ", error);
    }

    return comments;
  }

  static async getByUpdateId(updateId) {
    var comments = [];
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdateComment");
      var filter = {
        type: MongoProjectUpdateComment.TYPE_COMMENT,
        updateId: updateId,
      };
      var sort = {
        timestamp: -1,
      };

      var mongoComments = await collection.find(filter, { sort });
      mongoComments.forEach(mongoComment => {
        var comment = MongoProjectUpdateCommentEngine.fromJson(mongoComment);
        comments.push(comment);
      });
    } catch(error) {
      console.log("[MongoProjectUpdateCommentEngine] getByUpdateId() -> error: ", error);
    }

    return comments;
  }

  static async getCountByCommentId(commentId) {
    var count = 0;
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdateComment");
      var filter = {
        type: MongoProjectUpdateComment.TYPE_REPLY,
        commentId: commentId,
      };

      var count = await collection.count(filter);
    } catch(error) {
      console.log("[MongoProjectUpdateCommentEngine] getCountByCommentId() -> error: ", error);
    }

    return count;
  }

  static async getCountByUpdateId(updateId) {
    var count = 0;
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdateComment");
      var filter = {
        type: MongoProjectUpdateComment.TYPE_COMMENT,
        updateId: updateId,
      };

      var count = await collection.count(filter);
    } catch(error) {
      console.log("[MongoProjectUpdateCommentEngine] getCountByUpdateId() -> error: ", error);
    }

    return count;
  }

  static async like(comment, userId) {
    try {
      var result = await MongoProjectUpdateCommentLikeEngine.like(comment.updateId, comment.docId, userId);
      if (result == false) {
        return false;
      }

      var likeCount = await MongoProjectUpdateCommentLikeEngine.getCountByCommentId(comment.docId);
      comment.likeCount = likeCount;
      var mongoComment = MongoProjectUpdateCommentEngine.fromJson(comment);
      await MongoProjectUpdateCommentEngine.update(mongoComment);

      return true;
    } catch(error) {
      console.log("[MongoProjectUpdateCommentEngine] like() -> error: ", error);
    }

    return false;
  }

  static getLatestByUpdateId = async (updateId) => {
    var comment = null;
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdateComment");
      var filter = {
        type: MongoProjectUpdateComment.TYPE_COMMENT,
        updateId: updateId,
      };
      var sort = {
        timestamp: -1,
      };

      var mongoComments = await collection.find(filter, {
        sort: sort,
        limit: 1,
      });
      if (mongoComments.length == 0) {
        return null;
      }

      comment = MongoProjectUpdateCommentEngine.fromJson(mongoComments[0]);
    } catch(error) {
      console.log("[MongoProjectUpdateCommentEngine] getLatestByUpdateId() -> error: ", error);
    }

    return comment;
  }

  static async reply(project, comment, replier, text, heartCount) {
    try {
      var reply = new MongoProjectUpdateComment();
      reply.type = MongoProjectUpdateComment.TYPE_REPLY;
      reply.projectId = comment.projectId;
      reply.commentId = comment.docId;
      reply.creatorId = replier.docId;
      reply.creatorName = MongoUserEngine.getFriendlyUserName(replier);
      reply.creatorLogoUrl = replier.logoUrl;
      reply.text = text;
      reply.heartCount = heartCount;
      await MongoProjectUpdateCommentEngine.add(reply);

      // increase reply count
      comment.replyCount = await MongoProjectUpdateCommentEngine.getCountByCommentId(comment.docId);
      await MongoProjectUpdateCommentEngine.update(comment);

      // pledge
      if (heartCount > 0 && project != null) {
        await MongoProjectPledgeEngine.pledge(project, replier, MongoProjectPledge.TYPE_HEARTS, heartCount);
      }
    } catch(error) {
      console.log("[MongoProjectUpdateCommentEngine] reply() -> error: ", error);
    }
  }

  static async update(comment) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectUpdateComment");
      var result = await collection.updateOne(
        { _id: comment._id },
        { $set: comment },
        { upsert: true },
      );
    } catch(error) {
      console.log("[MongoProjectUpdateCommentEngine] update() -> error: ", error);
    }
  }
}
