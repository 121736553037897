import MongoCallback from "./callback";
import MongoEngine from ".";
import MongoProjectRead from "./projectread";
import MongoUserEngine from "./users";

export default class MongoProjectReadEngine {
  static changeListeners = [];

  static async add(read) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectRead");
      var result = await collection.insertOne(read);
      read._id = result.insertedId;
    } catch(error) {
      console.log("[MongoProjectReadEngine] add() -> error: ", error);
    }
  }

  static async deleteByProjectId(projectId) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectRead");
      var filter = {
        projectId: projectId,
      };

      var result = await collection.deleteMany(filter);
    } catch(error) {
      console.log("[MongoProjectReadEngine] deleteByProjectId() -> error: ", error);
    }
  }

  static async deleteAll() {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectRead");
      var result = await collection.deleteMany();
    } catch(error) {
      console.log("[MongoProjectReadEngine] deleteAll() -> error: ", error);
    }
  }

  static fromJson(json) {
    var read = new MongoProjectRead();
    try {
      if (json._id != undefined) {
        read._id = json._id;
      }
      if (json.projectId != undefined) {
        read.projectId = json.projectId;
      }
      if (json.readerIds != undefined) {
        read.readerIds = json.readerIds;
      }
    } catch(error) {
      console.log("[MongoProjectReadEngine] fromJson() -> error: ", error);
    }

    return read;
  }

  static async getByProjectId(projectId) {
    var read = null;
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectRead");
      var filter = {
        projectId: projectId,
      };

      var mongoProjectReads = await collection.find(filter);
      if (mongoProjectReads.length == 0) {
        return null;
      }

      read = MongoProjectReadEngine.fromJson(mongoProjectReads[0]);
    } catch(error) {
      console.log("[MongoProjectReadEngine] getByProjectId() -> error: ", error);
    }

    return read;
  }

  static isRead(read, userId) {
    var readerIds = read.readerIds;
    var index = readerIds.findIndex(readerId => readerId == userId);
    if (index < 0) {
      return false;
    }

    return true;
  }

  static async read(read, userId) {
    if (MongoProjectReadEngine.isRead(read, userId) == true) {
      return true;
    }

    var readerIds = [...read.readerIds, userId];
    read.readerIds = readerIds;

    await MongoProjectReadEngine.update(read);

    return true;
  }

  static registerChangeListener = (callback) => {
    try {
      var mongoCallback = new MongoCallback();
      mongoCallback.callback = callback;
      this.changeListeners.push(mongoCallback);
    } catch(error) {
      console.error("[MongoProjectReadEngine] registerChangeListener() -> error: ", error.message);
    }
  }

  static unregisterChangeListenerByListenerId = (callbackId) => {
    try {
      var index = this.changeListeners.find(callback => callback.id == callbackId);
      if (index < 0) {
        return;
      }

      this.changeListeners.splice(index, 1);
    } catch(error) {
      console.error("[MongoProjectReadEngine] unregisterChangeListenerByListenerId() -> error: ", error.message);
    }
  }

  static async update(read) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectRead");
      var result = await collection.updateOne(
        { _id: read._id },
        { $set: read },
        { upsert: true },
      );
    } catch(error) {
      console.log("[MongoProjectReadEngine] update() -> error: ", error);
    }
  }

  static watchForChanges = async () => {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectRead");
      var changeStream = collection.watch();
      for await (var change of changeStream) {
        this.changeListeners.forEach(listener => {
          try {
            listener.callback();
          } catch(error) {}
        });
      }
    } catch(error) {
      console.error("[MongoProjectReadEngine] watchForChanges() -> error: ", error.message);
    }
  }
}
