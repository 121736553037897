import MongoStripeBank from "./stripebank";

export default class MongoStripeBankEngine {
  static fromJson(json) {
    var bank = new MongoStripeBank();
    try {
      if (json.bank_name != undefined) {
        bank.bank_name = json.bank_name;
      }
      if (json.country != undefined) {
        bank.country = json.country;
      }
      if (json.last4 != undefined) {
        bank.last4 = json.last4;
      }
    } catch(error) {
      console.log("[MongoStripeBankEngine] fromJson() -> error: ", error);
    }

    return bank;
  }
}
