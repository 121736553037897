import MongoNotificationProjectFunded from "./notificationprojectfunded";
import MongoProjectEngine from "./projects";

export default class MongoNotificationProjectFundedEngine {
  static fromJson(json) {
    var notification = new MongoNotificationProjectFunded();
    try {
      if (json.projectId != undefined) {
        notification.projectId = json.projectId;
      }
      if (json.projectTitle != undefined) {
        notification.projectTitle = json.projectTitle;
      }
      if (json.projectLogoUrl != undefined) {
        notification.projectLogoUrl = json.projectLogoUrl;
      }
    } catch(error) {
      console.log("[MongoNotificationProjectFundedEngine] fromJson() -> error: ", error);
    }

    return notification;
  }

  static generateByProject(project) {
    var notification = new MongoNotificationProjectFunded();
    notification.projectId = project.docId;
    notification.projectTitle = project.title;
    var logo = MongoProjectEngine.getLogoImage(project);
    notification.projectLogoUrl = logo == null ? "" : logo.url;
    return notification;
  }
}
