import moment from "moment";

export default class Formatter {
  static integerToEasyString(number) {
    number = Number(number);
    if (number < 1000) {
      return number.toFixed(0);
    }

    //if (number < 10000) {
    var k = Math.floor(number / 1000);
    return k.toFixed(0) + "k" + (number > k * 1000 ? "+" : "");
    //}

    //var w = Math.floor(number / 10000);
    //return w.toFixed(0) + "w" + (number > w * 10000 ? "+" : "");
  }

  static timestampToDateString = (timestamp) => {
    if (timestamp == undefined || timestamp == null) {
      return "";
    }

    try {
      return moment(timestamp).calendar();
    } catch(error) {
      console.error("[Formatter] getBirthdayAsString() -> error: ", error.message);
    }

    return "";
  }
}
