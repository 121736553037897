import MongoEngine from ".";
import MongoEnviromate from "./enviromate";
import MongoUserEngine from "./users";

export default class MongoEnviromateEngine {
  static async add(enviromate) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("Enviromate");
      var result = await collection.insertOne(enviromate);
      enviromate._id = result.insertedId;
    } catch(error) {
      console.log("[MongoEnviromateEngine] add() -> error: ", error.message);
    }
  }

  static async addAsEnviromatee(enviromator, enviromatee) {
    try {
      // enviromator
      var user1Enviromate = await MongoEnviromateEngine.getByUserId(enviromator.docId);
      if (user1Enviromate == null) {
        user1Enviromate = new MongoEnviromate();
        user1Enviromate.userId = enviromator.docId;
      }

      var index = user1Enviromate.enviromatees.findIndex(enviromateeId => enviromateeId == enviromatee.docId);
      if (index < 0) {
        user1Enviromate.enviromatees.push(enviromatee.docId);
      }

      await MongoEnviromateEngine.update(user1Enviromate);

      // enviromatee
      var user2Enviromate = await MongoEnviromateEngine.getByUserId(enviromatee.docId);
      if (user2Enviromate == null) {
        user2Enviromate = new MongoEnviromate();
        user2Enviromate.userId = enviromatee.docId;
      }

      var index = user2Enviromate.enviromators.findIndex(enviromatorId => enviromatorId == enviromator.docId);
      if (index < 0) {
        user2Enviromate.enviromators.push(enviromator.docId);
      }

      await MongoEnviromateEngine.update(user2Enviromate);

      // envirmate count of enviromator
      var enviromateCount = user1Enviromate.enviromatees.length + user1Enviromate.enviromators.length;
      enviromator.enviromateCount = enviromateCount;

      await MongoUserEngine.update(enviromator);

      // envirmate count of enviromatee
      enviromateCount = user2Enviromate.enviromatees.length + user2Enviromate.enviromators.length;
      enviromatee.enviromateCount = enviromateCount;

      await MongoUserEngine.update(enviromatee);
    } catch(error) {
      console.log("[MongoEnviromateEngine] addAsEnviromatee() -> error: ", error.message);
    }
  }

  static async deleteAll() {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("Enviromate");
      var result = await collection.deleteMany();
    } catch(error) {
      console.log("[MongoEnviromateEngine] deleteAll() -> error: ", error);
    }
  }


  static fromJson(json) {
    var enviromate = new MongoEnviromate();
    try {
      if (json._id != undefined) {
        enviromate._id = json._id;
      }
      if (json.userId != undefined) {
        enviromate.userId = json.userId;
      }
      if (json.enviromatees != undefined) {
        enviromate.enviromatees = json.enviromatees;
      }
      if (json.enviromators != undefined) {
        enviromate.enviromators = json.enviromators;
      }
    } catch(error) {
      console.log("[MongoEnviromateEngine] fromJson() -> error: ", error);
    }

    return enviromate;
  }

  static async getByUserId(userId) {
    var enviromate = null;
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("Enviromate");
      var filter = {
        userId: userId,
      };

      var mongoEnviromates = await collection.find(filter);
      if (mongoEnviromates.length == 0) {
        return null;
      }

      enviromate = MongoEnviromateEngine.fromJson(mongoEnviromates[0]);
    } catch(error) {
      console.log("[MongoEnviromateEngine] getByUserId() -> error: ", error);
    }

    return enviromate;
  }

  static async getCountByUserId(userId) {
    var count = 0;
    try {
      var enviromate = await MongoEnviromateEngine.getByUserId(userId);
      if (enviromate != null) {
        count = enviromate.enviromatees.length + enviromate.enviromators.length;
      }
    } catch(error) {
      console.log("[MongoEnviromateEngine] getCountByUserId() -> error: ", error);
    }

    return count;
  }

  static getEnviromateIds(enviromate) {
    var enviromateIds = [...enviromate.enviromatees, ...enviromate.enviromators];
    return enviromateIds;
  }

  static async isEnviromate(userId1, userId2) {
    try {
      var enviromate = await MongoEnviromateEngine.getByUserId(userId1);
      if (enviromate != null) {
        var index = enviromate.enviromatees.findIndex(enviromatee => enviromatee == userId2);
        if (index >= 0) {
          return true;
        }
        index = enviromate.enviromators.findIndex(enviromator => enviromator == userId2);
        if (index >= 0) {
          return true;
        }
      } else {
        enviromate = await MongoEnviromateEngine.getByUserId(userId2);
        if (enviromate != null) {
          var index = enviromate.enviromatees.findIndex(enviromatee => enviromatee == userId1);
          if (index >= 0) {
            return true;
          }
          index = enviromate.enviromators.findIndex(enviromator => enviromator == userId1);
          if (index >= 0) {
            return true;
          }
        }
      }
    } catch(error) {
      console.log("[MongoEnviromateEngine] isEnviromate() -> error: " + error);
    }

    return false;
  }

  static async removeFromEnviromate(user1, user2) {
    try {
      // user1
      var user1Enviromate = await MongoEnviromateEngine.getByUserId(user1.docId);
      if (user1Enviromate != null) {
        var index = user1Enviromate.enviromatees.findIndex(enviromateeId => enviromateeId == user2.docId);
        if (index >= 0) {
          user1Enviromate.enviromatees.splice(index, 1);
        }

        index = user1Enviromate.enviromators.findIndex(enviromatorId => enviromatorId == user2.docId);
        if (index >= 0) {
          user1Enviromate.enviromators.splice(index, 1);
        }

        await MongoEnviromateEngine.update(user1Enviromate);
      }

      // user2
      var user2Enviromate = await MongoEnviromateEngine.getByUserId(user2.docId);
      if (user2Enviromate != null) {
        var index = user2Enviromate.enviromatees.findIndex(enviromateeId => enviromateeId == user1.docId);
        if (index >= 0) {
          user2Enviromate.enviromatees.splice(index, 1);
        }

        index = user2Enviromate.enviromators.findIndex(enviromatorId => enviromatorId == user1.docId);
        if (index >= 0) {
          user2Enviromate.enviromators.splice(index, 1);
        }

        await MongoEnviromateEngine.update(user2Enviromate);
      }

      // envirmate count of user1
      var enviromateCount = user1Enviromate == null ? 0 : user1Enviromate.enviromatees.length + user1Enviromate.enviromators.length;
      user1.enviromateCount = enviromateCount;

      await MongoUserEngine.update(user1);

      // envirmate count of user2
      enviromateCount = user2Enviromate == null ? 0 : user2Enviromate.enviromatees.length + user2Enviromate.enviromators.length;
      user2.enviromateCount = enviromateCount;

      await MongoUserEngine.update(user2);
    } catch(error) {
        console.log("[MongoEnviromateEngine] removeFromEnviromate() -> error: ", error.message);
    }
  }

  static async update(enviromate) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("Enviromate");
      var result = await collection.updateOne(
        { _id: enviromate._id },
        { $set: enviromate },
        { upsert: true },
      );
    } catch(error) {
      console.log("[MongoEnviromateEngine] update() -> error: ", error);
    }
  }
}
