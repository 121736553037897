export default class MongoNotificationProjectInvitationReject {
  projectId = "";
  projectTitle = "";

  inviteeId = "";
  inviteeName = "";
  inviteeLogoUrl = "";

  invitorId = "";
  invitorName = "";
  invitorLogoUrl = "";
}
