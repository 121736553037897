import MongoFunding from "./funding";

export default class MongoFundingEngine {
  static fromFirebase(json) {
    var funding = new MongoFunding();
    try {
      funding.currencyCode = "GBP";
      if (json.raised != undefined) {
        funding.raised = Number(json.raised);
      }
      if (json.target != undefined) {
        funding.target = Number(json.target);
      }
      if (json.monthly != undefined) {
        funding.monthly = Number(json.monthly);
      }
    } catch(error) {
      console.log("[MongoFundingEngine] fromFirebase() -> error: ", error);
    }

    return funding;
  }

  static fromJson(json) {
    var funding = new MongoFunding();
    try {
      if (json.currencyCode != undefined) {
        funding.currencyCode = json.currencyCode;
      }
      if (json.crowdFunding != undefined) {
        funding.crowdFunding = json.crowdFunding;
      }
      if (json.raised != undefined) {
        funding.raised = Number(json.raised);
      }
      if (json.target != undefined) {
        funding.target = Number(json.target);
      }
      if (json.monthly != undefined) {
        funding.monthly = Number(json.monthly);
      }
      if (json.funded != undefined) {
        funding.funded = Number(json.funded);
      }
    } catch(error) {
      console.log("[MongoFundingEngine] fromJson() -> error: ", error);
    }

    return funding;
  }

  static isEdited = (funding) => {
    if (funding == null) {
      return false;
    }
    if (funding.raised > 0) {
      return true;
    }
    if (funding.target > 0) {
      return true;
    }
    if (funding.monthly > 0) {
      return true;
    }

    return false;
  }
}
