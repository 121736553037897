import { ObjectId } from 'bson';

import MongoEngine from ".";
import MongoProjectComment from "./projectcomment";
import MongoProjectCommentLikeEngine from "./projectcommentlikes";
import MongoProjectEngine from "./projects";
import MongoProjectPledge from "./projectpledge";
import MongoProjectPledgeEngine from "./projectpledges";
import MongoUserEngine from "./users";

export default class MongoProjectCommentEngine {
  static async add(comment) {
    try {
      var db = await MongoEngine.getDB();
      var result = await db.collection("ProjectComment").insertOne(comment);
      comment._id = result.insertedId;
    } catch(error) {
      console.log("[MongoProjectCommentEngine] add() -> error: ", error);
    }
  }

  static async comment(project, commentor, text, heartCount) {
    try {
      var mongoComment = new MongoProjectComment();
      mongoComment.type = MongoProjectComment.TYPE_COMMENT;
      mongoComment.projectId = project.docId;
      mongoComment.creatorId = commentor.docId;
      mongoComment.creatorName = MongoUserEngine.getFriendlyUserName(commentor);
      mongoComment.creatorLogoUrl = commentor.logoUrl;
      mongoComment.text = text;
      mongoComment.heartCount = heartCount;
      await MongoProjectCommentEngine.add(mongoComment);

      // increase comment count
      project.commentCount = await MongoProjectCommentEngine.getCountByProjectId(project.docId);
      await MongoProjectEngine.update(project);

      // pledge
      if (heartCount > 0) {
        await MongoProjectPledgeEngine.pledge(project, commentor, MongoProjectPledge.TYPE_HEARTS, heartCount);
      }
    } catch(error) {
      console.log("[MongoProjectCommentEngine] comment() -> error: ", error);
    }
  }

  static async delete(comment) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectComment");
      var filter = {
        docId: comment.docId,
      };

      var result = await collection.deleteOne(filter);

      // likes
      await MongoProjectCommentLikeEngine.deleteByCommentId(comment.docId);

      // replies
      var replyIds = [];
      var replies = await MongoProjectCommentEngine.getByCommentId(comment.docId);
      replies.forEach(reply => {
        replyIds.push(reply.docId);
      });

      await MongoProjectCommentLikeEngine.deleteByCommentIds(replyIds);
      await MongoProjectCommentEngine.deleteByCommentId(comment.docId);

      // decrease comment count
      if (comment.commentId == "") {
        var project = await MongoProjectEngine.getByDocId(comment.projectId);
        if (project == null) {
          return;
        }

        var commentCount = await MongoProjectCommentEngine.getCountByProjectId(comment.projectId);
        project.commentCount = commentCount;
        MongoProjectEngine.update(project);
      } else {
        var parentComment = await MongoProjectCommentEngine.getByDocId(comment.commentId);
        if (parentComment == null) {
          return;
        }

        var replyCount = await MongoProjectCommentEngine.getCountByCommentId(comment.commentId);
        parentComment.replyCount = replyCount;
        MongoProjectCommentEngine.update(parentComment);
      }
    } catch(error) {
      console.log("[MongoProjectCommentEngine] delete() -> error: ", error);
    }
  }

  static async deleteByCommentId(commentId) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectComment");
      var filter = {
        commentId: commentId,
      };
      var result = await collection.deleteMany(filter);
    } catch(error) {
      console.log("[MongoProjectCommentEngine] deleteByCommentId() -> error: ", error);
    }
  }

  static async deleteByProjectId(projectId) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectComment");
      var filter = {
        projectId: projectId,
      };
      var result = await collection.deleteMany(filter);
    } catch(error) {
      console.log("[MongoProjectCommentEngine] deleteByProjectId() -> error: ", error);
    }
  }

  static async deleteAll() {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectComment");
      var result = await collection.deleteMany();
    } catch(error) {
      console.log("[MongoProjectCommentEngine] deleteAll() -> error: ", error);
    }
  }

  static fromJson(json) {
    var comment = new MongoProjectComment();
    try {
      if (json._id != undefined) {
        comment._id = new ObjectId(json._id);
      }
      if (json.docId != undefined && json.docId != null && json.docId != "") {
        comment.docId = json.docId;
      } else {
        comment.docId = comment._id.valueOf().toString();
      }
      if (json.type != undefined) {
        comment.type = json.type;
      }
      if (json.projectId != undefined) {
        comment.projectId = json.projectId;
      }
      if (json.commentId != undefined) {
        comment.commentId = json.commentId;
      }
      if (json.creatorId != undefined) {
        comment.creatorId = json.creatorId;
      }
      if (json.creatorName != undefined) {
        comment.creatorName = json.creatorName;
      }
      if (json.creatorLogoUrl != undefined) {
        comment.creatorLogoUrl = json.creatorLogoUrl;
      }
      if (json.text != undefined) {
        comment.text = json.text;
      }
      if (json.heartCount != undefined) {
        comment.heartCount = json.heartCount;
      }
      if (json.likeCount != undefined) {
        comment.likeCount = json.likeCount;
      }
      if (json.replyCount != undefined) {
        comment.replyCount = json.replyCount;
      }
      if (json.timestamp != undefined) {
        comment.timestamp = json.timestamp;
      }
    } catch(error) {
      console.log("[MongoProjectCommentEngine] fromJson() -> error: ", error);
    }

    return comment;
  }

  static async getByCommentId(commentId) {
    var comments = [];
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectComment");
      var filter = {
        type: MongoProjectComment.TYPE_REPLY,
        commentId: commentId,
      };
      var sort = {
        timestamp: -1,
      };

      var mongoComments = await collection.find(filter, { sort });
      mongoComments.forEach(mongoComment => {
        var comment = MongoProjectCommentEngine.fromJson(mongoComment);
        comments.push(comment);
      });
    } catch(error) {
      console.log("[MongoProjectCommentEngine] getByCommentId() -> error: ", error);
    }

    return comments;
  }

  static getByDocId = async (docId) => {
    var comment = null;
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectComment");
      var filter = {
        docId: docId,
      };

      var mongoComments = await collection.find(filter);
      if (mongoComments.length == 0) {
        return null;
      }

      comment = MongoProjectCommentEngine.fromJson(mongoComments[0]);
    } catch(error) {
      console.log("[MongoProjectCommentEngine] getByDocId() -> error: ", error);
    }

    return comment;
  }

  static async getByProjectId(projectId) {
    var comments = [];
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectComment");
      var filter = {
        type: MongoProjectComment.TYPE_COMMENT,
        projectId: projectId,
      };
      var sort = {
        timestamp: -1,
      };

      var mongoComments = await collection.find(filter, { sort });
      mongoComments.forEach(mongoComment => {
        var comment = MongoProjectCommentEngine.fromJson(mongoComment);
        comments.push(comment);
      });
    } catch(error) {
      console.log("[MongoProjectCommentEngine] getByProjectId() -> error: ", error);
    }

    return comments;
  }

  static async getCountByCommentId(commentId) {
    var count = 0;
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectComment");
      var filter = {
        type: MongoProjectComment.TYPE_REPLY,
        commentId: commentId,
      };

      count = await collection.count(filter);
    } catch(error) {
      console.log("[MongoProjectCommentEngine] getCountByCommentId() -> error: ", error);
    }

    return count;
  }

  static async getCountByProjectId(projectId) {
    var count = 0;
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectComment");
      var filter = {
        type: MongoProjectComment.TYPE_COMMENT,
        projectId: projectId,
      };

      count = await collection.count(filter);
    } catch(error) {
      console.log("[MongoProjectCommentEngine] getCountByProjectId() -> error: ", error);
    }

    return count;
  }

  static async reply(comment, replier, text, heartCount) {
    try {
      var reply = new MongoProjectComment();
      reply.type = MongoProjectComment.TYPE_REPLY;
      reply.projectId = comment.projectId;
      reply.commentId = comment.docId;
      reply.creatorId = replier.docId;
      reply.creatorName = MongoUserEngine.getFriendlyUserName(replier);
      reply.creatorLogoUrl = replier.logoUrl;
      reply.text = text;
      reply.heartCount = heartCount;
      await MongoProjectCommentEngine.add(reply);

      // increase reply count
      comment.replyCount = await MongoProjectCommentEngine.getCountByCommentId(comment.docId);
      await MongoProjectCommentEngine.update(comment);

      // pledge
      if (heartCount > 0) {
        var project = await MongoProjectEngine.getByDocId(comment.projectId);
        if (project != null) {
          await MongoProjectPledgeEngine.pledge(project, replier, MongoProjectPledge.TYPE_HEARTS, heartCount);
        }
      }
    } catch(error) {
      console.log("[MongoProjectCommentEngine] reply() -> error: ", error);
    }
  }

  static async update(comment) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectComment");
      var result = await collection.updateOne(
        { _id: comment._id },
        { $set: comment },
        { upsert: true },
      );
    } catch(error) {
      console.log("[MongoProjectCommentEngine] update() -> error: ", error);
    }
  }
}
