import { ObjectId } from 'bson';

import { HEART_VALUE } from '../constants/globalconstants';

import MongoEngine from ".";
import MongoMediaEngine from "./medias";
import MongoProjectEngine from "./projects";
import MongoProjectPledge from "./projectpledge";
import MongoUserEngine from "./users";
import MongoProjectUpdatePledgeEngine from './projectupdatepledges';

export default class MongoProjectPledgeEngine {
  static async add(pledge) {
    try {
      var db = await MongoEngine.getDB();
      var result = await db.collection("ProjectPledge").insertOne(pledge);
      pledge._id = result.insertedId;
    } catch(error) {
      console.log("[MongoProjectPledgeEngine] add() -> error: ", error);
    }
  }

  static async deleteAll() {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectPledge");
      var result = await collection.deleteMany();
    } catch(error) {
      console.log("[MongoProjectPledgeEngine] deleteAll() -> error: ", error);
    }
  }

  static fromJson = (json) => {
    var pledge = new MongoProjectPledge();
    try {
      if (json._id != undefined) {
        pledge._id = new ObjectId(json._id);
      }
      if (json.docId != undefined && json.docId != null && json.docId != "") {
        pledge.docId = json.docId;
      } else {
        pledge.docId = pledge._id.valueOf().toString();
      }

      if (json.projectId != undefined) {
        pledge.projectId = json.projectId;
      }
      if (json.projectTitle != undefined) {
        pledge.projectTitle = json.projectTitle;
        if (typeof(pledge.projectTitle) == "object") {
          pledge.projectTitle = JSON.stringify(pledge.projectTitle);
        }
      }
      if (json.projectMedia != undefined) {
        try {
          pledge.projectMedia = MongoMediaEngine.fromJson(json.projectMedia);
        } catch(error) {
          console.log("[MongoProjectPledgeEngine] fromJson() -> error: ", error);
        }
      }
      if (json.projectCategory != undefined) {
        pledge.projectCategory = json.projectCategory;
      }

      if (json.creatorId != undefined) {
        pledge.creatorId = json.creatorId;
      }
      if (json.creatorName != undefined) {
        pledge.creatorName = json.creatorName;
      }
      if (json.creatorLogoUrl != undefined) {
        pledge.creatorLogoUrl = json.creatorLogoUrl;
      }

      if (json.pledgerId != undefined) {
        pledge.pledgerId = json.pledgerId;
      }
      if (json.pledgerName != undefined) {
        pledge.pledgerName = json.pledgerName;
      }
      if (json.pledgerLogoUrl != undefined) {
        pledge.pledgerLogoUrl = json.pledgerLogoUrl;
      }

      if (json.type != undefined) {
        pledge.type = json.type;
      }
      if (json.amount != undefined) {
        pledge.amount = json.amount;
      }
      if (json.currencyCode != undefined) {
        pledge.currencyCode = json.currencyCode;
      }

      if (json.firedBy != undefined) {
        pledge.firedBy = json.firedBy;
      }

      if (json.timestamp != undefined) {
        pledge.timestamp = json.timestamp;
      }
    } catch(error) {
      console.log("[MongoProjectPledgeEngine] fromJson() -> error: ", error);
    }

    return pledge;
  }

  static async getAmountByUserId(userId) {
    var amount = 0;
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectPledge");
      var aggregate = [
        { $match: { pledgerId: userId } },
        { $project: { amount: 1 } },
        {
          $group: {
            _id: null,
            totalAmount: { $sum: "$amount" },
          },
        }
      ];

      var ret = await collection.aggregate(aggregate);
      if (ret.length == 0) {
        return 0;
      }

      amount = ret[0].totalAmount;
    } catch(error) {
      console.log("[MongoProjectPledgeEngine] getAmountByUserId() -> error: " + error.message);
    }

    return amount;
  }

  static getByDocId = async (docId) => {
    var pledge = null;
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectPledge");
      var filter = {
        docId: docId,
      };

      var pledges = await collection.find(filter);
      if (pledges.length == 0) {
        return null;
      }

      pledge = MongoProjectPledgeEngine.fromJson(pledges[0]);
    } catch(error) {
      console.log("[MongoProjectPledgeEngine] getByDocId() -> error: ", error);
    }

    return pledge;
  }

  static getByUserId = async (userId) => {
    var pledges = [];
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectPledge");
      var filter = {
        pledgerId: userId,
      };

      var sort = {
        timestamp: -1,
      };

      var mongoPledges = await collection.find(filter, {
        sort
      });
      mongoPledges.forEach(mongoPledge => {
        var pledge = MongoProjectPledgeEngine.fromJson(mongoPledge);
        pledges.push(pledge);
      });
    } catch(error) {
      console.log("[MongoProjectPledgeEngine] getByUserId() -> error: ", error);
    }

    return pledges;
  }

  static getByProjectIdAndUserId = async (projectId, userId) => {
    var pledges = [];
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectPledge");
      var filter = {
        projectId: projectId,
        pledgerId: userId,
      }

      var sort = {
        timestamp: 1,
      };

      pledges = await collection.find(filter, {sort});

    } catch(error) {
      console.log("[MongoProjectPledgeEngine] getByProjectIdAndUserId error:", error);
    }

    return pledges;
  }

  static getCountByProjectIdAndUserId = async (projectId, userId) => {
    var count = 0;
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectPledge");
      var filter = {
        projectId: projectId,
        pledgerId: userId,
      };

      count = await collection.count(filter);
    } catch(error) {
      console.log("[MongoProjectPledgeEngine] getCountByProjectIdAndUserId() -> error: ", error);
    }

    return count;
  }

  static getCountByUserId = async (userId) => {
    var count = 0;
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectPledge");
      var filter = {
        pledgerId: userId,
      };

      count = await collection.count(filter);
    } catch(error) {
      console.log("[MongoProjectPledgeEngine] getCountByUserId() -> error: ", error);
    }

    return count;
  }

  static getPledgerIdsByProjectId = async (projectId) => {
    var pledgerIds = [];
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectPledge");
      var filter = {
        projectId: projectId,
      };

      var pledges = await collection.find(filter);
      pledges.forEach(pledge => {
        var pledgerId = pledge.pledgerId;
        if (pledgerId == undefined || pledgerId == null) {
          return;
        }

        var index = pledgerIds.findIndex(id => id == pledgerId);
        if (index >= 0) {
          return;
        }

        pledgerIds.push(pledgerId);
      });
    } catch(error) {
      console.log("[MongoProjectPledgeEngine] getPledgerIdsByProjectId() -> error: ", error);
    }

    return pledgerIds;
  }

  static async pledge(project, pledger, type, amount, bMonthly = false, monthlyAmount = 0, currentCode = "") {
    try {
      // record pledging
      var mongoPledge = new MongoProjectPledge();
      mongoPledge.projectId = project.docId;
      mongoPledge.projectTitle = project.title;
      mongoPledge.projectMedia = MongoProjectEngine.getLogoImage(project);
      mongoPledge.projectCategory = project.category;
      mongoPledge.creatorId = project.creatorId;
      mongoPledge.creatorName = project.creatorName;
      mongoPledge.creatorLogoUrl = project.creatorLogoUrl;
      mongoPledge.pledgerId = pledger.docId;
      mongoPledge.pledgerName = MongoUserEngine.getFriendlyUserName(pledger);
      mongoPledge.pledgerLogoUrl = pledger.logoUrl;
      mongoPledge.currencyCode = pledger.currencyCode;
      if (currentCode) {
        mongoPledge.currencyCode = pledger.currencyCode;
      }
      
      mongoPledge.type = type;
      if (bMonthly) {
        mongoPledge.firedBy = MongoProjectPledge.FIREDBY_AUTO;
        mongoPledge.percentAmount = monthlyAmount;
      }

      var amountInCents = amount;
      if (type == MongoProjectPledge.TYPE_HEARTS) {
        amountInCents = amount * HEART_VALUE;
      } else if (type == MongoProjectPledge.TYPE_CASH) {

      }

      mongoPledge.amount = amountInCents;
      // confirm cash case
      await MongoProjectPledgeEngine.add(mongoPledge);

      // increase support count and amount of pledger
      pledger.supportCount = await MongoProjectPledgeEngine.getCountByUserId(pledger.docId);
      pledger.supportSpent = await MongoProjectPledgeEngine.getAmountByUserId(pledger.docId);

      pledger.supportCount += await MongoProjectUpdatePledgeEngine.getCountByUserId(pledger.docId);
      pledger.supportSpent += await MongoProjectUpdatePledgeEngine.getAmountByUserId(pledger.docId);

      await MongoUserEngine.update(pledger);

      // fund project
      await MongoProjectEngine.fund(project, mongoPledge);

      return true;
    } catch(error) {
      console.log("[MongoProjectPledgeEngine] pledge() -> error: ", error);
    }

    return false;
  }

  static async update(pledge) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("ProjectPledge");
      var result = await collection.updateOne(
        { _id: pledge._id },
        { $set: pledge },
        { upsert: true },
      );
    } catch(error) {
      console.log("[MongoProjectPledgeEngine] update() -> error: ", error);
    }
  }
}
